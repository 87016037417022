import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FuzzySearch from 'fuzzy-search'
import { uniqBy } from 'lodash'

import PageNavbar from '../PageNavbar'
import { Search } from '../../Atoms'

import { getAssets } from '../../../actions'

import { CATEGORIES } from './data'
import MediaItemContainer from './MediaItemContainer'

const MediaLibrary = ({
  isEmbedded = false,
  isEmbeddedOpen = false,
  multiSelect = false,
  fileDetailed = null,
  exclude = [],
  setFileDetailed = () => {},
  onSelectedChange = () => {},
}) => {
  const dispatch = useDispatch()
  const { files } = useSelector(state => state.Assets)
  const { group } = useSelector(state => state.auth)

  const searcher = useRef(new FuzzySearch(files, ['name']))

  const [selected, setSelected] = useState([])
  const [category, setCategory] = useState(CATEGORIES[0])
  const [filteredFiles, setFilteredFiles] = useState(files)
  const [searchQuery, setSearchQuery] = useState('')

  const handleSelectMedia = (file) => {
    if (isExcluded(file)) return
    
    if (!selected.some(f => f.Key === file.Key)) {
      // Not yet selected
      setSelected(multiSelect ? [...selected, file] : [file])
      setFileDetailed(file)
    } else {
      // De-select
      setSelected(selected.filter(f => f.Key !== file.Key))
      setFileDetailed(null)
    }
  }

  const isExcluded = (file) => exclude.some(f => f.Key === file.Key)

  const performSearch = () => {
    let results = files
    if (searchQuery) results = searcher.current.search(searchQuery)

    setFilteredFiles(results)
  }

  useEffect(() => {
    const unique = uniqBy(selected, 'Key')
    if (onSelectedChange) {
      onSelectedChange(selected)
    }
  
    if (unique.length !== selected.length) {
      return setSelected(unique)
    }
  }, [selected])

  useEffect(() => {
    setSelected(fileDetailed ? [fileDetailed] : [])
  }, [fileDetailed])

  useEffect(() => {
    if (!isEmbeddedOpen && isEmbedded) setSelected([])
  }, [isEmbeddedOpen])

  useEffect(() => {
    searcher.current = new FuzzySearch(files, ['name'])
    performSearch()
  }, [files])

  useEffect(() => {
    performSearch()
  }, [searchQuery])

  useEffect(() => {
    dispatch(getAssets(group._id))
  }, [group])

  return (
    <div className="relative flex flex-column overflow-y-auto overflow-x-hidden">
      {/* Navigation/filter */}
      {/* <PageNavbar
        categories={CATEGORIES}
        onSelect={setCategory}
        selected={category}
      /> */}

      {/* Search input */}
      <Search
        onChange={setSearchQuery}
        placeholder="Search asset..."
        className="mb-4"
        data_cy="media-search"
      />

      {/* Files */}
      <MediaItemContainer
        files={filteredFiles}
        selected={selected}
        exclude={exclude}
        isExcluded={isExcluded}
        onItemClick={handleSelectMedia}
      />
    </div>
  );
}

export default MediaLibrary;
