import React from "react";

import { Button } from "../../Atoms/Elements";
import { TeamCard } from "../Card";

const Team = props => {
  const { addTeamMember, members, onDeleteMember, onClick } = props;

  return (
    <div className="grid grid-cols-3 gap-4">
      {members.map((x, idx) => (
        <TeamCard
          key={idx}
          member={x}
          hasDelete={true}
          onDeleteMember={() => onDeleteMember(x)}
          onClick={onClick}
        />
      ))}
      <div
        className="p-4 flex justify-center items-center border-dashed border-4 border-gray-200"
        style={{ minHeight: "175px" }}
      >
        <Button
          data_cy="button-edit-job-add-member"
          onClick={addTeamMember}
          colorScheme="default"
          size="sm"
          type="button"
        >
          Add member
        </Button>
      </div>
    </div>
  );
};

export default Team;
