import axios from 'axios'

import { API_URL } from '../../constants/url'
import { bearer } from '../../helpers/Bearer'

import { ASSET_LOOKUP_IS_LOADING, ASSET_LOOKUP_DATA, ASSET_LOOKUP_ERROR }
    from './types'

const lookupAsset = (groupId, file) => async dispatch => {
    const header = bearer()

    try {
        dispatch({ type: ASSET_LOOKUP_IS_LOADING, action: 'lookup', file })

        const { data: { data: fileLookup } } = await axios.get(
            `${API_URL}/groups/${groupId}/media/photos/lookup/${file.name}`,
            header
        )
    
        return dispatch({ type: ASSET_LOOKUP_DATA, fileLookup })
    } catch (err) {
        return dispatch({ type: ASSET_LOOKUP_ERROR, err })
    }
}

export default lookupAsset