import React from 'react'

const InfoPanelHeader = ({ title = '', onClose = () => {} }) => (
  <div className="bg-indigo-600 p-3 flex justify-between items-center">
    <span className="text-white text-lg leading-7 font-medium">{title}</span>
    <button
      onClick={() => onClose()}
      className="rounded-md bg-white text-indigo-400 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
    >
      <span className="sr-only">Close panel</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
)

export default InfoPanelHeader