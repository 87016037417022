import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AWS_URL } from "../../../constants/url";
import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupIcon: ""
    };

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  componentDidMount = () => {
    const group = JSON.parse(sessionStorage.getItem("group"));
    if (group) {
      this.setState({
        groupIcon: group.icon,
        companyName: group.title
      });
    }
  };

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    const { hideLogoText, group } = this.props;
    const { groupIcon, companyName } = this.state;
    return (
      <div className="main-navbar">
        <Navbar className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0" type="light">
          <NavbarBrand className="w-100 mr-0" href="#" style={{ lineHeight: "25px" }}>
            <div className="d-flex justify-content-center align-items-center m-auto">
              {group.icon && <img className="hidden h-10 w-10 mr-1 rounded-full sm:block" src={`${AWS_URL}/${group.icon}`} alt="" />}
              {!group.icon && <img className="h-10 w-10 mr-1 rounded-full" src={`https://eu.ui-avatars.com/api/?name=${group.title}&format=svg&rounded=true&&color=fff&background=5850ec&length=1`} />}

              {!hideLogoText && <span className="d-none d-md-inline ml-1">{group.title}</span>}
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none" onClick={this.handleToggleSidebar}>
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

//Connect redux
function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    group: state.auth.group
  };
}

export default withRouter(connect(mapStateToProps, {})(SidebarMainNavbar));
