export default [
  {
    title: "Communication",
    value: "communication"
  },
  {
    title: "Form relationships",
    value: "form-relationships"
  },
  {
    title: "Vision",
    value: "vision"
  },
  {
    title: "Motivation",
    value: "motivation"
  },
  {
    title: "Coordination",
    value: "coordination"
  },
  {
    title: "Teamwork",
    value: "teamwork"
  },
  {
    title: "Adaptability",
    value: "adaptability"
  },
  {
    title: "Problem-Solving",
    value: "problem-Solving"
  },
  {
    title: "Creativity",
    value: "creativity"
  },
  {
    title: "Work Ethic",
    value: "work-ethic"
  },
  {
    title: "Time Management",
    value: "time-management"
  },
  {
    title: "Leadership",
    value: "leadership"
  },
  {
    title: "Attention to Detail",
    value: "detail"
  },
  {
    title: "Helpful",
    value: "helpful"
  },
  {
    title: "Social Skills",
    value: "social-skills"
  }
];
