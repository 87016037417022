import { MEMBERS, MEMBERS_IS_LOADING, MEMBERS_ERROR } from "../actions/types/member";

export default function(
  state = {
    isLoading: false,
    err: false,
    message: "",
    members: []
  },
  action
) {
  switch (action.type) {
    case MEMBERS_IS_LOADING: {
      return { ...state, isLoading: true, err: false };
    }

    case MEMBERS: {
      const { data } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        members: data
      };
    }

    case MEMBERS_ERROR: {
      const { message } = action.payload.response.data;
      return { ...state, err: true, message: message, isLoading: false };
    }

    default:
      return state;
  }
}
