import React from "react";
import { Input, Label } from "../../Atoms/Form";
import { Button } from "../../Atoms/Elements";

const TeamMemberForm = props => {
  const { values, handleChange, errors, isEditing } = props;

  return (
    <div className="space-y-2">
      <div>
        <Label name="name">Full name</Label>
        <Input
          data_cy="input-team-name"
          value={values.name}
          required
          type="text"
          name="name"
          placeholder="Elon Musk"
          onChange={handleChange}
        />
      </div>
      <div>
        <Label name="function">Role</Label>
        <Input
          data_cy="input-team-role"
          value={values.function}
          required
          type="text"
          name="function"
          placeholder="Product manager"
          onChange={handleChange}
        />
      </div>
      <div>
        <Label name="linkedin">Linkedin url</Label>
        <Input
          data_cy="input-team-linkedin"
          value={values.linkedin}
          required
          type="text"
          name="linkedin"
          placeholder="https://linkedin.com/elon-musk"
          onChange={handleChange}
        />
      </div>
      <div>
        <Label name="photo">Member photo</Label>
        <Input
          data_cy="input-team-avatar"
          value={values.photo}
          type="file"
          name="photo"
          onChange={handleChange}
          accept="image/*"
          useWhenAlreadyExist={true}
        />
      </div>
      <div className="mt-4">
        <Button
          data_cy="button-submit-team"
          colorScheme="default"
          size="lg"
          type="submit"
        >
          {isEditing ? "Edit member" : "Add member"}
        </Button>
      </div>
    </div>
  );
};

export default TeamMemberForm;
