import styled, { css } from 'styled-components';

const StyledNavbarLi = styled.li`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 2px;
    width: 0;
    transition: 0.25s ease;
    background-color: #4F46E5;
  }

  &:hover {
    color: #4F46E5;
  }

  ${({ $active }) => $active && css`
    color: #4F46E5;
    
    &::before {
      width: 100%;
    }
  `}
`

export default StyledNavbarLi