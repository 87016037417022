import React, { Fragment, useContext } from "react";
import { Transition, Dialog } from "@headlessui/react";

import { MediaLibraryContext } from '../../../context'

const Slider = props => {
  const { open: libraryOpen } = useContext(MediaLibraryContext)

  const handleClose = () => {
    if (libraryOpen) return

    props.onClose()
  }

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={props.isOpen}
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div
            className="fixed inset-y-0 right-0 pl-10 max-w-lg flex"
            style={{ marginTop: "60px" }}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className="h-full flex flex-col w-full bg-white shadow-xl overflow-y-scroll"
                style={{ minWidth: "400px" }}
              >
                <div className="px-4 py-4 bg-indigo-600 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2
                      id="slide-over-heading"
                      className="mb-0 text-xl font-medium text-white"
                    >
                      {props.title}
                    </h2>
                    <div
                      className="ml-3 h-7 flex items-center"
                      onClick={props.onClose}
                    >
                      <button className="rounded-md  hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                        <span className="sr-only">Close panel</span>
                        <svg
                          className="h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pb-1 sm:pb-6">
                    <div>{props.children}</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slider;
