import axios from "axios";
import Cookies from "js-cookie";

import { API_URL } from "../constants/url";
import { SKILLS, SKILLS_IS_LOADING, SKILLS_ERROR } from "./types/skills";

export const getSkills = () => async dispatch => {
  try {
    dispatch({ type: SKILLS_IS_LOADING });
    const response = await axios.get(`${API_URL}/skills`);
    return dispatch({ type: SKILLS, payload: response });
  } catch (err) {
    return dispatch({
      type: SKILLS_ERROR,
      payload: err
    });
  }
};
