import Cookies from "js-cookie";

export const bearer = () => {
  const token = Cookies.get("otellu-studio-jwt-token");

  if (token) {
    return {
      headers: {
        Authorization: "Bearer " + token
      }
    };
  } else {
    return "";
  }
};

export const multiPart = () => {
  const token = Cookies.get("otellu-studio-jwt-token");
  if (token) {
    return {
      headers: {
        Authorization: "Bearer " + token,
        ContentType: "multipart/form-data"
      }
    };
  } else {
    return "";
  }
};
