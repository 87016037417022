import React, { useRef, useEffect, useState } from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import styled from 'styled-components'

const StyledSearch = styled.div`
  position: relative;

  .icon {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input {
    flex-grow: 1;
    appearance: none;
    background: transparent;
    outline: 0;
    padding-left: 2rem;
  }
`

const Search = ({
  onChange = () => {},
  placeholder = 'Search...',
  delay = 250,
  className = '',
  data_cy = '',
  ...rest
}) => {
  const timeout = useRef()
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      onChange(query)
    }, delay)
  }, [query])

  return (
    <StyledSearch className={`flex bg-gray-200 rounded-md px-2 py-2.5 ${className}`} {...rest}>
      <SearchIcon className="icon h-6 w-6" />
      <input
        className="text-sm leading-5 font-normal text-gray-500"
        type="text"
        data-cy={data_cy}
        value={query}
        placeholder={placeholder}
        onChange={(e) => setQuery(e.target.value)}
      />
    </StyledSearch>
  )
}

export default Search