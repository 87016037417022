import React from "react";

const TabPanel = props => {
  if (props.activeTabIdx !== props.tabIdx) {
    return null;
  }
  return props.children;
};

export default TabPanel;
