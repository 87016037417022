import React from "react";

const Success = props => (
  <div
    className="flex flex-col mx-auto align-bottom bg-white rounded-lg  text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-2"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-headline"
  >
    <div>
      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-lg bg-green-100">
        <svg className="h-8 w-8 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z"
          />
        </svg>
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h2 className="mt-6 text-4xl font-extrabold text-gray-900 leading-tight">{props.title}</h2>
        <div className="mt-2">
          <p className="mt-2 text-base text-gray-600 font-light max-w">{props.description}</p>
        </div>
      </div>
    </div>
    <div className="mt-2">
      <span onClick={props.onClick} className="flex w-full rounded-md shadow-sm">
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
        >
          {props.buttonText}
        </button>
      </span>
    </div>
  </div>
);

export default Success;
