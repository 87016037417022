import * as Yup from "yup";
import axios from "axios";
import { API_URL, AWS_URL } from "../constants/url";

const EditorSchema = Yup.object().shape({
  title: Yup.string().required("Title is required."),
  content: Yup.string().notOneOf(["<p><br></p>"], "This content is required"),
  gallery: Yup.array(),
  team: Yup.array().min(0, "Add at least one image in your gallery"),
  team: Yup.array().of(
    Yup.object()
      .shape({
        name: Yup.string().required(
          "Please make sure all the required fields are filled in."
        ),
        function: Yup.string().required(
          "Please make sure all the required fields are filled in."
        ),
        file: Yup.array()
          .min(1, "Please make sure all the required fields are filled in.")
          .nullable()
      })
      .notRequired()
  ),
  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string()
        .min(4, "too short")
        .required("This content is required")
    })
  )
});

const addOnData = [];

const editorDefaultValues = {
  title: "",
  branch: "",
  jobTitle: "",
  employmentType: "",
  workLevel: "",
  locale: "",
  aboutJob: "",
  gallery: [],
  content: "",
  remote: false,
  openToMove: false,
  addonData: addOnData,
  team: [
    {
      name: "",
      function: "",
      linkedin: "",
      file: []
    }
  ]
};

const getHeaderImages = async vacancyId => {
  //get header images from aws
  // const files_req = await axios.get(`${API_URL}/aws/folder/?type=vacancy&folderType=headerImages&id=${vacancyId}`);
  // const files = files_req.data.data;
  // if (files && vacancyId) {
  //   const headerImages = await Promise.all(
  //     files.map(async (x, index) => {
  //       try {
  //         var d = new Date();
  //         console.log('IMAGE URL', `${AWS_URL}/${x.Key}?time=${d.getTime()}`)

  //         const blob = await axios.get(`${AWS_URL}/${x.Key}?time=${d.getTime()}`, {
  //           responseType: "blob",
  //           headers: {
  //             "Cache-Control": "no-cache"
  //           }
  //         });
  //         const file = new File([blob.data], `${x.Key}`);
  //         return Object.assign(file, {
  //           preview: URL.createObjectURL(file)
  //         });

  //         // throws Forbidden if there is no such file
  //       } catch (err) {
  //         return err;
  //       }
  //     })
  //   );
  //   return headerImages.filter(e => e !== undefined);
  // }
  const {
    data: { data: files }
  } = await axios.get(
    `${API_URL}/aws/folder/?type=vacancy&folderType=headerImages&id=${vacancyId}`
  );

  if (files && vacancyId) {
    return files.map((x, index) => ({
      ...x,
      url: `${AWS_URL}/${x.Key}?time=${new Date().getTime()}`,
      order: index
    }));
  }

  return [];
};

const convertYoutubeUrl = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export {
  editorDefaultValues,
  EditorSchema,
  getHeaderImages,
  convertYoutubeUrl
};
