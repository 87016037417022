import React from "react";
import { RangeSlider } from "../../Atoms/Form";
import { Button } from "../../Atoms";
import { prices } from "../../../data/package-prices";

const PackageModal = props => {
  const { loading } = props;
  return (
    <div className="flex flex-col align-center text-center justify-between">
      <div className="mx-auto">
        <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 max-w-md">
          <div className="p-4 grid grid-cols-2  gap-x-8 gap-y-4">
            <div className="flex flex-col text-left">
              <h2 className="mb-0 text-lg leading-6 font-medium text-indigo-00">Per job</h2>
              <p className="mb-0 mt-2">
                <span className="text-4xl font-extrabold text-indigo-700">€{prices[props.value - 1].pricePerSlot / 100}</span>
              </p>
            </div>
            <div className="flex flex-col text-left">
              <h2 className="mb-0 text-lg leading-6 font-medium text-gray-900">Total amount</h2>
              <p className="mb-0 mt-2">
                <span className="text-4xl font-extrabold text-gray-900">{prices[props.value - 1].price}</span>
              </p>
            </div>
            {props.value > 1 && (
              <div className="flex flex-col text-left">
                <h2 className="mb-0 text-lg leading-6 font-medium text-gray-900">Discount</h2>
                <p className="mt-2 mb-0">
                  <span className="mb-0 text-4xl font-extrabold text-gray-900">{prices[props.value - 1].saved}</span>
                  <span className="text-base font-medium text-gray-500">/per job</span>
                </p>
              </div>
            )}
          </div>
          <div className="pt-6 pb-8 px-6">
            <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
            <ul className="mt-6 space-y-4">
              <li className="flex space-x-3">
                <svg className="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span className="text-sm text-left text-gray-500">See info about your applicants and vacancies at a glance in our user-friendly dashboard.</span>
              </li>

              <li className="flex space-x-3">
                <svg className="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span className="text-sm text-left text-gray-500">Visually stunning job posts.</span>
              </li>

              <li className="flex space-x-3">
                <svg className="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span className="text-sm text-left text-gray-500">An ever-expanding list of free add-ons.</span>
              </li>

              <li className="flex space-x-3">
                <svg className="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span className="text-sm text-left text-gray-500">Enjoy the full power of our advanced search and match technologies.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-4 mb-8">
        <RangeSlider value={props.value} min={props.min} max={props.max} labels={props.labels} handleChangeSlider={props.handleChangeSlider} />
      </div>
      <div className="flex justify-center">
        <div className="w-48">
          <Button data_cy="button-confirm-buy-package" loading={loading} text="Buy package" theme="purple" onClick={() => props.onSubmit(prices[props.value - 1])} />
        </div>
      </div>
      <a target="_blank" href={`${process.env.REACT_APP_PEOPLE_FRONTEND_URL}/pricing`} className="mb-0 mt-1 underline text-gray-400">
        Learn more
      </a>
    </div>
  );
};

export default PackageModal;
