export default function() {
  return [
    {
      title: "Dashboards",
      items: [
        {
          title: "Home",
          to: "/dashboard",
          htmlBefore: '<i class="material-icons">home</i>',
          htmlAfter: ""
        },
        {
          title: "Applicants",
          to: "/applicants",
          htmlBefore: '<i class="material-icons">supervisor_account</i>',
          htmlAfter: ""
        },
        {
          title: "Vacancies",
          to: "/vacancies",
          htmlBefore: '<i class="material-icons">work</i>',
          htmlAfter: ""
        },
        {
          title: "Media",
          to: "/media-library",
          htmlBefore: '<i class="material-icons">collections</i>',
          htmlAfter: ""
        },
        // {
        //   title: "People",
        //   to: "/people",
        //   htmlBefore: '<i class="material-icons">collections</i>',
        //   htmlAfter: ""
        // },
        // {
        //   title: "People",
        //   to: "/people",
        //   htmlBefore: '<i class="material-icons">people</i>'
        // },
        {
          title: "Settings",
          to: "/settings",
          htmlBefore: '<i class="material-icons">settings</i>',
          htmlAfter: ""
        }
      ]
    }
  ];
}
