import React from "react";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

const SidebarItem = props => {
  return (
    <Link to={props.link} smooth={true} offset={-70} duration={500} className="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
      {props.icon}
      <span className="truncate">{props.name}</span>
    </Link>
  );
};

export default SidebarItem;
