import React from "react";
import { Field } from "formik";

import { Input } from "../../Atoms/Form";

const CompanyForm = props => {
  const { values, errors, touched, handleChange, group } = props;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-2 sm:col-span-2">
          <label for="title" className="block text-sm font-medium text-gray-700">
            Company name
          </label>
          <Field
            id="title"
            name="title"
            type="text"
            placeholder="Tesla"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {touched.name && <p className="mb-0 mt-1 text-sm text-red-400">{errors.name}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="shortDescription" className="block text-sm font-medium text-gray-700">
            Icon
          </label>

          <div className="flex gap-2 items-center">
          {!values.icon && <img className="inline-block h-12 w-12 rounded-full" src={`https://eu.ui-avatars.com/api/?name=${group.title}&format=svg&rounded=true&&color=fff&background=5850ec`} alt="" />}
            <Input
              value={values.icon}
              type="file"
              name="icon"
              onChange={handleChange}
              accept="image/*"
              useWhenAlreadyExist={true}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-2 sm:col-span-2">
          <label for="country" className="block text-sm font-medium text-gray-700">
            Country
          </label>
          <Field
            as="select"
            type="email"
            name="country"
            id="country"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          >
            <option value="" selected disabled hidden>
              Select a country
            </option>
            <option value="NL">Netherlands</option>
            <option value="USA">USA</option>
            <option value="EN">England</option>
          </Field>

          {touched.country && <p className="mb-0 mt-1 text-sm text-red-400">{errors.country}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="city" className="block text-sm font-medium text-gray-700">
            City
          </label>
          <Field
            type="text"
            name="city"
            id="city"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          {touched.city && <p className="mb-0 mt-1 text-sm text-red-400">{errors.city}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="address" className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <Field
            type="text"
            name="address"
            id="address"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          {touched.address && <p className="mb-0 mt-1 text-sm text-red-400">{errors.address}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="zip" className="block text-sm font-medium text-gray-700">
            Zip
          </label>
          <Field
            type="text"
            name="zip"
            id="zip"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          {touched.zip && <p className="mb-0 mt-1 text-sm text-red-400">{errors.zip}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="billingEmail" className="block text-sm font-medium text-gray-700">
            Billing email
          </label>
          <Field
            type="email"
            name="billingEmail"
            id="billingEmail"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          {touched.billingEmail && <p className="mb-0 mt-1 text-sm text-red-400">{errors.billingEmail}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="officeTunes" className="block text-sm font-medium text-gray-700">
            Office tunes
          </label>
          <Field
            type="text"
            name="officeTunes"
            id="officeTunes"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          {touched.zip && <p className="mb-0 mt-1 text-sm text-red-400">{errors.zip}</p>}
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
