import React, { useRef } from "react";
import styled from 'styled-components'

const colors = {
  purple: "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
  white: "text-black bg-white hover:bg-gray-100 focus:ring-gray-50",
  black: "text-white bg-gray-900 hover:bg-gray-800 focus:ring-gray-600",
  success: "text-green-800 bg-green-400 hover:bg-green-500 focus:ring-green-300"
};

const colorsDisabled = {
  purple: 'pointer-events-none bg-indigo-300 text-white',
  white: 'pointer-events-none bg-gray-100 text-black',
  black: 'pointer-events-none bg-gray-600 text-black-white',
  success: 'pointer-events-none bg-gray-100 text-black-400'
}

const StyledFileInput = styled.input`
  font-size: 0;
  cursor: pointer;

  &::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
  }
`

const Button = ({
  type = '',
  theme = 'purple',
  onClick = () => {},
  onFilesUploaded = () => {},
  multipleFile = false,
  disabled = false,
  data_cy = '',
  data_cy_file = '',
  loading = false,
  text = '',
  ...rest
}) => {
  return (
    <button
      data-cy={data_cy}
      type={type}
      className={`${disabled ? colorsDisabled[theme] : colors[theme]} relative w-full justify-center w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`}
      onClick={onClick}
    >
      {loading ? (
        <div className="flex justify-center">
          <svg className={`w-full animate-spin h-5 w-5 ${theme === "white" ? "text-black" : "text-white"}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      ) : (
        text
      )}
      {type === 'file' && (
        <StyledFileInput
          type="file"
          id="file"
          name="file"
          className="w-100 h-100 left-0 top-0 absolute opacity-0 cursor-pointer"
          multiple={multipleFile}
          onChange={(e) => onFilesUploaded && onFilesUploaded(e.target.files)}
          data-cy={data_cy_file}
        />
      )}
    </button>
  )
}

export default Button;
