import React from "react";
import { ExternalLinkIcon } from "../../Atoms/Icon";

const AddonCard = props => {
  const { addon } = props;
  const activeStyle = "bg-indigo-400 text-white";
  const defaultStyle = "bg-white text-gray-900";
  return (
    <div
      className={`flex flex-col justify-between items-start h-full text-left p-4 ${addon.active ? activeStyle : defaultStyle} shadow-md rounded-md cursor-pointer hover:shadow-lg`}
      onClick={props.onClick}
    >
      <div className="flex justify-between items-center w-full">
        <img className="h-8 w-8" src={addon.icon} />
        {props.addon.externalLink && (
          <a target="_blank" href={`${process.env.REACT_APP_PEOPLE_FRONTEND_URL}/${props.addon.externalLink}`}>
            <div className="flex justify-center items-center px-2 py-2 bg-gray-200 rounded-sm">
              <ExternalLinkIcon className="text-indigo-400 group-hover:text-gray-500 flex-shrink-0 h-5 w-5" />
            </div>
          </a>
        )}
      </div>
      <div className="mt-2">
        <span className="font-bold text-gray-900">{addon.name}</span>
        <p className="mb-0 text-gray-600">{addon.shortDescription}</p>
      </div>
      {addon.isCore && (
        <div className="mt-2 py-1 px-4 bg-green-300 text-green-700 rounded-lg">
          <span>free</span>
        </div>
      )}
      {!addon.isCore && (
        <div className="mt-2 py-1 px-4 bg-indigo-300 text-indigo-700 rounded-lg">
          <span>€{Number((addon.price / 100).toFixed(2))}</span>
        </div>
      )}
    </div>
  );
};

export default AddonCard;
