import React, { useState, useEffect } from "react";
import { Field } from "formik";
 
import { Input } from "../../Atoms/Form";

const ProfileForm = props => {
  const { values, errors, touched, handleChange, profile } = props;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-2 sm:col-span-2">
          <label for="name" className="block text-sm font-medium text-gray-700">
            Fullname
          </label>
          <Field
            id="name"
            name="name"
            type="text"
            placeholder="Elon Musk"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {touched.name && <p className="mb-0 mt-1 text-sm text-red-400">{errors.name}</p>}
        </div>
        <div className="col-span-2 sm:col-span-2">
          <label for="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <Field
            type="email"
            name="email"
            id="email"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
          />
          {touched.email && <p className="mb-0 mt-1 text-sm text-red-400">{errors.email}</p>}
        </div>
      </div>
      <div className="grid grid-cols-4">
        <div className="col-span-2 sm:col-span-2">
          <label for="shortDescription" className="block text-sm font-medium text-gray-700">
            Photo
          </label>
          <div className="flex gap-2 items-center">
            {!values.photo && (
              <img className="inline-block h-12 w-12 rounded-full" src={`https://eu.ui-avatars.com/api/?name=${profile.name}&format=svg&rounded=true&&color=fff&background=5850ec`} alt="" />
            )}
             <Input
              value={values.photo}
              type="file"
              name="photo"
              onChange={handleChange}
              accept="image/*"
              useWhenAlreadyExist={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
