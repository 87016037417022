import React from 'react'
import { useSelector } from 'react-redux'

import { AWS_URL } from '../../../constants/url'
import { Button } from '../../Atoms'

const getReadableSize = (sizeInBytes) => {
  const magnitudes = ['KB', 'MB', 'GB']
  
  let magnitude = 'B'
  let result = sizeInBytes

  for (let i = 0; i < magnitudes.length; i += 1) {
    if (result / 1000 <= 1) {
      break;
    }

    result = result / 1000
    magnitude = magnitudes[i]
  }

  return `${parseFloat(result).toFixed(1)} ${magnitude}`
}

const MediaInfo = ({
  file,
  onUpdate,
  onDelete,
}) => {
  const { isLoading: { value: isLoading, action: loadingAction } } = useSelector(state => state.Assets)
  const { Key, Size, extension, name } = file || {}

  if (!file) return null

  return (
    <>
      <img
        src={`${AWS_URL}/${Key}`}
        className="h-80 bg-gray-200 w-full object-contain mb-2"
      />
      <div className="p-4">
        <h5 className="text-2xl leading-7 leading-8 font-bold text-gray-900 overflow-ellipsis overflow-hidden truncate">
          {name}
        </h5>
        {/* File size */}
        <div className="mb-3">
          <span className="mb-1 block text-sm leading-5 font-medium text-gray-500">Size</span>
          <span className="block text-sm leading-5 font-normal text-gray-900">{getReadableSize(Size)}</span>
        </div>
        {/* File extension */}
        <div className="mb-3">
          <span className="mb-1 block text-sm leading-5 font-medium text-gray-500">Extension</span>
          <span className="block text-sm leading-5 font-normal text-gray-900">.{extension}</span>
        </div>
        <div className="flex grid grid-cols-2 gap-2">
          {onUpdate && (
            <Button
              theme="purple"
              text="Update"
              loading={isLoading && loadingAction === 'update'}
              disabled={isLoading && loadingAction !== 'getMany'}
              data_cy="button-update-asset"
              data_cy_file="button-update-asset-input"
              type="file"
              multipleFile={false}
              onFilesUploaded={(files) => onUpdate(files)}
            />
          )}
          {onDelete && (
            <Button
              theme="white"
              text="Delete"
              loading={isLoading && loadingAction === 'delete'}
              disabled={isLoading && loadingAction !== 'getMany'}
              onClick={() => onDelete(file)}
              data_cy="button-delete-asset"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default MediaInfo