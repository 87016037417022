import axios from 'axios';
import { API_URL } from '../../constants/url';

import {bearer} from '../../helpers/Bearer';
const header = bearer();
export const VACANCIES_BY_ACCOUNT_IS_LOADING = 'vacancies_by_account_is_loading';
export const VACANCIES_BY_ACCOUNT_DATA = 'vacancies_by_account_data';
export const VACANCIES_BY_ACCOUNT_ERROR = 'vacancies_by_account_error';

export const getVacanciesByAccount = accountId => async dispatch => {
  try {
    dispatch({ type: VACANCIES_BY_ACCOUNT_IS_LOADING });
    const result = await axios.get(
      `${API_URL}/vacancies/accounts/${accountId}`,
      header
    );

    const headerImageURLs = []
    
    // Find the header images associated with each vacancy
    // to use the first image as an example in the vacancy overview
    if (result) {
      await Promise.all(result.data.vacancies.map(async vac => {

        // returns all vacancy files (images)
        const vacFiles = await axios.get(
          `${API_URL}/aws/folder/${vac._id}`,
          header
        );

        // use the first image as a preview
        headerImageURLs.push(vacFiles.data[0].Key);
      }));
    }

    // Add the ordered pictures to the vacancy state obj
    headerImageURLs.forEach((imgURL, idx) => {
      result.data.vacancies[idx].previewImgURL = imgURL
    })
    
    return dispatch({ type: VACANCIES_BY_ACCOUNT_DATA, result });
  } catch (err) {
    return dispatch({
      type: VACANCIES_BY_ACCOUNT_ERROR,
      payload: err
    });
  }
};
