import React from 'react';

import { AWS_URL } from '../../../constants/url'

import { StyledMediaItem, StyledMediaItemThumbnail } from './styled'

const MediaItem = ({ file, onSelect, selected = false, inActive = false, loading = false }) => {
  const { name, Key } = file;

  const nonClickable = inActive || loading

  return (
    <StyledMediaItem
      data-cy="media-item"
      data-cy-filename={name}
      data-cy-selected={`${selected ? 'true' : 'false'}`}
      key={Key}
    >
      <StyledMediaItemThumbnail
        style={{ 
          backgroundImage: nonClickable 
            ? `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('${AWS_URL}/${Key}')`
            : `url('${AWS_URL}/${Key}')` 
        }}
        className="relative bg-cover bg-center bg-no-repeat mb-2"
        onClick={() => onSelect(file)}
        $selected={selected}
        $inActive={inActive}
        $loading={loading}
      >
        {loading && (
          <div className="loader">
            <svg className={`w-full animate-spin h-5 w-5 text-black`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </StyledMediaItemThumbnail>
      <p className={`${nonClickable ? 'text-gray-200' : 'text-gray-500'} mb-0 text-base leading-6 font-normal overflow-ellipsis overflow-hidden truncate`}>
        {name}
      </p>
    </StyledMediaItem>
  );
};

export default MediaItem;
