import React, { useState } from "react"

import { Formik, Field, Form, ErrorMessage } from "formik"
import { Button, Alert } from "../Atoms"
import { Input } from "../Atoms/Form"

const onboarding_values = {
  country: "",
  city: "",
  address: "",
  zip: "",
  icon: ""
};

const OnboardingForm = props => {
  const { message, error, loading } = props;

  return (
    <div>
      <img className="h-12 w-auto" src={require("../../images/logos/otellu-icon-v1.svg")} alt="otellu" />
      <h2 className="mt-6 text-4xl font-extrabold text-gray-900 leading-tight">{props.title}</h2>
      <p className="mt-2 text-sm text-gray-600 font-light max-w">{props.description}</p>

      <div className="mt-8">
        <div className="mt-6">
          <Formik
            initialValues={onboarding_values}
            onSubmit={async values => {
              await new Promise(r => setTimeout(r, 500));
              props.handleSubmit(values);
            }}
          >
            {({ values, ...formProps }) => (
              <Form className="space-y-4">
                <>
                  <div>
                    <label for="country" className="mb-0 block text-sm font-medium text-gray-700">
                      Country
                    </label>
                    <div className="mt-1">
                      <Field
                        data-cy="input-country-onboard"
                        as="select"
                        name="country"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        required
                        value={values.country}
                      >
                        <option value="null">Select country</option>
                        <option value="NL">Netherlands</option>
                        <option value="USA">USA</option>
                        <option value="EN">England</option>
                      </Field>
                    </div>
                  </div>

                  <div>
                    <label for="city" className="mb-0 block text-sm font-medium text-gray-700">
                      City
                    </label>
                    <div className="mt-1">
                      <Field
                        data-cy="input-city-onboard"
                        id="city"
                        name="city"
                        type="text"
                        value={values.city}
                        placeholder="Amsterdam"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label for="address" className="mb-0 block text-sm font-medium text-gray-700">
                      Adress
                    </label>
                    <div className="mt-1">
                      <Field
                        data-cy="input-address-onboard"
                        id="address"
                        name="address"
                        type="text"
                        value={values.address}
                        placeholder="......"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>
                </>
                <div>
                  <label for="zip" className="mb-0 block text-sm font-medium text-gray-700">
                    Postal code
                  </label>
                  <div className="mt-1">
                    <Field
                      data-cy="input-zip-onboard"
                      id="zip"
                      name="zip"
                      type="zip"
                      value={values.zip}
                      autoComplete="postal-code"
                      placeholder="1098LJ"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="mb-0 block text-sm font-medium text-gray-700 pointer-events-none">
                    Company icon
                  </label>
                  <div>
                    <Input
                      data_cy="input-icon-onboard"
                      id="icon"
                      name="icon"
                      type="file"
                      autoComplete="file"
                      accept="image/*"
                      value={values.icon}
                      onChange={e => formProps.setFieldValue("icon", e.target.value)}
                      allowMediaLibrary={false}
                      required
                      useWhenAlreadyExist={true}
                    />
                  </div>
                </div>

                {!loading && error && <Alert message={message} />}

                <div className="grid grid-cols-2 gap-4 items-center">
                  <Button data_cy="button-onboard" theme="purple" text="Submit" type="submit" loading={loading} />
                  <div>
                    <p onClick={props.onCancel} className="mb-0 underline ml-4 text-gray-600 cursor-pointer hover:text-gray-800" href="#">
                      Skip for now
                    </p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default OnboardingForm;
