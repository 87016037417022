import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, Alert } from "../Atoms";

const form_values = {
  email: ""
};

const ForgotPasswordForm = props => {
  const { message, error, loading, trigger } = props;

  return (
    <div>
      <img className="h-12 w-auto" src={require("../../images/logos/otellu-icon-v1.svg")} alt="otellu" />
      <h2 className="mt-6 text-4xl font-extrabold text-gray-900">{props.title}</h2>
      <div className="mt-8">
        {!loading && error && trigger && <Alert status="error" message={message} />}
        {!loading && !error && trigger && <Alert status="success" message={message} />}
        <div className="mt-6">
          <Formik
            initialValues={form_values}
            onSubmit={async values => {
              await new Promise(r => setTimeout(r, 500));
              props.handleSubmit(values);
            }}
          >
            <Form className="space-y-4">
              <div>
                <label for="email" className="mb-0 block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="elon@tesla.com"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link to="/login">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Go back to login
                    </a>
                  </Link>
                </div>
              </div>
              <div className="w-full">
                <Button theme="purple" text="Send" type="submit" loading={loading} />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
