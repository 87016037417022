/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { connect } from "react-redux";
import { ForgotPasswordForm } from "../components/Forms";
import { forgotPassword } from "../actions/Auth";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demo: false,
      onboardingStatus: "forgot_password",
      user: {},
      group: {},
      trigger: false
    };
  }

  handleSubmit = async values => {
    this.setState({ trigger: true });
    const { forgotPassword } = this.props;
    try {
      const forgot_password = await forgotPassword(values);
      if (forgot_password.response.status === 200) {
        this.props.history.push("/reset-password");
      }
    } catch (err) {
      return err;
    }
  };

  render() {
    const { auth_loading, auth_error, auth_message, group_loading, group_error, group_message } = this.props;
    const { onboardingStatus, trigger } = this.state;
    return (
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {onboardingStatus === "forgot_password" && (
              <ForgotPasswordForm handleSubmit={values => this.handleSubmit(values)} loading={auth_loading} error={auth_error} message={auth_message} title="Forgot password" trigger={trigger} />
            )}
          </div>
        </div>
        <div
          className="hidden lg:block relative  flex-1 bg-cover"
          style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80")' }}
        ></div>
      </div>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    auth_error: state.auth.err,
    auth_message: state.auth.message,
    auth_loading: state.auth.isLoading,
    group_loading: state.group.isLoading,
    group_error: state.group.err,
    group_message: state.group.message
  };
}

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
