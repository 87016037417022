import React, { createContext, useState, useRef } from 'react'

import { MediaLibraryEmbedded } from '../components/Molecules/MediaLibrary'

const INITIAL = {
  open: false,
  setOpen: () => {},
  title: '',
  setTitle: () => {},
  multiSelect: false,
  setMultiSelect: () => {},
  exclude: [],
  setExclude: () => {},
  callback: () => {},
  setCallback: () => {},
}

const MediaLibraryContext = createContext(INITIAL)

export const MediaLibraryProvider = ({ children }) => {
  const callback = useRef(() => {})
  const setCallback = cb => callback.current = cb

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [multiSelect, setMultiSelect] = useState(false)
  const [exclude, setExclude] = useState([])

  return (
    <MediaLibraryContext.Provider value={{
      callback: callback.current,
      setCallback,
      open,
      setOpen,
      title,
      setTitle,
      multiSelect,
      setMultiSelect,
      exclude,
      setExclude
    }}>
      <MediaLibraryEmbedded />
      {children}
    </MediaLibraryContext.Provider>
  )
}

export default MediaLibraryContext