import styled, { css } from 'styled-components'

const StyledModal = styled.div `
  background: #FFF;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.05);
  transition: all 0.25s ease;
  cursor: auto;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.media-library {
    width: 90vw;
    max-width: 1000px;
    height: 80vh;
  }

  .button-container {
    max-width: 250px;
  }

  ${({ $isOpen }) => $isOpen && css`
transform: translate(-50 % , -50 % ) scale(1);
`}
`

export default StyledModal