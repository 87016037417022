import React from 'react'
import { useSelector } from 'react-redux'

import MediaItem from './MediaItem'
import MediaItemSkeleton from './MediaItemSkeleton'

import { StyledMediaItemContainer } from './styled'

const SKELETON_ITEMS = Array.from(Array(24).keys())

const MediaItemContainer = ({ files, selected, isExcluded, onItemClick }) => {
    const { isLoading: { value: isLoadingFile, file: fileLoading, action: actionLoading, amount: amountCreated } } =
        useSelector(state => state.Assets)

    const displaySkeletonCards = isLoadingFile && actionLoading === 'getMany' && !files.length
    const displaySkeletonCreateCard = isLoadingFile && actionLoading === 'create'

    const isSelected = (file) => selected.some(f => f.Key === file.Key)
  
    const isLoading = (file) => isLoadingFile && fileLoading && fileLoading.Key === file.Key

    return (
        <StyledMediaItemContainer
            className="relative flex flex-wrap justify-start overflow-y-auto"
            data-cy="media-container"
        >
        {displaySkeletonCards && SKELETON_ITEMS.map(() => <MediaItemSkeleton />)}
        {displaySkeletonCreateCard &&
            Array
                .from(Array(amountCreated).keys())
                .map(() => <MediaItemSkeleton loading={true} />)
            }
        {files.map((file) => (
          <MediaItem
            file={file}
            onSelect={onItemClick}
            selected={isSelected(file)}
            inActive={isExcluded(file)}
            loading={isLoading(file)}
          />
        ))}
      </StyledMediaItemContainer>
    )
}

export default MediaItemContainer