import {
  // GET
  ASSET_GET_MANY_IS_LOADING,
  ASSET_GET_MANY_DATA,
  ASSET_GET_MANY_ERROR,
  // CREATE
  ASSET_CREATE_ONE_IS_LOADING,
  ASSET_CREATE_ONE_DATA,
  ASSET_CREATE_ONE_ERROR,
  // UPDATE
  ASSET_UPDATE_IS_LOADING,
  ASSET_UPDATE_DATA,
  ASSET_UPDATE_ERROR,
  // DELETE
  ASSET_DELETE_ONE_IS_LOADING,
  ASSET_DELETE_ONE_DATA,
  ASSET_DELETE_ONE_ERROR,
  // LOOKUP
  ASSET_LOOKUP_IS_LOADING,
  ASSET_LOOKUP_DATA,
  ASSET_LOOKUP_ERROR
} from '../../actions/Assets/types'

const loadingInitial = { value: false, type: '' }
const fileLookupInitial = {
  total: 0,
  critical: null, // array of instances where the usage of the file is critical which makes the file undeletable
  used: [],
}

const initialState = {
  files: [],
  fileLookup: fileLookupInitial,
  isLoading: loadingInitial,
  error: null
}

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case ASSET_GET_MANY_DATA:
      return { ...state, isLoading: loadingInitial, files: action.files }

    case ASSET_CREATE_ONE_DATA:
      return { ...state, isLoading: loadingInitial, files: [...action.file, ...state.files] }

    case ASSET_UPDATE_DATA: {
      return {
        ...state,
        isLoading: loadingInitial,
        files: state.files.map(file => file.id === action.file.id ? action.file : file)
      }
    }

    case ASSET_DELETE_ONE_DATA:
      return { ...state, isLoading: loadingInitial, files: state.files.filter(file => file.id !== action.file.id) }

    case ASSET_LOOKUP_DATA:
      return { ...state, isLoading: loadingInitial, fileLookup: action.fileLookup }
  
    case ASSET_GET_MANY_IS_LOADING:
    case ASSET_CREATE_ONE_IS_LOADING:
    case ASSET_UPDATE_IS_LOADING:
    case ASSET_DELETE_ONE_IS_LOADING:
      return { ...state, isLoading: { value: true, action: action.action, file: action.file, amount: action.amount || 0 }, error: null }

    case ASSET_LOOKUP_IS_LOADING:
      return {
          ...state,
          isLoading: { value: true, action: action.action },
          fileLookup: fileLookupInitial
        }

    case ASSET_GET_MANY_ERROR:
    case ASSET_CREATE_ONE_ERROR:
    case ASSET_UPDATE_ERROR:
    case ASSET_DELETE_ONE_ERROR:
    case ASSET_LOOKUP_ERROR:
      return { ...state, isLoading: loadingInitial, error: action.err }
  }

  return state
}