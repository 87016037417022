import axios from 'axios'

import { API_URL } from '../../constants/url'
import { bearer } from '../../helpers/Bearer'
import { triggerToastError, triggerToastSuccess } from "../../helpers/handleResponse";

import { ASSET_DELETE_ONE_IS_LOADING, ASSET_DELETE_ONE_DATA, ASSET_DELETE_ONE_ERROR } from './types'

const deleteAsset = (groupId, fileToDelete) => async dispatch => {
  const header = bearer()

  try {
    dispatch({ type: ASSET_DELETE_ONE_IS_LOADING, action: 'delete', file: fileToDelete })

    // API call
    const { data: { data: id } } =
      await axios.delete(
        `${API_URL}/groups/${groupId}/media/photos/${fileToDelete.name}`,
        header
      )

    triggerToastSuccess(`You succesfully deleted '${fileToDelete.name}'`)
    return dispatch({ type: ASSET_DELETE_ONE_DATA, file: { id } })
  } catch (err) {
    triggerToastError("Something went wrong when deleting your file", false)
    return dispatch({ type: ASSET_DELETE_ONE_ERROR,  err })
  }
}

export default deleteAsset