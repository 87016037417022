import React from "react";

const Badge = props => {
  const colors = {
    info: "bg-yellow-100 text-yellow-800",
    success: "bg-green-100 text-green-800",
    error: "bg-red-100 text-red-800"
  };
  const errorColor = "bg-red-100 text-red-800";
  return <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${colors[props.colorType]}`}>{props.text}</span>;
};

export default Badge;
