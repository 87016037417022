import React from "react";
import moment from "moment";
import { Button } from "../../Atoms";
import { AWS_URL } from "../../../constants/url";

const JobCard = props => {
  const { type, job } = props;
  return (
    <div className="flex flex-col rounded-lg shadow-md overflow-hidden">
      <div className="flex-shrink-0">
        {!job.previewImgURL && (
          <div className="flex justify-center bg-gray-300 items-center h-48 w-full">
            <p className="mb-0 text-lg text-gray-900">No image found</p>
          </div>
        )}
        {job.previewImgURL && <img className="h-48 w-full object-cover" src={`${AWS_URL}/${job.previewImgURL}`} alt="" />}
      </div>
      <div className="flex-1 bg-white p-4 flex flex-col justify-between">
        <div className="flex-1">
          {job.active && (
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 relative">
              Active
              <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
              </span>
            </span>
          )}
          {job.archive && <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800 relative">Archived</span>}
          {!job.archive && job.draft && <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800 relative">Draft</span>}
          {(!job.archive && !job.draft && !job.active) && (<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-purple-100 text-purple-800 relative">Pending</span>)}
          <a href="#" className="block mt-2">
            <p className="mb-0 text-xl font-semibold text-gray-900">{job.title ? job.title : "Empty job"}</p>
            <p className="mb-0 mt-2 text-base text-gray-500">{job.shortDescription}</p>
          </a>
        </div>
        <div className="mt-6 flex flex-col items-center">
          <Button text="Edit job" theme="purple" onClick={props.onClick} />
          {/* {job.active && <p className="text-xs mb-0 mt-2 text-gray-600">{`Job will expire at ${moment(job.expiresAt).format("MMMM DD, YYYY")}`}</p>} */}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
