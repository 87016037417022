import React from "react";
import { Transition } from "@headlessui/react";

const SlideOver = props => {
  return (
    <div class={`fixed ${props.isOpen && "inset-0 overflow-hidden"}`} style={{ zIndex: props.isOpen ? 2000 : 0 }}>
      <div class="absolute inset-0 overflow-hidden">
        <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
          <Transition
            show={props.isOpen}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className={`w-screen ${props.sliderWidth}`}
          >
            <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
              <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                <div class="flex items-center justify-between">
                  <h2 id="slide-over-heading" class="text-xl mb-0 font-medium text-white">
                    {props.title}
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button onClick={props.onClose} class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span class="sr-only">Close panel</span>
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-6 relative flex-1 px-4 sm:px-6">
                <div class="h-full" aria-hidden="true">
                  {props.children}
                </div>
              </div>
            </div>
          </Transition>
        </section>
      </div>
    </div>
  );
};

export default SlideOver;
