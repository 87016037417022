import React from "react";
import dateFormat from "dateformat";
import { AWS_URL } from "../constants/url";

import { Container, Row, Col, Card, CardHeader, CardBody, FormSelect, InputGroup, InputGroupAddon, InputGroupText, FormInput, Modal } from "shards-react";
import { connect } from "react-redux";
import PageTitle from "../components/common/PageTitle";
import { Success, UnlockDemo, Error } from "../components/Modal";
import { Button } from "../components/Atoms";
import { Table } from "../components/Atoms/Table";
import { goLive } from "../actions/Group";
import { getApplicationsByGroup } from "../actions/application";

import { applicationsMockup } from "../data/mockup";

class Applicants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      applications: [],
      hiddenApplications: [],
      modal: false,
      modalInfo: null,
      openDeviationModal: false,
      userInfo: null,
      unlockDemoModal: false,
      successModal: false,
      errorModal: false,
      goLiveTrigger: false,
      unlockDemoModal: false,
      applicantSlider: false,
      activeTabIdx: 0,
      selectedApplicant: null
    };

    this.searcher = null;

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount = async () => {
    const { group, getApplicationsByGroup, profile } = this.props;

    //get applications

    await getApplicationsByGroup(group._id);

    if (!group.demo) {
      this.setState({
        applications: this.props.applications
      });
    }
  };

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  openDetails = async row => {
    await this.props.getSingleApplicant(row.original._id);
    if (row) {
      this.setState({
        openDeviationModal: true,
        modal: true,
        userInfo: row.original,
        deviations: this.props.deviations
      });
    }
  };

  goLive = async () => {
    const { goLive } = this.props;
    const { _id } = this.props.group;
    this.setState({ goLiveTrigger: true });
    try {
      const go_live_request = await goLive(_id);
    } catch (err) {
      return err;
    }
  };

  continueAfterLive = () => {
    this.setState({
      unlockDemoModal: false
    });
  };

  openSelectedApplicant = row => {
    this.setState({
      applicantSlider: true,
      selectedApplicant: row.original
    });
    return window.MessageBirdChatWidget.hide();
  };

  handleCloseSlider = () => {
    this.setState({ applicantSlider: false });
    return window.MessageBirdChatWidget.show();
  };

  render() {
    const { pageSize, pageSizeOptions, goLiveTrigger, applicantSlider, activeTabIdx, selectedApplicant } = this.state;
    const { userInfo, hiddenApplications } = this.state;
    const { group, single_applicant, applications } = this.props;
    const csvApplicants = applications.map(x => ({ ...x, resume: `${AWS_URL}/resume/${x.applicantId}` }));

    const applicantTableColumns = [
      {
        Header: "#",
        accessor: "id",
        maxWidth: 30,
        className: "text-center",
        Cell: row => <div>{row.index + 1}</div>
      },
      {
        Header: "Name",
        accessor: "name",
        className: "text-center",
        minWidth: 60,
        maxWidth: 300,
        Cell: row => <p className="mb-0">{row.original.name}</p>
      },
      {
        Header: "Date",
        accessor: "date",
        className: "text-center",
        minWidth: 80,
        maxWidth: 120,
        Cell: row => dateFormat(new Date(row.original.createdAt), "dd-mm-yyyy")
      },
      {
        Header: "Vacancy",
        accessor: "vacancyTitle",
        className: "text-center",
        maxWidth: 230
      },
      {
        Header: "Addons",
        accessor: "addonData",
        sortable: false,
        Cell: row => {
          return (
            <div className="flex w-full justify-start gap-2">
              {row.original.addonData.map(x => (
                <span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-800">{x.name}</span>
              ))}
            </div>
          );
        }
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        maxWidth: 140,
        Cell: row => (
          <div className="flex ">
            <Button text="Details" theme="white" type="button" onClick={() => this.openSelectedApplicant(row)}></Button>
          </div>
        )
      }
    ];

    return (
      <Container fluid className="main-content-container px-2 px-sm-4 pb-4 relative">
        <div className="max-w-7xl mx-auto">
          <Row noGutters className="page-header py-4">
            <PageTitle title="Applicants" subtitle="Overview" className="text-sm-left mb-3" />
          </Row>

          <Table
            columns={applicantTableColumns}
            data={group.demo ? applicationsMockup : applications}
            applicantSlider={applicantSlider}
            selectedApplicant={selectedApplicant}
            closeSlider={() => this.handleCloseSlider()}
            demo={group.demo}
          />

          <Modal open={this.state.unlockDemoModal} toggle={() => this.setState({ unlockDemoModal: !this.state.unlockDemoModal })} size="lg" position="center" className="c-modal">
            {!goLiveTrigger ? <UnlockDemo onClose={() => this.setState({ unlockDemoModal: false })} onSubmit={() => this.goLive()} /> : null}
            {goLiveTrigger ? (
              !this.props.group_is_loading && this.props.group_error ? (
                <Error title={this.props.group_message} buttonText="Continue" onClick={() => this.setState({ unlockDemoModal: false })} />
              ) : (
                <Success
                  title="Welcome to the real world"
                  description="You can now place unlimited vacancies with an unlimited amount of applicants. We're glad to have you!
                If you have any questions in the future, don't hestitate to contact our customer support team, we're happy to help."
                  buttonText="Continue"
                  onClick={() => this.continueAfterLive()}
                />
              )
            ) : null}
          </Modal>
        </div>
      </Container>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    applications: state.applicants.applications,
    deviations: state.applicants.deviations,
    group: state.auth.group,
    group_error: state.auth.err,
    group_is_loading: state.auth.isLoading,
    group_message: state.auth.message,
    single_applicant: state.applicants.single_applicant,
    profile: state.auth.profile
  };
}

export default connect(mapStateToProps, { getApplicationsByGroup })(Applicants);
