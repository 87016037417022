import React from "react";
import { Button } from "../../Atoms";
import { AWS_URL } from "../../../constants/url";

const SkeletonJobCard = props => {
  return (
    <div className="flex flex-col animate-pulse rounded-lg shadow-md overflow-hidden">
      <div className="flex-shrink-0">
        <div className="h-48 w-full object-cover bg-gray-300 animate-pulse" alt="" />
      </div>
      <div className="flex-1 bg-white p-4 flex flex-col justify-between">
        <div className="flex-1">
          <a href="#" className="block mt-2">
            <div class="h-5 bg-gray-400 rounded w-1/4"></div>
            <div className="mt-2">
              <div className="h-4 bg-gray-400 rounded w-4/6 mt-2"></div>
              <div className="mt-4 space-y-2">
                <div class="h-2 bg-gray-400 rounded w-4/6"></div>
                <div class="h-2 bg-gray-400 rounded w-5/6"></div>
              </div>
            </div>
          </a>
        </div>
        <div className="mt-6 flex flex-col items-start">
          <div class="h-8 bg-indigo-200 rounded w-5/6"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonJobCard;
