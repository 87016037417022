import styled, { css } from 'styled-components'

const MediaItemThumbnail = styled.div`
  padding: 1px;
  border: 2px solid transparent;
  border-radius: 8px;
  height: 150px; 
  cursor: pointer;

  &:hover {
    border: 2px solid #000;
  }

  ${({ $selected }) => $selected && css`
    border: 2px solid #4F46E5 !important;
  `}

  ${({ $inActive, $loading }) => ($inActive || $loading) && css`
    cursor: default;
    border: none !important;

    &:hover {
      border: none !important;
    }
  `}

   .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default MediaItemThumbnail