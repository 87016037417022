import styled, { css } from 'styled-components'

const StyledModalWrapper = styled.div`
  background: #FFF;
  border-radius: 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
  background: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.25s ease;

  ${({ $isOpen }) => $isOpen && css`
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25 ease;
  `}
`

export default StyledModalWrapper