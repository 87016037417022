import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../constants/url";
import crypto from "crypto";
import Cookies from "js-cookie";
import { bearer, multiPart } from "../../helpers/Bearer";
import { Mixpanel } from "../../helpers/mixpanel";
const header = bearer();
const headerFile = multiPart();
export const ADD_VACANCIE_IS_LOADING = "add_vacancie_is_loading";
export const ADD_VACANCIE_DATA = "add_vacancie_data";
export const ADD_VACANCIES_ERROR = "add_vacancie_error";

export const addVacancyAction = ({ vacancy, author, groupId, headerImages, companyName, aboutCompany, aboutJob, aboutYou, teamImages, team, questions }) => async dispatch => {
  var bodyFormData = new FormData();
  bodyFormData.set("author", author);
  bodyFormData.set("groupId", groupId);
  bodyFormData.set("companyName", companyName);
  bodyFormData.set("maxApplicants", vacancy.maxApplicants);
  bodyFormData.set("jobTitle", vacancy.jobTitle);
  bodyFormData.set("branch", vacancy.branch);
  bodyFormData.set("workLevel", vacancy.workLevel);
  bodyFormData.set("employmentType", vacancy.employmentType);
  bodyFormData.set("title", vacancy.title);
  bodyFormData.set("shortDescription", vacancy.shortDescription);
  bodyFormData.set("remoteWork", vacancy.remoteWork);
  bodyFormData.set("locale", vacancy.locale);
  bodyFormData.set("type", vacancy.type);
  bodyFormData.set("softSkillOne", vacancy.softSkillOne);
  bodyFormData.set("softSkillTwo", vacancy.softSkillTwo);
  bodyFormData.set("youtubeUrl", vacancy.youtubeUrl);
  bodyFormData.set("aboutYou", aboutYou);
  bodyFormData.set("aboutCompany", aboutCompany);
  bodyFormData.set("aboutJob", aboutJob);
  bodyFormData.set("applyCTAText", "Solliciteer");
  bodyFormData.set("team", JSON.stringify(team.map(t => ({ name: t.name, function: t.function, linkedin: t.linkedin, imgFileName: t.imgFileName }))));
  bodyFormData.set("questions", JSON.stringify(questions.map(t => ({ question: t.question }))));

  try {
    dispatch({ type: ADD_VACANCIE_IS_LOADING });
    const result = await axios.post(`${API_URL}/vacancies`, bodyFormData, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });

    //setup api call for header images
    // bodyFormData = new FormData();
    // headerImages.forEach((image, idx) => {
    //   // generate unique id
    //   const imageId = crypto.randomBytes(16).toString("hex");
    //   bodyFormData.append(String(imageId), image, image.name);
    // });
    // await axios.post(`${API_URL}/aws/headerImages/${result.data.vacancy._id}`, bodyFormData, {
    //   headers: {
    //     Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token"),
    //     ContentType: "multipart/form-data"
    //   }
    // });

    //setup api call for team images
    bodyFormData = new FormData();
    teamImages.forEach((image, idx) => {
      bodyFormData.append(`image${idx}`, image, image.name);
    });
    if (teamImages.length !== 0) {
      await axios.put(`${API_URL}/vacancies/${result.data.vacancy._id}/images/team`, bodyFormData, {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token"),
          ContentType: "multipart/form-data"
        }
      });
    }
    Mixpanel.track("Create vacancy", result.data.vacancy);
    toast.success("Sucessfully created a vacancy");
    return dispatch({ type: ADD_VACANCIE_DATA, result });
  } catch (err) {
    return dispatch({
      type: ADD_VACANCIES_ERROR,
      payload: err
    });
  }
};
