import React from "react";
import { Transition } from "@headlessui/react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import dateFormat from "dateformat";
import { Element, Events } from "react-scroll";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { maxBy } from "lodash";

//ATOMS
import { Button } from "../components/Atoms";
import { Tab, TabList, TabPanel } from "../components/Atoms/Tab";
import { Table } from "../components/Atoms/Table";
import { ModalContainer, ModalHeader } from "../components/Atoms/Modal";
import { Pill } from "../components/Atoms/Elements";
import {
  InfoIcon,
  PencilIcon,
  PhotographIcon,
  PlusIcon,
  MinIcon,
  LockedClosedIcon,
  UsersIcon,
  ArrowUp
} from "../components/Atoms/Icon";

//MOLECULES
import {
  Block,
  CopyForm,
  Gallery,
  Header,
  InfoForm,
  SoftskillsForm,
  LocationForm,
  Youtube,
  SkeletonEditor,
  ErrorFocus
} from "../components/Molecules/Editor";
import { Sidebar, SidebarItem } from "../components/Molecules/Navigation";
import { AddonsModal } from "../components/Molecules/Modal";
import { Team, Questions } from "../components/Molecules/Addons";
import { Panel } from "../components/Molecules/Card";
import { TeamMemberForm } from "../components/Molecules/Form";
import { Slider } from "../components/Atoms/Slider";

import { MediaLibraryContext } from "../context";

//REDUX ACTIONS
import { getVacancy, activateVacancy, updateVacancy } from "../actions/vacancy";
import { getApplicationsByVacancy } from "../actions/application";
import { getSkills } from "../actions/skills";
import { ACTIVATE_VACANCY, UPDATE_VACANCY } from "../actions/types/vacancy";

//HELPERS
import {
  editorDefaultValues,
  EditorSchema,
  getHeaderImages
} from "../helpers/editor";
import { getTeamFiles } from "../helpers/storage";

//DATA
import { applicationsMockup } from "../data/mockup";
import { addonsTypes } from "../data/addons";
import { createUsers } from "../data/team";

class EditJob extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addOnsModalState: false,
      editStatus: false,
      job_post: [],
      activeTabIdx: 0,
      applicantSlider: false,
      selectedApplicant: null,
      selectedAddons: [],
      addonsOptions: [],
      triggerSaveJob: false,
      defaultValues: [],
      activateJobTrigger: false,
      updateTrigger: false,
      readyToPublish: false,
      openSlider: false,
      member: { name: "", function: "", linkedin: "", photo: "" },
      editMode: false,
      showPublishedModal: false
    };
  }

  componentDidMount = async () => {
    const { getVacancy, getApplicationsByVacancy, getSkills } = this.props;
    const { id, draftId } = this.props.match.params;

    //get skills from db
    await getSkills();

    //running when new job
    if (!id) {
      await getVacancy(draftId);
      this.setState({
        addonsOptions: addonsTypes,
        job_post: Object.assign(editorDefaultValues, this.props.job_post),
        defaultValues: editorDefaultValues
      });
    }

    //running when job is in edit mode
    if (id) {
      //get vacancy
      await getVacancy(id);
      let job_post = this.props.job_post;

      //get used addons
      const usedAddons = addonsTypes.filter(element =>
        job_post.addons.includes(element.id)
      );

      //get addonOptions minus usedAddons
      const addonOptions = addonsTypes.filter(
        element => !job_post.addons.includes(element.id)
      );
      //get applicantions
      const res = await getApplicationsByVacancy(id);

      this.setState({
        editStatus: true,
        job_post: job_post,
        selectedAddons: usedAddons,
        addonsOptions: addonOptions,
        readyToPublish: job_post.title ? true : false
      });
    }
  };

  componentWillUnmount = async () => {};

  handleSelectAddon = (addon, values) => {
    //add selected addon to blocks
    const { id, draftId } = this.props.match.params;
    const { addonsOptions } = this.state;
    const currentAddons = addonsOptions.filter(x => x.id !== addon.id);

    this.setState(prevState => ({
      addonsOptions: currentAddons,
      selectedAddons: [...prevState.selectedAddons, addon],
      job_post: {
        ...values,
        addonData: [...values.addonData, addon]
      },
      addOnsModalState: false
    }));
  };

  handleRemoveAddon = (addon, values) => {
    const { id, draftId } = this.props.match.params;
    const { selectedAddons, job_post } = this.state;

    this.setState(prevState => ({
      addonsOptions: [...prevState.addonsOptions, addon],
      job_post: {
        ...values,
        addonData: job_post.addonData.filter(x => x.addonId !== addon.addonId)
      }
    }));
  };

  openSelectedApplicant = row => {
    this.setState({
      applicantSlider: true,
      selectedApplicant: row.original
    });
  };

  handleUpdateJob = async (id, profile, group, values) => {

    console.log({values})

    const { type, payload } = await this.props.updateVacancy(
      id,
      values,
      profile,
      group
    );

    this.setState(prevState => ({
      updateTrigger: true,
      readyToPublish: true,
      job_post: {
        ...prevState.job_post,
        ...(type === UPDATE_VACANCY ? payload.data.data : {})
      }
    }));
  };

  handleActivateJob = async (id, profile, group, values) => {
    console.log(id, profile, group, values);
    const { job_post, activateVacancy } = this.props;
    this.setState({
      activateJobTrigger: false,
      showPublishedModal: false
    });
    const that = this;
    const { type } = await activateVacancy(
      id,
      values.title,
      values,
      profile,
      group
    );

    if (!this.props.errorState.update && !this.props.errorState.publish) {
      that.setState({
        activateJobTrigger: type === ACTIVATE_VACANCY ? true : false,
        showPublishedModal: this.props.publish_done
      });
    }
  };

  handleSkills = (type, skillObj, values) => {
    const { job_post } = this.state;
    if (type === "add") {
      return this.setState(prevState => ({
        job_post: {
          ...values,
          skills: [...values.skills, skillObj]
        }
      }));
    }
    if (type === "remove") {
      let new_job_post = {
        ...values,
        skills: values.skills.filter((_, i) => _.value !== skillObj.value)
      };
      return this.setState({
        job_post: new_job_post
      });
    }
  };

  handleMediaLibraryCallback = (selected, values) => {
    const { order: maxOrder } = maxBy(
      values.gallery || [],
      image => image.order
    ) || { order: 0 };
    const selectedWithOrder = selected.map(({ Key }, index) => ({
      Key,
      order: maxOrder + index + 1
    }));

    this.setState({
      job_post: {
        ...values,
        gallery: [...values.gallery, ...selectedWithOrder]
      }
    });
  };

  handleSubmitMemberForm = values => {
    const { editMode } = this.state;

    editMode ? this.handleMemberEdit(values) : this.handleMemberCreate(values);
  };

  handleMemberDelete = (member, values) => {
    this.setState({
      job_post: {
        ...values,
        team: values.team.filter(m => member._id !== m._id)
      }
    });
  };

  handleMemberCreate = values => {
    this.setState(prevState => ({
      openSlider: false,
      job_post: {
        ...prevState.job_post,
        team: [...prevState.job_post.team, values]
      }
    }));
  };

  handleMemberEdit = values => {
    const { member } = this.state;

    this.setState(prevState => ({
      openSlider: false,
      job_post: {
        ...prevState.job_post,
        team: prevState.job_post.team.map(t =>
          t._id === member._id ? values : t
        )
      }
    }));
  };

  render() {
    const {
      job_post,
      addOnsModalState,
      addonsOptions,
      editStatus,
      activeTabIdx,
      applicantSlider,
      selectedApplicant,
      activateJobTrigger,
      updateTrigger,
      readyToPublish,
      member,
      editMode,
      showPublishedModal
    } = this.state;
    const {
      loading,
      group,
      profile,
      applicantions,
      skillOptions,
      loading_update,
      loading_publish,
      errorState,
      loadingState,
      fetching,
      publish_doneGvd
    } = this.props;
    const { id, draftId } = this.props.match.params;

    const applicantTableColumns = [
      {
        Header: "#",
        accessor: "id",
        maxWidth: 30,
        className: "text-center",
        Cell: row => <div>{row.index + 1}</div>
      },
      {
        Header: "Name",
        accessor: "name",
        className: "text-center",
        minWidth: 60,
        maxWidth: 300,
        Cell: row => <p className="mb-0">{row.original.name}</p>
      },
      {
        Header: "Date",
        accessor: "date",
        className: "text-center",
        minWidth: 80,
        maxWidth: 120,
        Cell: row => dateFormat(new Date(row.original.createdAt), "dd-mm-yyyy")
      },
      {
        Header: "Vacancy",
        accessor: "vacancyTitle",
        className: "text-center",
        maxWidth: 230
      },
      {
        Header: "Addons",
        accessor: "addonData",
        sortable: false,
        Cell: row => {
          return (
            <div className="flex w-full justify-start gap-2">
              {row.original.addonData.map(x => (
                <span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-800">
                  {x.name}
                </span>
              ))}
            </div>
          );
        }
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        maxWidth: 140,
        Cell: row => (
          <div className="flex ">
            <Button
              text="Details"
              theme="white"
              type="button"
              onClick={() => this.openSelectedApplicant(row)}
            ></Button>
          </div>
        )
      }
    ];

    return (
      <>
        {loadingState.fetching && <SkeletonEditor />}
        {!loadingState.fetching && (
          <Formik
            initialValues={job_post}
            validationSchema={EditorSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={async values => {
              await new Promise(r => setTimeout(r, 500));

              // Whether the activate, or the update button was pressed
              if (this.state.activateJobTrigger) {
                console.log("activate");
                await this.handleActivateJob(draftId || id, profile, group, {
                  ...values,
                  draft: false
                });
              } else {
                console.log("just update");
                await this.handleUpdateJob(
                  draftId || id,
                  profile,
                  group,
                  values
                );
              }
            }}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => {
              return (
                <Form>
                  <Element name="header">
                    <Header
                      handleActivateJob={() => this.handleActivateJob()}
                      group={group}
                      loading_update={loading_update}
                      loading_publish={loading_publish}
                      job_post={job_post}
                      editStatus={editStatus}
                      loadingState={loadingState}
                      errorState={errorState}
                      draftId={draftId}
                      updateTrigger={updateTrigger}
                      readyToPublish={readyToPublish}
                      setActivateTrigger={() =>
                        this.setState({
                          activateJobTrigger: true,
                          loading_publish: true
                        })
                      }
                    />
                  </Element>
                  {editStatus && (
                    <div className="w-full bg-white border-t border-gray-200">
                      <TabList>
                        <Tab
                          name="edit job"
                          tabIdx={0}
                          activeTabIdx={activeTabIdx}
                          onClick={() => this.setState({ activeTabIdx: 0 })}
                        />
                        <Tab
                          name="applicants"
                          tabIdx={1}
                          activeTabIdx={activeTabIdx}
                          onClick={() => this.setState({ activeTabIdx: 1 })}
                        />
                      </TabList>
                    </div>
                  )}

                  <TabPanel activeTabIdx={activeTabIdx} tabIdx={0}>
                    <main className="max-w-9xl mx-auto pb-10 lg:py-12 lg:px-8">
                      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="space-y-6 relative sm:px-6 lg:px-0 lg:col-span-8">
                          <Element
                            name="info"
                            aria-labelledby="payment_details_heading"
                          >
                            <Block
                              data_cy="info-block-edit-job"
                              title="General info"
                              description="Add the basics of your the job. Didn't find what you are looking for? Connect our support."
                            >
                              <InfoForm
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                                values={values}
                              />
                            </Block>
                          </Element>
                          <Block
                            data_cy="info-block-edit-job"
                            title="Location preferences"
                            description="Leverage the power of Europe's biggest startup ecosystems! Do you want to accept remote work for this position? Are you open to applicants that are moving to your city?"
                          >
                            <LocationForm values={values} />
                          </Block>
                          <Element
                            name="content"
                            aria-labelledby="payment_details_heading"
                          >
                            <Block
                              title="Content"
                              description="Our machine learning will classify the job based on the content section."
                            >
                              <CopyForm
                                touched
                                errors={errors}
                                values={values}
                                loading={loading}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                              />
                            </Block>
                          </Element>
                          <Element
                            name="gallery"
                            aria-labelledby="payment_details_heading"
                          >
                            <Block
                              title="Gallery"
                              description="Add a minimum of one image into your job post gallery."
                            >
                              <Gallery
                                errors={errors}
                                touched={touched}
                                loading={loadingState.update}
                                files={values.gallery || []}
                                mediaLibraryCallback={selected =>
                                  this.handleMediaLibraryCallback(
                                    selected,
                                    values
                                  )
                                }
                                handleGalleryChanged={files =>
                                  setFieldValue(
                                    "gallery",
                                    files.map(f => ({
                                      Key: f.Key,
                                      order: f.order
                                    }))
                                  )
                                }
                              />
                            </Block>
                          </Element>
                          <Element
                            name="team"
                            aria-labelledby="payment_details_heading"
                          >
                            <Block
                              title="Teams"
                              description="Add team members with their given information."
                              optional
                            >
                              <Team
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors.team}
                                touched={touched}
                                addTeamMember={() =>
                                  this.setState({
                                    editMode: false,
                                    openSlider: true,
                                    member: {}
                                  })
                                }
                                onClick={member =>
                                  this.setState({
                                    editMode: true,
                                    openSlider: true,
                                    member
                                  })
                                }
                                members={values.team || []}
                                onDeleteMember={member =>
                                  this.handleMemberDelete(member, values)
                                }
                              />
                            </Block>
                          </Element>

                          {/* Addons blocks */}

                          {values.length !== 0 &&
                            values.addonData.length !== 0 &&
                            values.addonData.map((x, index) => (
                              <div key={index}>
                                {x.name === "Soft Skills" && (
                                  <section
                                    key={index}
                                    aria-labelledby="payment_details_heading"
                                  >
                                    <Block
                                      onClose={() =>
                                        this.handleRemoveAddon(x, values)
                                      }
                                      type="addon"
                                      title="Softskills"
                                      description="Choose two softskills to test your applicant on. A soft skill can be chosen once."
                                    >
                                      <SoftskillsForm
                                        job_post={job_post}
                                        addonNmbr={index}
                                        errors={errors}
                                        values={
                                          values.addonData &&
                                          values.addonData.find(
                                            y => y.addonId === x.addonId
                                          )
                                        }
                                      />
                                    </Block>
                                  </section>
                                )}

                                {x.name === "Questions" && (
                                  <section
                                    key={index}
                                    aria-labelledby="payment_details_heading"
                                  >
                                    <Block
                                      onClose={() =>
                                        this.handleRemoveAddon(x, values)
                                      }
                                      type="addon"
                                      title="Questions"
                                      description="Add the questions you want your applicants to answer."
                                    >
                                      <Questions
                                        job_post={job_post}
                                        addonNmbr={index}
                                        values={
                                          values.addonData &&
                                          values.addonData.find(
                                            y => y.addonId === x.addonId
                                          )
                                        }
                                      />
                                    </Block>
                                  </section>
                                )}
                                {x.name === "Youtube" && (
                                  <section
                                    key={index}
                                    aria-labelledby="payment_details_heading"
                                  >
                                    <Block
                                      onClose={() =>
                                        this.handleRemoveAddon(x, values)
                                      }
                                      type="addon"
                                      title="Youtube"
                                      description="Add a youtube url to display the video in your vacancy."
                                    >
                                      <Youtube
                                        setFieldValue={setFieldValue}
                                        addonNmbr={index}
                                        values={
                                          values.addonData &&
                                          values.addonData.find(
                                            y => y.addonId === x.addonId
                                          )
                                        }
                                      />
                                    </Block>
                                  </section>
                                )}
                              </div>
                            ))}
                          {/* Addons options */}
                          <div className="relative">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="w-full border-t border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center">
                              <button
                                type="button"
                                className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() =>
                                  this.setState({
                                    addOnsModalState: !this.state
                                      .addOnsModalState
                                  })
                                }
                                disabled={job_post.active}
                              >
                                {job_post.active && (
                                  <LockedClosedIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
                                )}
                                {!addOnsModalState && !job_post.active && (
                                  <PlusIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
                                )}
                                {addOnsModalState && !job_post.active && (
                                  <MinIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" />
                                )}
                                <span>
                                  {!addOnsModalState
                                    ? "add-ons"
                                    : "collapse add-ons"}
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-col w-full mx-auto">
                            <Transition
                              show={addOnsModalState}
                              enter="transition ease-out duration-75"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-150"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                              className="relative w-full"
                            >
                              <AddonsModal
                                selectAddon={addon =>
                                  this.handleSelectAddon(addon, values)
                                }
                                addonsOptions={addonsOptions}
                              />
                            </Transition>
                          </div>
                        </div>
                        <div className="col-span-4 space-y-4">
                          <Sidebar>
                            <SidebarItem
                              link="info"
                              name="General info"
                              icon={
                                <InfoIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                              }
                            />
                            <SidebarItem
                              link="content"
                              name="Content"
                              icon={
                                <PencilIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                              }
                            />
                            <SidebarItem
                              link="gallery"
                              name="Gallery"
                              icon={
                                <PhotographIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                              }
                            />
                            <SidebarItem
                              link="team"
                              name="Team"
                              icon={
                                <UsersIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                              }
                            />
                            <SidebarItem
                              link="header"
                              name="Scroll to top"
                              icon={
                                <ArrowUp className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                              }
                            />
                          </Sidebar>
                          <Panel
                            title="Skills"
                            description="Save your job and we'll auto generate skills based on your content. Afterwards you can add or remove skills."
                          >
                            <div className="mt-6 flex flex-wrap gap-2 max-w-lg py-4 px-2 bg-gray-100 rounded-lg">
                              {job_post.skills !== undefined &&
                                job_post.skills.length === 0 && (
                                  <p className="text-center mb-0">
                                    Fill in your content or select skills below
                                    for your job post
                                  </p>
                                )}
                              {job_post.skills !== undefined &&
                                !fetching &&
                                job_post.skills.map(x => (
                                  <Pill
                                    key={x.value}
                                    selected={false}
                                    onClick={() =>
                                      this.handleSkills("remove", x, values)
                                    }
                                  >
                                    {x.label}
                                  </Pill>
                                ))}
                            </div>
                            <div className="mt-4">
                              <p className="mb-0">Add more skills</p>
                              <Select
                                options={skillOptions}
                                onChange={val =>
                                  this.handleSkills("add", val, values)
                                }
                              />
                            </div>
                          </Panel>
                        </div>
                      </div>
                    </main>
                  </TabPanel>
                </Form>
              );
            }}
          ></Formik>
        )}

        <TabPanel activeTabIdx={activeTabIdx} tabIdx={1}>
          <div className="max-w-9xl mx-auto pb-10 lg:py-12 lg:px-8">
            <Table
              columns={applicantTableColumns}
              data={group.demo ? applicationsMockup : applicantions}
              applicantSlider={applicantSlider}
              selectedApplicant={selectedApplicant}
              closeSlider={() => this.setState({ applicantSlider: false })}
            />
          </div>
        </TabPanel>

        <Slider
          title={
            editMode
              ? `Edit ${member.name || "your member"}`
              : "Add a new member"
          }
          isOpen={this.state.openSlider}
          onClose={() =>
            this.setState({
              openSlider: false
            })
          }
        >
          <div className="p-4">
            <Formik
              initialValues={member}
              onSubmit={values => this.handleSubmitMemberForm(values)}
            >
              {({ handleSubmit, values, handleChange, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <TeamMemberForm
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    isEditing={editMode}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Slider>
        {showPublishedModal && (
          <ModalContainer
            data_cy="modal-activate-job-success"
            hideCloseBtn
            modalState={showPublishedModal}
            modalWidth="max-w-3xl"
          >
            <ModalHeader
              title={`${job_post.title} is active!`}
              description="Awesome, you just placed a brand new job! View and edit the job under the vacancies tab"
            />
            <div className="flex flex-col justify-center items-center mt-6 space-y-6">
              <img
                className="w-64"
                src="https://media.giphy.com/media/f9RHPRFxJhKTF0MQth/giphy.gif"
              />
              <div className="flex gap-2">
                <div>
                  <Link to="/vacancies">
                    <Button text="Go back to jobs" theme="white" />
                  </Link>
                </div>
              </div>
            </div>
          </ModalContainer>
        )}
      </>
    );
  }
}

// Connect Redux
function mapStateToProps(state) {
  return {
    job_post: state.vacancies.single_vacancy,
    loading: state.vacancies.isLoading,
    fetching: state.vacancies.loadingState.fetching,
    loading_update: state.vacancies.loadingState.update,
    loading_publish: state.vacancies.loadingState.publish,
    publish_done: state.vacancies.publishDone,
    loadingState: state.vacancies.loadingState,
    errorState: state.vacancies.errorState,
    group: state.auth.group,
    profile: state.auth.profile,
    applicantions: state.applicants.applications,
    skillOptions: state.Skills.skillOptions
  };
}

export default connect(mapStateToProps, {
  getVacancy,
  activateVacancy,
  updateVacancy,
  getApplicationsByVacancy,
  getSkills
})(withRouter(EditJob));
