/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { RegisterForm, CompleteForm, OnboardingForm, VerifyForm } from "../components/Forms";
import { createAccount, verifyAccount, onboardGroup } from "../actions/Auth";
import { updateGroup } from "../actions/Group";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingStatus: "signup",
      user: {},
      group: {},
      trigger: false
    };
  }

  async componentDidUpdate(nextProps, history) {
    if (!this.props.error && !this.state.showingError) {
      if (this.props.message) {
        toast.success(this.props.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });

        await this.setState({ showingError: true });
      }
    }
    if (this.props.error && !this.state.showingError) {
      if (this.props.message) {
        toast.error(this.props.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });
        await this.setState({ showingError: true });
      }
    }
  }

  handleCheck = e => {
    this.setState({ checked: !this.state.checked });
  };

  onRegister = async () => {
    if (!this.state.checked) {
      return toast.error("Please first check the agreement to continue the register process", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
    const { name, email, password, password2, groupName, checked, adress, city, country, postal_code, icon, demo } = this.state;
    if ((name && email && password && password2 && groupName && checked && adress && city && country && postal_code, icon)) {
      this.props.registerUser(name, email, password, password2, groupName, checked, adress, city, country, postal_code, icon, demo).then(res => {
        this.setState({ showingError: false });
      });
    } else {
      return toast.error("Please fill in all the fields.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  onUploadIcon = e => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let newState = Object.assign({}, this.state);
      newState.icon = reader.result;
      this.setState(newState);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  handleSubmit = async values => {
    this.setState({ trigger: true });
    const { createAccount } = this.props;
    try {
      const create_user = await createAccount(values);
      const { status, data } = create_user.payload.data;
      if (status === 200) {
        this.setState({
          onboardingStatus: "verify",
          user: data
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleVerifySubmit = async values => {
    const { verifyAccount } = this.props;
    const { user } = this.state;
    try {
      const verify_user = await verifyAccount(user.email, values);
      const { status, data } = verify_user.payload.data;

      if (status === 200) {
        this.setState({
          onboardingStatus: "onboarding",
          group: data.group
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleOnboardingSubmit = async values => {
    const { onboardGroup } = this.props;
    const { group } = this.state;

    try {
      const onboard_user = await onboardGroup(group, values);
      const { status } = onboard_user.payload.data;
      if (status === 200) {
        return this.setState({
          onboardingStatus: "complete"
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleOncancelOnboarding = () => {
    this.setState({
      onboardingStatus: "complete"
    });
  };

  render() {
    const { auth_loading, auth_error, auth_message, group_loading, group_error, group_message } = this.props;
    const { onboardingStatus, trigger } = this.state;
    return (
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {onboardingStatus === "signup" && (
              <RegisterForm
                type="signup"
                handleSubmit={values => this.handleSubmit(values)}
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
                title="Create your studio account"
                redirectText="Login"
                redirectLink="/login"
                trigger={trigger}
              />
            )}
            {onboardingStatus === "verify" && (
              <VerifyForm
                title="Verify your account"
                description="You received an email for us! Fill in the code to verify your account"
                onSubmit={values => this.handleVerifySubmit(values)}
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
              />
            )}
            {onboardingStatus === "onboarding" && (
              <OnboardingForm
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
                title="Let's make it official!"
                description="Fill in the details of your company here, or do it later. This is necessary before placing vacancies."
                handleSubmit={values => this.handleOnboardingSubmit(values)}
                onCancel={() => this.handleOncancelOnboarding()}
              />
            )}
            {onboardingStatus === "complete" && (
              <CompleteForm title="Mission accomplished!" description="Login to your account and start placing your vacancies." redirect="/login" redirectText="Login" />
            )}
          </div>
        </div>

        <div
          className="hidden lg:block relative flex-1 bg-cover bg-center"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1598257006303-031250badbdc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80")'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-indigo-500 opacity-100"></div>
        </div>
      </div>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    auth_error: state.auth.err,
    auth_message: state.auth.message,
    auth_loading: state.auth.isLoading,
    group_loading: state.group.isLoading,
    group_error: state.group.err,
    group_message: state.group.message
  };
}

export default connect(mapStateToProps, { createAccount, verifyAccount, updateGroup, onboardGroup })(Register);
