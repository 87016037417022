import React from "react";

const Label = props => {
  const { children, extraLabel, extraLabelAction, name } = props;
  return (
    <div className="flex justify-between mb-1">
      <label
        htmlFor={name}
        className="mb-0 block text-sm font-medium text-gray-700"
      >
        {children}
      </label>
      {extraLabel && (
        <span
          onClick={extraLabelAction}
          className="mb-0 text-sm text-gray-500"
          id="email-optional"
        >
          Optional
        </span>
      )}
    </div>
  );
};

export default Label;
