import axios from "axios";
import Cookies from "js-cookie";

import { API_URL } from "../../constants/url";
import { bearer } from "../../helpers/Bearer";

import {
  ASSET_GET_MANY_IS_LOADING,
  ASSET_GET_MANY_DATA,
  ASSET_GET_MANY_ERROR
} from "./types";

const getAssets = (groupId = "") => async dispatch => {
  const header = bearer();

  try {
    dispatch({ type: ASSET_GET_MANY_IS_LOADING, action: "getMany" });

    // API call
    const {
      data: { data: files }
    } = await axios.get(
      `${API_URL}/groups/${groupId}/media/photos/all`,
      header
    );

    return dispatch({ type: ASSET_GET_MANY_DATA, files });
  } catch (err) {
    return dispatch({ type: ASSET_GET_MANY_ERROR, err });
  }
};

export default getAssets;
