import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import FuzzySearch from "fuzzy-search";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { CSVLink } from "react-csv";

import { Slider } from "../Slider";
import { Tab, TabList, TabPanel } from "../Tab";
import { SearchIcon } from "../Icon";

import { BasicInfo, Header, Questions, Softskills } from "../../Molecules/Applicant";
import { ToggleMenu, MenuItem } from "../../Molecules/Navigation";

import { Input } from "..";

const Table = props => {
  const { demo } = props;
  const [tableData, setTableData] = useState([]);
  const [activeTabIdx, setActiveTab] = useState(0);
  useEffect(() => {
    setTableData(props.data);
  }, [tableData]);

  const searcher = new FuzzySearch(props.data, ["name", "vacancyTitle"], {
    caseSensitive: true
  });

  const handleFilterSearch = e => {
    setTableData(searcher.search(e.target.value));
  };

  return (
    <>
      <div className="flex justify-between items-center gap-4 bg-white rounded-t-md border-b border-gray-200 p-2">
        <div className="flex items-center space-x-2">
          <SearchIcon className="h-5 w-5" />
          <Input required={false} placeholder="search" onChange={e => handleFilterSearch(e)} />
        </div>
        <ToggleMenu>
          <CSVLink
            data={props.data}
            filename={`otellu-candidates-${moment().format("L")}.csv`}
            headers={[
              { label: "Name", key: "name" },
              { label: "Phone", key: "phone" },
              { label: "Email", key: "email" },
              { label: "Vacancy", key: "vacancyTitle" },
              { label: "Motivation", key: "motivation" },
              { label: "Resume", key: "resume" }
            ]}
          >
            <button data-tip="custom show" data-for="export-applicants" type="button" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              Export applicants
            </button>
          </CSVLink>
        </ToggleMenu>
      </div>

      <ReactTable className="bg-white" columns={props.columns} data={tableData} pageSize={10} showPageSizeOptions={false} resizable={true} />
      <Slider isOpen={props.selectedApplicant !== null && props.applicantSlider} onClose={props.closeSlider} sliderWidth="max-w-lg">
        <Header selectedApplicant={props.selectedApplicant} />
        <div className="py-4">
          <TabList>
            <Tab name="Basic info" tabIdx={0} activeTabIdx={activeTabIdx} onClick={() => setActiveTab(0)} />
            {props.selectedApplicant !== null &&
              props.selectedApplicant.addonData.map((x, index) => {
                if (x.name == "Youtube") {
                  return null;
                }
                return <Tab name={x.name} tabIdx={index + 1} activeTabIdx={activeTabIdx} onClick={() => setActiveTab(index + 1)} />;
              })}
          </TabList>
        </div>
        <div className="px-4">
          <TabPanel activeTabIdx={activeTabIdx} tabIdx={0}>
            <BasicInfo selectedApplicant={props.selectedApplicant} demo={demo} />
          </TabPanel>
          {props.selectedApplicant !== null &&
            props.selectedApplicant.addonData.map((x, index) => (
              <>
                {x.name === "Questions" && (
                  <TabPanel activeTabIdx={activeTabIdx} tabIdx={index + 1}>
                    <Questions data={x.data} />
                  </TabPanel>
                )}

                {x.name === "Soft Skills" && (
                  <TabPanel activeTabIdx={activeTabIdx} tabIdx={index + 1}>
                    <Softskills data={x.data} />
                  </TabPanel>
                )}

                {x.name === "Youtube" && null}
              </>
            ))}
        </div>
      </Slider>
    </>
  );
};

export default Table;
