import React from "react";
import { useDropzone } from "react-dropzone";

import { Divider, Button } from "../../../Atoms/Elements";

const GalleryUpload = ({
  handleOpenMediaLibrary,
  handleFileUploaded,
  ...rest
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: true,
    onDrop: files => handleFileUploaded(files)
  });

  return (
    <div {...rest}>
      <input
        name="gallery"
        className="cursor-pointer"
        data-cy="drag-and-drop"
        {...getInputProps()}
      />

      <p
        {...getRootProps()}
        className="mb-0"
        data-cy="button-upload-image-local"
      >
        Upload image
      </p>
      <Divider
        text="OR"
        borderColor="border-gray-300"
        bg="bg-gray-100"
        width="w-32"
      />
      <div>
        <Button
          data_cy="button-edit-job-upload-gallery"
          colorScheme="default"
          size="sm"
          type="button"
          onClick={handleOpenMediaLibrary}
        >
          From media library
        </Button>
      </div>
    </div>
  );
};

export default GalleryUpload;
