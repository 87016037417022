import axios from "axios";
import Cookies from "js-cookie";

import { API_URL } from "../constants/url";
import { MEMBERS, MEMBERS_IS_LOADING, MEMBERS_ERROR } from "./types/member";

export const getMembers = groupId => async dispatch => {
  try {
    dispatch({ type: MEMBERS_IS_LOADING });
    const response = await axios.get(`${API_URL}/group/members/${groupId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    return dispatch({ type: MEMBERS, payload: response });
  } catch (err) {
    return dispatch({
      type: MEMBERS_ERROR,
      payload: err
    });
  }
};
