import React from "react";

const UnlockDemo = props => {
  if (props.currentReviewIdx !== props.reviewIdx) {
    return null;
  }
  return (
    <div
      className="flex flex-col mx-auto align-bottom bg-white rounded-lg  text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-2"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="mt-6 text-3xl font-extrabold text-gray-900 leading-tight">It's time to take your hiring to the next level</h3>
          <p className="text-base leading-5 text-gray-500 mb-0">Benefit from all features of Otellu Studio by going live. </p>
        </div>
        <dl className="my-8 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:col-gap-6 sm:row-gap-6 lg:grid-cols-2 lg:col-gap-8">
          <div className="flex space-x-3">
            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
            <div className="space-y-2">
              <dt className="text-base leading-6 font-medium text-gray-600">Unlimited vacancies</dt>
            </div>
          </div>
          <div className="flex space-x-3">
            <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
            <div className="space-y-2">
              <dt className="text-base leading-6 font-medium text-gray-600">Unlimited applicants</dt>
            </div>
          </div>
        </dl>
      </div>
      <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span onClick={props.onSubmit} className="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Go live
          </button>
        </span>
        <span onClick={props.onClose} className="flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Not yet
          </button>
        </span>
      </div>
      <div className="mt-4">
        <p className="text-xs text-gray-500 mb-0">
          Note: Going live with your Otellu Studio account is irreversible. The received applicants will be billed from the 4th applicant in the first month of usage. For more information about on
          pricing, check our{" "}
          <a target="_blank" href="https://otellu.work/pricing">
            pricing page
          </a>
        </p>
      </div>
    </div>
  );
};

export default UnlockDemo;
