import React from "react";
import { Transition } from "@headlessui/react";

const ModalContainer = props => {
  const { modalState, hideCloseBtn } = props;
  return (
    <div className="absolute z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <Transition
          show={modalState}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          className={`inline-block align-center px-4 py-8 bg-white relative rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:${props.modalWidth} sm:w-full`}
          data-cy={props.data_cy}
        >
          {!hideCloseBtn && (
            <div className="absolute" style={{ right: "20px", top: "20px" }}>
              <button
                type="button"
                data-cy="modal-button-close"
                onClick={() => props.onClose()}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          )}
          {props.children}
        </Transition>
      </div>
    </div>
  );
};

export default ModalContainer;
