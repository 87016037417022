import React from "react";
import toast from "react-hot-toast";
import { XIcon } from "@heroicons/react/solid";

export const getErrorMessage = err => {
  const { message } = err.payload.response.data.childError;
  return message;
};

export const triggerToastSuccess = message => {
  const handleDismiss = t => {
    toast.dismiss(t.id);
  };
  return toast.success(
    t => (
      <div data-cy="toast-success" className="flex items-center">
        <div class="flex items-center">
          <div class="flex-1 flex justify-between">
            <p class="mb-0 flex-1 text-base font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: message }} />
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              onClick={() => handleDismiss(t)}
              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Close</span>
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    ),
    {
      duration: 8000
    }
  );
};

export const triggerToastError = (message, includeSupport = true) => {
  const handleSupport = t => {
    window.MessageBirdChatWidget.toggleChat();
    toast.dismiss(t.id);
  };
  const handleDismiss = t => {
    return toast.dismiss(t.id);
  };
  return toast.error(
    t => (
      <div data-cy="toast-error" className="flex items-center">
        <div class="flex items-center">
          <div class="flex-1 flex justify-between">
            <p class="mb-0 flex-1 text-base font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: message }} />
            {includeSupport && (
              <button
                onClick={() => handleSupport(t)}
                class="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Support
              </button>
            )}
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              onClick={() => handleDismiss(t)}
              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Close</span>
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    ),
    {
      duration: 8000
    }
  );
};
