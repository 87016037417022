import axios from "axios";
import Cookies from "js-cookie";

import { API_URL } from "../constants/url";
import {
  APPLICATIONS_BY_GROUP_IS_LOADING,
  APPLICATIONS_BY_GROUP,
  APPLICATIONS_BY_GROUP_ERROR,
  APPLICATIONS_BY_VACANCY_IS_LOADING,
  APPLICATIONS_BY_VACANCY,
  APPLICATIONS_BY_VACANCY_ERROR,
  SINGLE_APPLICATION_IS_LOADING,
  SINGLE_APPLICATION,
  SINGLE_APPLICATION_ERROR
} from "./types/applicants";

export const getApplicationsByGroup = groupId => async dispatch => {
  try {
    dispatch({ type: APPLICATIONS_BY_GROUP_IS_LOADING });
    const response = await axios.get(`${API_URL}/applications/?groupId=${groupId}&isVerified=true`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    return dispatch({ type: APPLICATIONS_BY_GROUP, response });
  } catch (err) {
    return dispatch({
      type: APPLICATIONS_BY_GROUP_ERROR,
      payload: err
    });
  }
};

export const getApplicationsByVacancy = vacancyId => async dispatch => {
  try {
    dispatch({ type: APPLICATIONS_BY_VACANCY_IS_LOADING });
    const response = await axios.get(`${API_URL}/applications/?vacancyId=${vacancyId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    return dispatch({ type: APPLICATIONS_BY_VACANCY, response });
  } catch (err) {
    return dispatch({
      type: APPLICATIONS_BY_VACANCY_ERROR,
      payload: err
    });
  }
};

export const getApplication = applicantId => async dispatch => {
  try {
    dispatch({ type: SINGLE_APPLICATION_IS_LOADING });
    const response = await axios.get(`${API_URL}/applications/${applicantId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    return dispatch({ type: SINGLE_APPLICATION, response });
  } catch (err) {
    return dispatch({
      type: SINGLE_APPLICATION_ERROR,
      payload: err
    });
  }
};
