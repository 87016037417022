import axios from 'axios';
import { API_URL } from '../../constants/url';
import {bearer} from '../../helpers/Bearer';
const header = bearer();

//TOTO POST partners/:partnerId/sendInvite
//TOTO GET partners/:partnerId/referred-companies

export const SEND_INVITE_PARTNER_IS_LOADING = 'send_invite_partner_is_loading';
export const SEND_INVITE_PARTNER_DATA = 'send_invite_partner_data';
export const SEND_INVITE_PARTNER_ERROR = 'send_invite_partner_error';

export const GET_REFFERED_COMPANIES_IS_LOADING = 'get_reffered_companies_is_loading';
export const GET_REFFERED_COMPANIES_DATA = 'get_reffered_companies_data';
export const GET_REFFERED_COMPANIES_ERROR = 'get_reffered_companies_error';

export const sendPartnerInvite = (partnerId, email, companyName) => async dispatch => {
  try {
    dispatch({ type: SEND_INVITE_PARTNER_IS_LOADING });
    const result = await axios.post(`${API_URL}/partners/${partnerId}/sendInvite`,{
      email:email,
      companyName:companyName
    },header);
    return dispatch({ type: SEND_INVITE_PARTNER_DATA, result });
  } catch (err) {
    return dispatch({
      type: SEND_INVITE_PARTNER_ERROR,
      payload: err
    });
  }
};

export const getRefferedCompanies = (partnerId) => async dispatch => {
  try {
    dispatch({ type: GET_REFFERED_COMPANIES_IS_LOADING });
    const result = await axios.get(`${API_URL}/partners/${partnerId}/referred-companies`,header);
    return dispatch({ type: GET_REFFERED_COMPANIES_DATA, result });
  } catch (err) {
    return dispatch({
      type: GET_REFFERED_COMPANIES_ERROR,
      payload: err
    });
  }
}


