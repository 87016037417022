import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, Alert } from "../Atoms";
import * as Yup from "yup";

const signup_values = {
  name: "",
  company: "",
  email: "",
  password: ""
};

const inv_signup_values = {
  name: "",
  email: "",
  password: ""
};

const RegisterForm = props => {
  const { message, error, loading, trigger } = props;
  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
  });
  return (
    <div>
      <img className="h-12 w-auto" src={require("../../images/logos/otellu-icon-v1.svg")} alt="otellu" />
      <h2 className="mt-6 text-4xl font-extrabold text-gray-900">{props.title}</h2>
      {props.redirectLink && props.redirectText && (
        <p className="mt-2 text-sm text-gray-600 max-w">
          Or{" "}
          <Link to={props.redirectLink} className="font-medium text-indigo-600 hover:text-indigo-500">
            {props.redirectText}
          </Link>
        </p>
      )}

      <div className="mt-8">
        {!loading && error && trigger && <Alert status="error" message={message} />}
        <div className="mt-6">
          <Formik
            initialValues={props.type === "inv_signup" ? inv_signup_values : signup_values}
            validationSchema={SignupSchema}
            onSubmit={async values => {
              await new Promise(r => setTimeout(r, 500));
              props.handleSubmit(values);
            }}
          >
            <Form className="space-y-4">
              <>
                <div>
                  <label for="email" className="mb-0 block text-sm font-medium text-gray-700">
                    Full name
                  </label>
                  <div className="mt-1">
                    <Field
                      data-cy="input-name"
                      id="name"
                      name="name"
                      placeholder="Elon Musk"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                {props.type === "signup" && (
                  <div>
                    <label for="email" className="mb-0 block text-sm font-medium text-gray-700">
                      Company
                    </label>
                    <div className="mt-1">
                      <Field
                        data-cy="input-company"
                        id="company"
                        name="company"
                        type="text"
                        placeholder="Tesla"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              </>
              <div>
                <label for="email" className="mb-0 block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <Field
                    data-cy="input-email"
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="elon@tesla.com"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="space-y-1">
                <div className="flex justify-between items-center">
                  <label for="password" className="mb-0 block text-sm font-medium text-gray-700 leading-none">
                    Password
                  </label>
                  <ErrorMessage component="p" className="mb-0 text-xs text-red-400 text-bold leading-none" name="password" />
                </div>
                <div className="mt-1">
                  <Field
                    data-cy="input-password"
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder="*****************"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="w-full">
                <Button data_cy="button-register" theme="purple" text="Sign up" type="submit" loading={loading} />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
