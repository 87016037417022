import React from "react";

const Sidebar = props => {
  return (
    <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
      <nav className="space-y-1">{props.children}</nav>
    </aside>
  );
};

export default Sidebar;
