/* eslint jsx-a11y/anchor-is-valid: 0 */
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { RegisterForm, CompleteForm, OnboardingForm, VerifyForm } from "../components/Forms";
import { createInviteAccount, verifyAccount, onboardGroup, checkMemberInviteCode } from "../actions/Auth";
import { updateGroup } from "../actions/Group";

class RegisterMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingStatus: "inv_verify",
      user: {},
      group: {},
      trigger: false,
      groupId: ""
    };
  }

  componentDidMount = async () => {
    this.setState({
      groupId: window.location.pathname
        .split("/")
        .slice(-1)
        .pop()
    });
  };

  handleVerifyInviteSubmit = async values => {
    const { checkMemberInviteCode } = this.props;
    const { groupId } = this.state;
    try {
      const verify_code = await checkMemberInviteCode(groupId, values);
      console.log(verify_code);

      const { status, data } = verify_code.payload.data;
      console.log(status);

      if (status === 200) {
        console.log("try to change layout");
        this.setState({
          onboardingStatus: "signup",
          group: data
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleSubmit = async values => {
    const { groupId } = this.state;
    this.setState({ trigger: true });
    const { createInviteAccount } = this.props;
    try {
      const create_user = await createInviteAccount(values, groupId);
      const { status, data } = create_user.payload.data;
      if (status === 200) {
        this.setState({
          onboardingStatus: "verify",
          user: data
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleVerifySubmit = async values => {
    const { verifyAccount } = this.props;
    const { user } = this.state;
    try {
      const verify_user = await verifyAccount(user.email, values);
      const { status, data } = verify_user.payload.data;
      if (status === 200) {
        this.setState({
          onboardingStatus: "complete",
          group: data
        });
      }
    } catch (err) {
      return err;
    }
  };

  render() {
    const { auth_loading, auth_error, auth_message, group_loading, group_error, group_message } = this.props;
    const { onboardingStatus, trigger, group } = this.state;
    console.log(onboardingStatus);
    return (
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {onboardingStatus === "inv_verify" && (
              <VerifyForm
                title="Hey stranger, verify your invitation"
                description="You received an email for us! Fill in the code to verify your inivation"
                onSubmit={values => this.handleVerifyInviteSubmit(values)}
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
              />
            )}

            {onboardingStatus === "signup" && (
              <RegisterForm
                type="invite_signup"
                handleSubmit={values => this.handleSubmit(values)}
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
                title={`Create your studio account for ${group.title}`}
                trigger={trigger}
              />
            )}

            {onboardingStatus === "verify" && (
              <VerifyForm
                title="Verify your account"
                description="You received an email for us! Fill in the code to verify your account"
                onSubmit={values => this.handleVerifySubmit(values)}
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
              />
            )}

            {onboardingStatus === "complete" && <CompleteForm title="Mission accomplished!" description="You're now all set to place your first job!" redirect="/login" redirectText="Login" />}
          </div>
        </div>
        <div
          className="hidden lg:block relative  flex-1 bg-cover"
          style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80")' }}
        ></div>
      </div>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    auth_error: state.auth.err,
    auth_message: state.auth.message,
    auth_loading: state.auth.isLoading,
    group_loading: state.group.isLoading,
    group_error: state.group.err,
    group_message: state.group.message
  };
}

export default connect(mapStateToProps, { createInviteAccount, verifyAccount, updateGroup, onboardGroup, checkMemberInviteCode })(RegisterMember);
