import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { maxBy } from 'lodash'

import { MediaLibraryContext } from '../../../../context'

import GalleryImages from './GalleryImages'
import GalleryUpload from './GalleryUpload'

import { createAsset } from '../../../../actions'

const Gallery = props => {
  const dispatch = useDispatch()
  const { setCallback, setMultiSelect, setTitle, setOpen, setExclude } = useContext(MediaLibraryContext)
  const { group } = useSelector(state => state.auth)

  const {
    files,
    errors,
    loading,
    touched,
    mediaLibraryCallback,
    handleGalleryChanged
  } = props;

  const handleRemoveImage = file => {
    const newFiles = files.filter(({ Key }, index) => Key !== file.Key);
    
    handleGalleryChanged(newFiles)
  }

  const handleFileUploaded = (_files) => {
    // upload the files to the file library
    dispatch(createAsset(group._id, _files)).then(res => {
      if (res.file) {
        const { order: maxOrder } = maxBy(files || [], (image) => image.order) || { order: 0 }
        const ordered = res.file.map((f, i) => ({ ...f, order: i + maxOrder + 1 }))

        handleGalleryChanged(files ? [...files, ...ordered] : ordered)
      }
    })
  }

  const handleGallerySorted = (files) => {
    const newFiles = files.map((file, index)=> ({ ...file, order: index }))

    handleGalleryChanged(newFiles)
  }

  const handleOpenMediaLibrary = () => {
    setCallback((selected) => {
      mediaLibraryCallback(selected)
      setOpen(false)
    })
    setExclude(files)
    console.log('files excluded set', files)
    setTitle('Add image(s) to your vacancy')
    setMultiSelect(true)
    setOpen(true)
  }

  if (loading) {
    return (
      <div
        className="bg-gray-100 w-full border-dashed border-4 border-gray-300 cursor-pointer"
        style={{ height: "400px" }}
      >
        <div className="flex justify-center items-center min-h-full">
          <p className="text-lg font-medium text-gray-700">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {!files.length && (
        <div
          className="bg-gray-100 w-full border-dashed border-4 border-gray-300 cursor-pointer"
          style={{ height: "400px" }}
        >
          <GalleryUpload
            className="flex flex-col justify-center items-center min-h-full space-y-2"
            handleOpenMediaLibrary={handleOpenMediaLibrary}
            handleFileUploaded={handleFileUploaded}
          />
        </div>
      )}

      {files && files.length ? (
        <GalleryImages
          files={files}
          handleGallerySorted={handleGallerySorted}
          handleRemoveImage={handleRemoveImage}
          handleOpenMediaLibrary={handleOpenMediaLibrary}
          handleFileUploaded={handleFileUploaded}
        />
      ) : null}
    </div>
  );
};

export default Gallery;
