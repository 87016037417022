import { INVOICES, INVOICES_IS_LOADING, INVOICES_ERROR } from "../../actions/types/billing";

export default function(
  state = {
    err: false,
    loadingState: {
      invoices: false
    },
    invoices: []
  },
  action
) {
  switch (action.type) {
    case INVOICES_IS_LOADING: {
      return { ...state, loadingState: { invoices: true }, err: false };
    }

    case INVOICES: {
      const { data } = action.response;
      return { ...state, loadingState: { invoices: false }, invoices: data, err: false };
    }

    case INVOICES_ERROR: {
      return { ...state, loadingState: { invoices: false }, err: true };
    }

    default:
      return state;
  }
}
