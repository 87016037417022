import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../../constants/url";
import { bearer } from "../../helpers/Bearer";
const header = bearer();
export const VACANCIES_BY_GROUP_IS_LOADING = "vacancies_by_group_is_loading";
export const VACANCIES_BY_GROUP_DATA = "vacancies_by_group_data";
export const VACANCIES_BY_GROUP_ERROR = "vacancies_by_group_error";

export const getVacanciesByGroup = groupId => async dispatch => {
  try {
    dispatch({ type: VACANCIES_BY_GROUP_IS_LOADING });
    const result = await axios.get(`${API_URL}/vacancies/groups/${groupId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });

    const headerImageURLs = [];

    // // Find the header images associated with each vacancy
    // // to use the first image as an example in the vacancy overview
    // if (result) {
    //   await Promise.all(
    //     result.data.vacancies.map(async vac => {
    //       // returns all vacancy files (images)
    //       const vacFiles = await axios.get(`${API_URL}/aws/folder/${vac._id}`, {
    //         headers: {
    //           Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
    //         }
    //       });

    //       // use the first image as a preview
    //       headerImageURLs.push(vacFiles.data[0].Key);
    //     })
    //   );
    // }

    // Add the ordered pictures to the vacancy state obj
    result.data.vacancies.forEach((v, idx) => {
      result.data.vacancies[idx].previewImgURL = v.gallery.length
        ? v.gallery[0].Key
        : "";
    });

    return dispatch({ type: VACANCIES_BY_GROUP_DATA, result });
  } catch (err) {
    return dispatch({
      type: VACANCIES_BY_GROUP_ERROR,
      payload: err
    });
  }
};
