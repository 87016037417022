import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import { withRouter, Link } from "react-router-dom";
import Truncate from "react-truncate";
import dayjs from "dayjs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { getVacanciesByGroup, getVacanciesByAccount } from "../actions/index";
import { getAllVacancies, draftVacancy } from "../actions/vacancy";
import { goLive } from "../actions/Group";
import { onboardGroup, addPackage, getGroup } from "../actions/Auth";
import { CompleteForm, OnboardingForm } from "../components/Forms";
import { PackageModal } from "../components/Molecules/Modal";
import PageTitle from "../components/common/PageTitle";
import { Modal, Success, UnlockDemo, Error } from "../components/Modal";
import { ModalContainer, ModalHeader } from "../components/Atoms/Modal";
import { Input, Button } from "../components/Atoms";
import { EmptyJobCard, JobCard, SkeletonJobCard } from "../components/Molecules/Card";
import { vacanciesMockup } from "../data/mockup";

class Vacancies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacancies_by_group: [],
      vacancies_by_account: [],
      allow_placing_vacancy: true,
      unlockDemoModal: false,
      onBoardModal: false,
      onBoardSuccessModal: false,
      packageInfo: {
        value: 1
      },
      packageModal: false,
      packageModalTrigger: false
    };
  }

  componentDidMount = async () => {
    const { group, profile, isAuthenticated } = this.props;
    if (group && isAuthenticated) {
      await this.props.getGroup(group._id);
      await this.props.getAllVacancies(group._id);
      // await this.props.getVacanciesByAccount(profile._id);
      this.setState({
        vacancies_by_group: this.props.vacancies_by_group
      });
    }
  };
  linkTo = id => {
    this.props.history.push(`/vacancy/${id}`);
  };

  showMyVacancies = async () => {
    const { profile } = this.props;

    await this.props.getVacanciesByAccount(profile._id);
    this.setState({
      vacancies_by_account: this.props.vacancies_by_account
    });
  };

  //check if required fields are filled in
  handleAddVacancy = () => {
    const { country, city, address, zip, demo } = this.props.group;
    const { vacancies_by_group } = this.state;

    if (!country || !city || !address || !zip) {
      this.setState({
        onBoardModal: true
      });
    } else if (vacancies_by_group.length !== 0 && demo) {
      this.setState({
        unlockDemoModal: true
      });
    } else {
      return this.props.history.push("/add-vacancy");
    }
  };

  handleOnboardingSubmit = async values => {
    const { onboardGroup, group } = this.props;
    try {
      const onboard_user = await onboardGroup(group, values);
      const { status } = onboard_user.payload.data;

      if (status === 200) {
        this.setState({
          onBoardModal: false,
          onBoardSuccessModal: true
        });
      }
    } catch (err) {
      return err;
    }
  };

  draftVacancy = async slot => {
    const { group, draftVacancy } = this.props;
    try {
      const draft_vacancy = await draftVacancy(group, slot);

      const { status, data } = draft_vacancy.payload.data;
      if (status === 200) {
        return this.props.history.push(`/job/create/${data._id}`);
      }
    } catch (err) {
      return err;
    }
  };

  editJob = id => {
    return this.props.history.push(`/job/edit/${id}`);
  };

  handleAddPackage = async packageInfo => {
    const { group, addPackage } = this.props;
    await addPackage(group, packageInfo);
    this.setState({
      packageModal: false,
      packageModalTrigger: true
    });
  };

  render() {
    const { isLoading, group, group_error, group_loading, group_message } = this.props;
    const { onBoardModal, onBoardSuccessModal, unlockDemoModal, packageInfo, packageModal, packageModalTrigger } = this.state;

    const groupInfoCheck = group => {
      if (!group.country || !group.city || !group.address || !group.zip || !group.icon) {
        return true;
      }
    };

    return (
      <Container fluid className="main-content-container px-4 relative">
        <div className="max-w-7xl mx-auto">
          <Row noGutters className="page-header py-4">
            {/* Page Header :: Title */}
            <PageTitle title="Vacancies" subtitle="Overview" className="text-sm-left mb-3" />
            {/* Page Header :: Actions */}
            <Col>
              <div className="flex justify-end">
                {!groupInfoCheck(group) && (
                  <div>
                    <Button theme="purple" text="Buy a package" onClick={() => this.setState({ packageModal: true })} />
                  </div>
                )}

                {groupInfoCheck(group) && (
                  <div>
                    <Button theme="purple" text="Update your company" onClick={() => this.setState({ onBoardModal: true })} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Tabs>
            <TabList className="v-tab d-flex align-items-center">
              <Tab selectedClassName="v-tabs--selected" className="v-tabs">
                <Button text="All" theme="white" />
              </Tab>
            </TabList>
            <TabPanel>
              {group.vacancySlots.length !== 0 && (
                <div className="grid grid-cols-3 gap-4 py-8">
                  {group.vacancySlots
                    .filter(job => job.draftVacancy === null)
                    .map(job => (
                      <EmptyJobCard job={job} onClick={() => this.draftVacancy(job)} />
                    ))}
                </div>
              )}

              {/* )} */}

              {isLoading && (
                <div className="grid grid-cols-3 gap-4 py-8">
                  <SkeletonJobCard />
                  <SkeletonJobCard />
                  <SkeletonJobCard />
                </div>
              )}

              {!isLoading && (
                <div className="grid grid-cols-3 gap-4 py-8">
                  {group.demo && vacanciesMockup.map(job => <JobCard onClick={() => this.editJob(job._id)} type="active" job={job} />)}
                  {!group.demo &&
                    this.state.vacancies_by_group
                      .filter(job => job.draft)
                      .map(job => (
                        <>
                          <JobCard onClick={() => this.editJob(job._id)} type="draft" job={job} />
                        </>
                      ))
                  }
                  {!group.demo &&  
                    this.state.vacancies_by_group
                      .filter(job => !job.draft && !job.active)
                      .map(job => (
                        <>
                          <JobCard onClick={() => this.editJob(job._id)} type="pending" job={job} />
                        </>
                      ))
                  }
                  {!group.demo && this.state.vacancies_by_group.filter(job => job.active).map(job => <JobCard onClick={() => this.editJob(job._id)} type="active" job={job} />)}
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="grid grid-cols-3 gap-4 py-8">
                {this.state.vacancies_by_account.map(job => (
                  <>
                    <JobCard onClick={() => this.editJob(job._id)} type="active" job={job} />
                  </>
                ))}
              </div>
            </TabPanel>
          </Tabs>

          {onBoardModal && (
            <ModalContainer modalState={onBoardModal} onClose={() => this.setState({ onBoardModal: !this.state.onBoardModal })} modalWidth="max-w-3xl">
              <Modal>
                <OnboardingForm
                  loading={group_loading}
                  error={group_error}
                  message={group_message}
                  title="Let's make it official!"
                  description="Fill in the details of your company here, or do it later. This is necessary before placing vacancies."
                  handleSubmit={values => this.handleOnboardingSubmit(values)}
                  onCancel={() => this.setState({ onBoardModal: !this.state.onBoardModal })}
                />
              </Modal>
            </ModalContainer>
          )}
          {onBoardSuccessModal && (
            <ModalContainer modalState={onBoardSuccessModal} onClose={() => this.setState({ onBoardSuccessModal: !this.state.onBoardSuccessModal })} modalWidth="max-w-3xl">
              <Modal>
                <CompleteForm title="Mission accomplished!" description="You're now all set to place your first job!" redirect="/vacancies" redirectText="Add vacancy" />
              </Modal>
            </ModalContainer>
          )}
          {packageModal && (
            <ModalContainer modalState={packageModal} onClose={() => this.setState({ packageModal: false })} modalWidth="max-w-3xl">
              <ModalHeader title="Choose your package" description="Choose the amount of job slots and place your vacacancies directly." />
              <PackageModal
                loading={group_loading}
                labels={{
                  1: "1 job",
                  2: "2 jobs",
                  3: "3 jobs",
                  4: "4 jobs",
                  5: "5 jobs"
                }}
                min={1}
                max={5}
                value={packageInfo.value}
                handleChangeSlider={value =>
                  this.setState(prevState => ({
                    packageInfo: {
                      ...prevState.packageInfo,
                      value: value
                    }
                  }))
                }
                onSubmit={packageInfo => this.handleAddPackage(packageInfo)}
              />
            </ModalContainer>
          )}
          {packageModalTrigger && (
            <ModalContainer modalState={packageModalTrigger} onClose={() => this.setState({ packageModalTrigger: false })} modalWidth="max-w-3xl">
              <ModalHeader title="Mission completed" description="The job slots are added to your account. See the updated status in your billing overview. Let the party begin!" />
              <div className="flex flex-col justify-center items-center mt-10 space-y-5">
                <img className="w-64" src="https://media2.giphy.com/media/26BGP98lm74FJgfNS/giphy.gif?cid=ecf05e470tf530s7wqqd941o90ocy55vzxayrly912vj1rz6&rid=giphy.gif" />
                <div>
                  <Button text="Add a job" onClick={() => this.setState({ packageModalTrigger: false })} />
                </div>
              </div>
            </ModalContainer>
          )}
        </div>
      </Container>
    );
  }
}

Vacancies.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

//Connect redux
function mapStateToProps(state) {
  return {
    vacancies_by_group: state.vacancies.vacancies_by_group,
    vacancies_by_account: state.vacancies.vacancies_by_account,
    isLoading: state.vacancies.isLoading,
    fetching: state.vacancies.loadingState.fetching,
    group: state.auth.group,
    profile: state.auth.profile,
    group_error: state.auth.err,
    group_loading: state.auth.isLoading,
    group_message: state.auth.message,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, { getAllVacancies, getVacanciesByGroup, getVacanciesByAccount, getGroup, onboardGroup, addPackage, draftVacancy })(withRouter(Vacancies));
