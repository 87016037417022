import React from "react";
import faker from "faker";
import { PlusIcon, UserIcon } from "@heroicons/react/solid";
import { TeamCard } from "../components/Molecules/Card";
import { InfoPanel } from "../components/Molecules/InfoPanel";
import { SimpleEmptyState } from "../components/Molecules/EmptyState";
import { useNavHeight } from "../hooks";
import PageTitle from "../components/common/PageTitle";

const People = () => {
  const { headerHeight, footerHeight } = useNavHeight();

  const createUser = () => {
    return {
      fullName: `${faker.name.firstName()} ${faker.name.lastName()}`,
      role: faker.name.jobTitle(),
      image: faker.image.image()
    };
  };

  const createUsers = (numUsers = 12) => {
    return new Array(numUsers).fill(undefined).map(createUser);
  };

  const memberlist = createUsers();

  return (
    <div className="container px-4">
      <div
        className="max-w-7xl mx-auto grid grid-cols-12 overflow-hidden"
        style={{ maxHeight: `calc(100vh - ${headerHeight + footerHeight}px)` }}
      >
        <div className="col-span-8">
          <PageTitle title="Media" className="text-sm-left mb-3" />
          <div className="grid grid-cols-3 gap-4 h-screen overflow-y-scroll">
            {memberlist.map(x => (
              <TeamCard
                hasDelete={true}
                image={x.image}
                fullName={x.fullName}
                role={x.role}
                linkedinUrl="https://linkedin.com"
              />
            ))}
          </div>
        </div>

        <div className="col-span-4 pl-2">
          <InfoPanel
            onClose={() => console.log("x")}
            empty={true}
            title="View asset"
          >
            <div className="px-4">
              <SimpleEmptyState
                icon={
                  <UserIcon
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  />
                }
              />
            </div>
          </InfoPanel>
        </div>
      </div>
    </div>
  );
};

export default People;
