import React, { useEffect, useState } from "react";
import { Field } from "formik";
import axios from "axios";
import SearchField from "../../../helpers/searchFile";

const InfoForm = props => {
  const { values, errors, touched, setFieldValue } = props;
  const [jobOptions, setJobOptions] = useState([]);
  const [branches, setBranches] = useState(null);
  const [jobTitles, setJobTitles] = useState(null);
  const [jobLocations, setJobLocations] = useState(null);

  const changeBranch = e => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedBranch = branches.find(x => x._id.toString() === e.target.options[selectedIndex].getAttribute("data-key").toString());
    const list = jobOptions.filter(job => job.branch.toString() === e.target.options[selectedIndex].getAttribute("data-key").toString());
    setFieldValue("branch", selectedBranch.name);
    setJobTitles(list);
  };

  useEffect(() => {
    async function fetchList() {
      const {
        data: {
          data: { branches, jobtitles }
        }
      } = await axios.get(`${process.env.REACT_APP_STUDIO_WEB_API}/jobtitles/both`);
      const {
        data: { data: joblocations }
      } = await axios.get(`${process.env.REACT_APP_STUDIO_WEB_API}/joblocations`);
      setBranches(branches);
      setJobTitles(jobtitles);
      setJobOptions(jobtitles);
      setJobLocations(joblocations);
    }

    fetchList();
  }, []);

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 sm:col-span-4">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <Field
            data-cy="input-title-edit-job"
            defaultValue={values.title}
            value={values.title}
            id="title"
            name="title"
            type="text"
            placeholder="Finance controller"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {touched.title && <p className="mb-0 mt-1 text-sm text-red-400">{errors.title}</p>}
        </div>
        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="branch" className="block text-sm font-medium text-gray-700">
            Branch
          </label>
          <Field
            data-cy="select-branch-edit-job"
            defaultValue={values.branch}
            as="select"
            id="branch"
            name="branch"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            onChange={e => changeBranch(e)}
            required
          >
            <option value="" selected disabled hidden>
              Select a branch
            </option>
            {branches !== null &&
              branches.map((list, index) => (
                <option key={index} data-key={list._id} value={list.name}>
                  {list.name}
                </option>
              ))}
          </Field>
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">
            Job
          </label>
          <Field
            data-cy="select-job-edit-job"
            as="select"
            id="jobTitle"
            name="jobTitle"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
          >
            <option value="" selected disabled hidden>
              Select a job title
            </option>
            {jobTitles !== null &&
              jobTitles.map((list, index) => (
                <option key={index} value={list.humanReadable}>
                  {list.humanReadable}
                </option>
              ))}
          </Field>
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="employmentType" className="block text-sm font-medium text-gray-700">
            Employment type
          </label>
          <Field
            data-cy="select-employment-type-edit-job"
            as="select"
            id="employmentType"
            name="employmentType"
            autoComplete="country"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
          >
            <option value="" selected disabled hidden>
              Select a employment type
            </option>
            <option value="Full-time">Full-time</option>
            <option value="Part-time">Part-time</option>
            <option value="Stage">Stage</option>
          </Field>
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="workLevel" className="block text-sm font-medium text-gray-700">
            Work level
          </label>
          <Field
            data-cy="select-work-level-edit-job"
            as="select"
            id="workLevel"
            name="workLevel"
            autoComplete="workLevel"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
          >
            <option value="" selected disabled hidden>
              Select a work level
            </option>
            <option value="Junior">Junior</option>
            <option value="Mid-level">Mid-level</option>
            <option value="Senior">Senior</option>
          </Field>
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="locale" className="block text-sm font-medium text-gray-700">
            Language
          </label>
          <Field
            data-cy="select-language-edit-job"
            as="select"
            id="locale"
            name="locale"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
          >
            <option value="" selected disabled hidden>
              Select a language
            </option>
            <option value="en">English</option>
            <option value="nl">Dutch</option>
          </Field>
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="remoteWork" className="block text-sm font-medium text-gray-700">
            Job location
          </label>
          <Field
            data-cy="select-location-edit-job"
            as="select"
            id="city"
            name="city"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
          >
            <option value="" selected disabled hidden>
              Select the job location
            </option>
            {jobLocations &&
              jobLocations.map(x => (
                <option key={x._id} value={x._id}>
                  {x.name}
                </option>
              ))}
          </Field>
        </div>
      </div>
    </>
  );
};

export default InfoForm;
