import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//COMPONENTS
import { Pill } from "../../Atoms/Elements";

//REDUX ACTIONS
import { getVacancy } from "../../../actions/vacancy";

//DATA
import { vacanciesMockup } from "../../../data/mockup";

const BasicInfo = props => {
  const { selectedApplicant, job_post, getVacancy, demo, fetching } = props;

  useEffect(() => {
    async function fetchJobPost() {
      await getVacancy(selectedApplicant.vacancyId);
    }
    fetchJobPost();
  }, []);

  return (
    <div className="overflow-hidden ">
      <div className="pb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Applicant information</h3>
        <p className="mb-0 mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
      </div>
      <div className="border-t border-gray-200 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="pb-0 text-sm font-medium text-gray-500">Full name</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedApplicant.name}</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="pb-0 text-sm font-medium text-gray-500">Application for</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedApplicant.vacancyTitle}</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="pb-0 text-sm font-medium text-gray-500">Email address</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedApplicant.email}</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-6 sm:max-w-sm">
            <dt className="pb-0 text-sm font-medium text-gray-500">Motivation</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedApplicant.motivation}</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="pb-0 text-sm font-medium text-gray-500">Phone number</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedApplicant.phone}</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="pb-0 text-sm font-medium text-gray-500">Work level</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedApplicant.specifications.workLevel}</dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Resume</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path
                        fillRule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-2 flex-1 w-0 truncate">View resume</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a target="_blank" href={`${process.env.REACT_APP_AWS_URL}/resume/${selectedApplicant.applicantId}`} className="font-medium text-indigo-600 hover:text-indigo-500">
                      View
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>

      {demo && vacanciesMockup[0].skills.length !== 0 && (
        <div className="pb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Matched skills</h3>
          <p className="mb-0 mt-1 max-w-2xl text-sm text-gray-500">View the skills that are matches with an applicant.</p>
          <div className="mt-6 flex flex-wrap gap-2 max-w-lg py-8 px-4 bg-gray-100 rounded-lg">
            {vacanciesMockup[0].skills.map(x => (
              <Pill key={x.value} selected={selectedApplicant.skills.filter(e => e.value === x.value).length > 0}>
                {x.label}
              </Pill>
            ))}
          </div>
        </div>
      )}
      {!demo && job_post.length !== 0 && job_post.skills.length !== 0 && (
        <div className="pb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Matched skills</h3>
          <p className="mb-0 mt-1 max-w-2xl text-sm text-gray-500">View the skills that are matches with an applicant.</p>
          <div className="mt-6 flex flex-wrap gap-2 max-w-lg py-8 px-4 bg-gray-100 rounded-lg">
            {job_post.skills !== undefined &&
              job_post.skills.map(x => (
                <Pill key={x.value} selected={selectedApplicant.skills.filter(e => e.value === x.value).length > 0}>
                  {x.label}
                </Pill>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Connect Redux
function mapStateToProps(state) {
  return {
    job_post: state.vacancies.single_vacancy,
    fetching: state.vacancies.loadingState.fetching
  };
}

export default connect(mapStateToProps, { getVacancy })(BasicInfo);
