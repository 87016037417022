import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { triggerToastSuccess } from "../../helpers/handleResponse";

import { API_URL } from "../../constants/url";
import {
  AUTHENTICATE,
  AUTHENTICATE_IS_LOADING,
  AUTHENTICATE_ERROR,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_IS_LOADING,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_INV,
  CREATE_ACCOUNT_INV_IS_LOADING,
  CREATE_ACCOUNT_INV_ERROR,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_IS_LOADING,
  VERIFY_ACCOUNT_ERROR,
  PROFILE,
  PROFILE_IS_LOADING,
  PROFILE_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_IS_LOADING,
  LOGOUT_USER_ERROR,
  ONBOARD_GROUP,
  ONBOARD_GROUP_IS_LOADING,
  ONBOARD_GROUP_ERROR,
  UPDATE_GROUP,
  UPDATE_GROUP_IS_LOADING,
  UPDATE_GROUP_ERROR,
  UPLOAD_GROUP_ICON,
  UPLOAD_GROUP_ICON_IS_LOADING,
  UPLOAD_GROUP_ICON_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IS_LOADING,
  UPDATE_PROFILE_ERROR,
  UPLOAD_PROFILE_ICON,
  UPLOAD_PROFILE_ICON_IS_LOADING,
  UPLOAD_PROFILE_ICON_ERROR,
  CHECK_MEMBER_INV_CODE,
  CHECK_MEMBER_INV_CODE_IS_LOADING,
  CHECK_MEMBER_INV_CODE_ERROR,
  INVITE_MEMBER,
  INVITE_MEMBER_IS_LOADING,
  INVITE_MEMBER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_IS_LOADING,
  FORGOT_PASSWORD_ERROR,
  CHECK_RESET_PASSWORD_CODE,
  CHECK_RESET_PASSWORD_CODE_IS_LOADING,
  CHECK_RESET_PASSWORD_CODE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_IS_LOADING,
  RESET_PASSWORD_ERROR,
  ADD_PACKAGE,
  ADD_PACKAGE_IS_LOADING,
  ADD_PACKAGE_ERROR,
  GET_GROUP,
  GET_GROUP_IS_LOADING,
  GET_GROUP_ERROR
} from "../types/auth";

export const authenticate = values => async dispatch => {
  try {
    dispatch({ type: AUTHENTICATE_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth`, {
      email: values.email,
      password: values.password
    });
    Cookies.set("otellu-studio-jwt-token", response.data.data.token);
    return dispatch({ type: AUTHENTICATE, payload: response });
  } catch (err) {
    return dispatch({
      type: AUTHENTICATE_ERROR,
      payload: err
    });
  }
};

export const createAccount = values => async dispatch => {
  try {
    dispatch({ type: CREATE_ACCOUNT_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/register`, {
      name: values.name,
      company: values.company,
      email: values.email,
      password: values.password,
      demo: values.demo
    });
    return dispatch({ type: CREATE_ACCOUNT, payload: response });
  } catch (err) {
    return dispatch({
      type: CREATE_ACCOUNT_ERROR,
      payload: err
    });
  }
};

export const updateProfile = profile => async dispatch => {
  try {
    dispatch({ type: UPDATE_PROFILE_IS_LOADING });
    const response = await axios.put(`${API_URL}/profile/${profile._id}`, profile, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    triggerToastSuccess(response.data.message);
    return dispatch({ type: UPDATE_PROFILE, payload: response });
  } catch (err) {
    return dispatch({
      type: UPDATE_PROFILE_ERROR,
      payload: err
    });
  }
};

export const uploadProfileIcon = (id, file, profile) => async dispatch => {
  var bodyFormData = new FormData();
  bodyFormData.append(`file`, file[0], file[0].name);
  try {
    dispatch({ type: UPDATE_PROFILE_IS_LOADING });
    const aws_response = await axios.post(`${API_URL}/aws/folder/user/user/${id}`, bodyFormData);
    const response = await axios.put(
      `${API_URL}/profile/${profile._id}`,
      { ...profile, photo: aws_response.data.data },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );
    triggerToastSuccess(response.data.message);
    return dispatch({ type: UPDATE_PROFILE, payload: response });
  } catch (err) {
    return dispatch({
      type: UPDATE_PROFILE_ERROR,
      payload: err
    });
  }
};

export const verifyAccount = (email, verifyCode) => async dispatch => {
  try {
    dispatch({ type: VERIFY_ACCOUNT_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/verify`, {
      email,
      verifyCode
    });
    Cookies.set("otellu-studio-jwt-token", response.data.data.token);

    return dispatch({ type: VERIFY_ACCOUNT, payload: response });
  } catch (err) {
    return dispatch({
      type: VERIFY_ACCOUNT_ERROR,
      payload: err
    });
  }
};

export const forgotPassword = values => async dispatch => {
  try {
    dispatch({ type: FORGOT_PASSWORD_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/forgot-password`, {
      email: values.email
    });
    return dispatch({ type: FORGOT_PASSWORD, payload: response });
  } catch (err) {
    return dispatch({
      type: FORGOT_PASSWORD_ERROR,
      payload: err
    });
  }
};

export const verifyResetPasswordToken = (email, values) => async dispatch => {
  try {
    dispatch({ type: CHECK_RESET_PASSWORD_CODE_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/reset-password/verify`, {
      email: email,
      resetToken: values
    });
    return dispatch({ type: CHECK_RESET_PASSWORD_CODE, payload: response });
  } catch (err) {
    return dispatch({
      type: CHECK_RESET_PASSWORD_CODE_ERROR,
      payload: err
    });
  }
};

export const createInviteAccount = (values, groupId) => async dispatch => {
  try {
    dispatch({ type: CREATE_ACCOUNT_INV_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/register/invite`, {
      name: values.name,
      email: values.email,
      password: values.password,
      groupId: groupId
    });
    return dispatch({ type: CREATE_ACCOUNT_INV, payload: response });
  } catch (err) {
    return dispatch({
      type: CREATE_ACCOUNT_INV_ERROR,
      payload: err
    });
  }
};

export const getProfile = token => async dispatch => {
  try {
    dispatch({ type: PROFILE_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/profile`, {
      token
    });

    const { data } = response.data;

    return dispatch({ type: PROFILE, data });
  } catch (err) {
    return dispatch({
      type: PROFILE_ERROR,
      payload: err
    });
  }
};

export const onboardGroup = (group, values) => async dispatch => {

  try {
    dispatch({ type: UPDATE_GROUP_IS_LOADING });

    const response = await axios.put(
      `${API_URL}/group/${group._id}`,
      { ...group, ...values },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );

    return dispatch({ type: UPDATE_GROUP, payload: response });
  } catch (err) {
    return dispatch({
      type: UPDATE_GROUP_ERROR,
      payload: err
    });
  }
};

export const getGroup = id => async dispatch => {
  try {
    dispatch({ type: GET_GROUP_IS_LOADING });
    const response = await axios.get(`${API_URL}/group/${id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    return dispatch({ type: GET_GROUP, response });
  } catch (err) {
    return dispatch({
      type: GET_GROUP_ERROR,
      payload: err
    });
  }
};

export const updateGroup = group => async dispatch => {
  try {
    dispatch({ type: UPDATE_GROUP_IS_LOADING });
    const response = await axios.put(`${API_URL}/group/${group._id}`, group, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });
    triggerToastSuccess(response.data.message);
    return dispatch({ type: UPDATE_GROUP, payload: response });
  } catch (err) {
    return dispatch({
      type: UPDATE_GROUP_ERROR,
      payload: err
    });
  }
};

export const uploadGroupIcon = (id, file, group) => async dispatch => {
  var bodyFormData = new FormData();
  bodyFormData.append(`file`, file[0], file[0].name);
  try {
    dispatch({ type: UPDATE_GROUP_IS_LOADING });
    const aws_response = await axios.post(`${API_URL}/aws/folder/group/groups/${id}`, bodyFormData);
    const response = await axios.put(
      `${API_URL}/group/${group._id}`,
      { ...group, icon: aws_response.data.data },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );
    triggerToastSuccess("Sucessfully uploaded icon");
    return dispatch({ type: UPDATE_GROUP, payload: response });
  } catch (err) {
    toast.error("Something went wrong, connect support");
    return dispatch({
      type: UPDATE_GROUP_ERROR,
      payload: err
    });
  }
};

export const logoutUser = () => async dispatch => {
  try {
    dispatch({ type: LOGOUT_USER_IS_LOADING });
    Cookies.remove("otellu-studio-jwt-token");
    return dispatch({ type: LOGOUT_USER });
  } catch (err) {
    return dispatch({
      type: LOGOUT_USER_ERROR,
      payload: err
    });
  }
};

export const checkMemberInviteCode = (groupId, invitationToken) => async dispatch => {
  try {
    dispatch({ type: CHECK_MEMBER_INV_CODE_IS_LOADING });
    const response = await axios.post(`${API_URL}/group/${groupId}/invite-check`, {
      invitationToken
    });
    return dispatch({ type: CHECK_MEMBER_INV_CODE, payload: response });
  } catch (err) {
    return dispatch({
      type: CHECK_MEMBER_INV_CODE_ERROR,
      payload: err
    });
  }
};

export const inviteMember = (groupId, email) => async dispatch => {
  try {
    dispatch({ type: INVITE_MEMBER_IS_LOADING });
    const response = await axios.post(
      `${API_URL}/group/${groupId}/invite`,
      { email: email },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );
    triggerToastSuccess(response.data.message);

    return dispatch({ type: INVITE_MEMBER, payload: response });
  } catch (err) {
    return dispatch({
      type: INVITE_MEMBER_ERROR,
      payload: err
    });
  }
};

export const resetPassword = (email, values) => async dispatch => {
  try {
    dispatch({ type: RESET_PASSWORD_IS_LOADING });
    const response = await axios.post(`${API_URL}/auth/reset-password`, {
      email: email,
      password: values.password,
      confirmPassword: values.password_repeat
    });
    return dispatch({ type: RESET_PASSWORD, payload: response });
  } catch (err) {
    return dispatch({
      type: RESET_PASSWORD_ERROR,
      payload: err
    });
  }
};

export const addPackage = (group, packageInfo) => async dispatch => {
  try {
    dispatch({ type: ADD_PACKAGE_IS_LOADING });
    const response = await axios.post(
      `${API_URL}/groups/${group._id}/packages`,
      {
        vacancySlotAmount: packageInfo.vacancySlotAmount,
        pricePerSlot: packageInfo.pricePerSlot,
        vacancySlotExpiryTime: packageInfo.vacancySlotExpiryTime,
        stripeCustomerId: group.stripeCustomerId
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );
    return dispatch({ type: ADD_PACKAGE, response });
  } catch (err) {
    return dispatch({
      type: ADD_PACKAGE_ERROR,
      payload: err
    });
  }
};
