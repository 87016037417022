import axios from "axios";

import { API_URL } from "../constants/url";
import { INVOICES, INVOICES_IS_LOADING, INVOICES_ERROR } from "./types/billing";

export const getInvoices = stripeCustomerId => async dispatch => {
  try {
    dispatch({ type: INVOICES_IS_LOADING });
    const response = await axios.get(`${API_URL}/stripe/invoices/${stripeCustomerId}`);
    return dispatch({ type: INVOICES, response });
  } catch (err) {
    return dispatch({
      type: INVOICES_ERROR,
      payload: err
    });
  }
};
