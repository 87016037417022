import React from "react";

import { AWS_URL } from "../../../constants/url";
import { CloseIcon } from "../../Atoms";

const TeamCard = props => {
  const { hasDelete, onDeleteMember, member, onClick } = props;
  const { name, function: role, linkedin, photo } = member;

  return (
    <div
      data-cy="team-card"
      className="relative bg-gray-100 shadow-sm rounded-sm w-full cursor-pointer"
    >
      {hasDelete && <CloseIcon onClick={onDeleteMember} />}

      <div
        className="flex flex-col mx-auto pt-12 pb-4 space-y-4 p-3r"
        onClick={() => onClick(member)}
      >
        <img
          className="mx-auto h-20 w-20 object-fit rounded-full xl:w-24 xl:h-24"
          src={`${AWS_URL}/${photo || "placeholders/team_placeholder.jpg"}`}
        />
        <div className="w-full">
          <h2 className="mb-0 text-lg text-center text-gray-600 leading-6 font-medium">
            {name}
          </h2>
          <p className="mb-0 text-sm text-center text-indigo-500 leading-none font-normal">
            {role}
          </p>
        </div>
        <div className="mx-auto">
          {linkedin && (
            <a href={linkedin}>
              <i className="material-icons w-4 text-gray-700">linkedin</i>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
