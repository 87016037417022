import axios from 'axios'

import { API_URL } from '../../constants/url';
import { multiPart } from '../../helpers/Bearer'
import { triggerToastError, triggerToastSuccess } from "../../helpers/handleResponse"

import { ASSET_CREATE_ONE_IS_LOADING, ASSET_CREATE_ONE_DATA, ASSET_CREATE_ONE_ERROR } from './types'

const createAsset = (groupId, fileList) => async dispatch => {
  const header = multiPart()

  try {
    dispatch({ type: ASSET_CREATE_ONE_IS_LOADING, action: 'create', amount: fileList.length })

    // API call
    const formData = new FormData()
    for (let i = 0; i < fileList.length; i += 1) {
      formData.append('files', fileList[i])
    }

    const { data: { data } } = await axios.post(
      `${API_URL}/groups/${groupId}/media/photos`,
      formData,
      header
    )

    const { created, errors } = data
    if (errors.length) {
      errors.forEach(err => {
        const message = err.childError.message || err.message
        triggerToastError(message, false)
      })
    } else {
      triggerToastSuccess(`You succesfully created <b>${created.length}</b> file${created.length > 1 ? 's' : ''}`)
    }

    const alreadyExistErrors = errors.filter(err => err.childError && err.childError.Key).map(err => err.childError.Key)

    return dispatch({ type: ASSET_CREATE_ONE_DATA, file: created, alreadyExist: alreadyExistErrors })
  } catch (err) {
    return dispatch({ type: ASSET_CREATE_ONE_ERROR,  err })
  }
}

export default createAsset