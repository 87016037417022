import {
  APPLICATIONS_BY_GROUP_IS_LOADING,
  APPLICATIONS_BY_GROUP,
  APPLICATIONS_BY_GROUP_ERROR,
  APPLICATIONS_BY_VACANCY_IS_LOADING,
  APPLICATIONS_BY_VACANCY,
  APPLICATIONS_BY_VACANCY_ERROR
} from "../actions/types/applicants";

const initialState = {
  applications: [],
  isLoading: false,
  deviations: [],
  single_applicant: [],
  loadingState: {
    fetch: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APPLICATIONS_BY_GROUP_IS_LOADING: {
      return { ...state, err: false, isLoading: false, loadingState: { fetch: true } };
    }
    case APPLICATIONS_BY_GROUP: {
      const { data } = action.response.data;
      return { ...state, applications: data, err: false, isLoading: false, loadingState: { fetch: true } };
    }

    case APPLICATIONS_BY_GROUP_ERROR: {
      return { ...state, err: true, isLoading: false, loadingState: { fetch: true } };
    }

    case APPLICATIONS_BY_VACANCY_IS_LOADING: {
      return { ...state, err: false, isLoading: false, loadingState: { fetch: true } };
    }
    case APPLICATIONS_BY_VACANCY: {
      const { data } = action.response.data;
      return { ...state, applications: data, err: false, isLoading: false, loadingState: { fetch: true } };
    }

    case APPLICATIONS_BY_VACANCY_ERROR: {
      return { ...state, err: true, isLoading: false, loadingState: { fetch: true } };
    }

    default:
      return state;
  }
};
