import React from "react";

const Tab = props => {
  const activeStyle = "border-purple-500 text-purple-600 border-b-2";
  const defaultStyle = "text-gray-400";
  return (
    <span onClick={props.onClick} className={`mb-0 whitespace-nowrap py-2 px-1 font-medium text-base cursor-pointer ${props.activeTabIdx === props.tabIdx ? activeStyle : defaultStyle}`}>
      {props.name}
    </span>
  );
};

export default Tab;
