import React, { useContext, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";

import { MediaLibraryContext } from "../../../context";
import { Modal } from "../Modal";
import { MediaLibrary } from ".";
import { Button } from "../../Atoms";

const MediaLibraryEmbedded = () => {
  const { title, open, setOpen, multiSelect, exclude, callback } = useContext(
    MediaLibraryContext
  );
  const [selected, setSelected] = useState([]);

  const isDisabled = () => !selected.length;

  return (
    <Modal isOpen={open} setOpen={setOpen} className="media-library">
      <div className="flex flex-column overflow-hidden flex-grow">
        {/* Header */}
        {title && (
          <div className="p-4 bg-gray-200 flex justify-between">
            <h1 className="text-lg leading-none m-0 font-normal">{title}</h1>
            <XIcon
              className="h-6 w-6 text-gray-500 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>
        )}
        {/* Library */}
        <div className="p-4 flex-grow overflow-y-auto">
          {open && (
            <MediaLibrary
              isEmbedded={true}
              isEmbeddedOpen={open}
              multiSelect={multiSelect}
              onSelectedChange={setSelected}
              exclude={exclude}
            />
          )}
        </div>
        {/* Select button */}
        <div className="p-3 bg-gray-200 flex self-bottom justify-end">
          <div>
            <Button
              data_cy="button-select-media-items"
              theme="purple"
              text="Select"
              onClick={() =>
                typeof callback === "function" && callback(selected)
              }
              disabled={isDisabled()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MediaLibraryEmbedded;
