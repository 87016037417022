import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector  } from "react-redux";

import { Button } from '.'
import { createAsset } from '../../actions'
import { ASSET_CREATE_ONE_DATA } from '../../actions/Assets/types'

const StyledFileInput = styled.input`
  font-size: 0;
  cursor: pointer;

  &::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
  }
`

const InputFile = ({
  label,
  name,
  onChange,
  required,
}) => {
  const dispatch = useDispatch()
  const { group } = useSelector(state => state.auth)

  const onUploadDesktop = (e) => {
    const { files } = e.target

    dispatch(createAsset(group._id, files)).then(({ type, file }) => {
      if (type === ASSET_CREATE_ONE_DATA) {
        if (file.length) {
          onChange({ target: { name, value: file[0].Key }})
        }
      }
    })
  }

  return (
    <div>
      {label ? (
        <label for={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}

      <div className="mt-1 flex items-center">
        <Button type="button" theme="white" text="Choose from library" />
        <a href="#" className="relative">
          Upload from desktop
          <StyledFileInput
            type="file"
            id={name}
            name={name}
            required={required}
            className="w-100 h-100 left-0 top-0 absolute opacity-0 cursor-pointer"
            multiple={false}
            onChange={onUploadDesktop}
          />
        </a>
      </div>
    </div>
  )
}

const Input = (props) => {
  const { label, type, value, name, placeholder, onChange, required, ...rest } = props

  if (type === 'file') {
    return <InputFile {...props} />
  }

  return (
    <div>
      {label ? (
        <label for={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}

      <div className="mt-1">
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          autoComplete="email"
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          {...rest}
        />
      </div>
    </div>
  ) 
}

export default Input;
