import React, { useState } from "react";
import axios from "axios";
import { Button } from "../../Atoms";
import { API_URL } from "../../../constants/url";

const Softskills = props => {
  const { selectedApplicant } = props;
  const [softskillResultsState, setSoftskillsResultsState] = useState(false);
  const [softskillResults, setSoftskillResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data } = props;
  const handleTypeformRequest = async () => {
    setLoading(true);
    try {
      const result = await axios.get(`${API_URL}/typeform/${data.typeFormId}/${data.applicantTypeformToken}`);
      setSoftskillResults(result.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
    setLoading(true);
    setLoading(false);
  };
  return (
    <>
      <div className="pb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Softskills</h3>
        <p className="mb-0 mt-1 max-w-2xl text-sm text-gray-500">See the results of the soft skills test.</p>
      </div>
      <div className="border-t border-gray-200 sm:p-0">
        <dl className="mb-0 sm:divide-y sm:divide-gray-200">
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-2">
            <dt className="pb-0 text-sm font-medium text-gray-500">Total score</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.SSScoreTotal}%</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-2">
            <dt className="pb-0 text-sm font-medium text-gray-500">{data.softSkillOne}</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.SSScoreOne}%</dd>
          </div>
          <div className="sm:grid py-3 sm:grid-cols-3 sm:gap-4 sm:px-2">
            <dt className="pb-0 text-sm font-medium text-gray-500">{data.softSkillTwo}</dt>
            <dd className="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.SSScoreTwo}%</dd>
          </div>
        </dl>
      </div>
      {softskillResults.length === 0 && (
        <div className="flex justify-start w-full mt-8">
          <div className="w-48">
            <Button text="Load results" loading={loading} theme="purple" onClick={() => handleTypeformRequest()} />
          </div>
        </div>
      )}

      {softskillResults.length !== 0 && (
        <div className="overflow-y-scroll mt-12">
          {softskillResults.map(softskill => (
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:border-t sm:border-gray-200 sm:py-5">
              <dt className="text-lg leading-5 font-medium text-gray-900">{softskill.title}</dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md">
                  {softskill.properties.choices.map((answer, index) => (
                    <li className={`${index !== 0 ? "border-t border-gray-2000" : null} pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5`}>
                      <div className="w-0 flex-1 flex flex-col">
                        <span className="text-xs text-gray-500">{answer.ref}</span>
                        <p className="text-base w-full mb-0 flex-1 w-0 truncate text-gray-700">{answer.label}</p>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        {answer.id === softskill.choice.id ? (
                          <svg className="h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                          </svg>
                        ) : null}
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Softskills;
