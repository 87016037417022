import { ADD_VACANCIE_IS_LOADING, ADD_VACANCIE_DATA, ADD_VACANCIES_ERROR } from "../../actions/Vacancies/AddVacancyAction";

import { DELETE_VACANCY_IS_LOADING, DELETE_VACANCY_DATA, DELETE_VACANCY_ERROR } from "../../actions/Vacancies/DeleteSingleVacancyAction";

import { VACANCIES_BY_ACCOUNT_IS_LOADING, VACANCIES_BY_ACCOUNT_DATA, VACANCIES_BY_ACCOUNT_ERROR } from "../../actions/Vacancies/GetVacanciesByAccountAction";

import {
  VACANCY,
  VACANCY_IS_LOADING,
  VACANCY_ERROR,
  ALL_VACANCY,
  ALL_VACANCY_IS_LOADING,
  ALL_VACANCY_ERROR,
  UPDATE_VACANCY_IS_LOADING,
  UPDATE_VACANCY,
  UPDATE_VACANCY_ERROR,
  ACTIVATE_VACANCY_IS_LOADING,
  ACTIVATE_VACANCY,
  ACTIVATE_VACANCY_ERROR,
  DRAFT_VACANCY_IS_LOADING,
  DRAFT_VACANCY,
  DRAFT_VACANCY_ERROR
} from "../../actions/types/vacancy";

const initialState = {
  vacancies_by_account: [],
  vacancies_by_group: [],
  single_vacancy: [],
  isLoading: false,
  err: false,
  message: "",
  loadingState: {
    fetching: false,
    publish: false,
    update: false,
    done: false
  },
  errorState: {
    publish: false,
    update: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_VACANCIE_IS_LOADING: {
      return { ...state, isLoading: true, err: action.error };
    }

    case ADD_VACANCIE_DATA: {
      const { vacancy, msg } = action.result.data;
      return { ...state, isLoading: false, vacancies_by_group: state.vacancies_by_group.concat(vacancy), message: msg, err: false };
    }

    case ADD_VACANCIES_ERROR: {
      const { msg } = action.payload.response.data;
      return { ...state, err: true, isLoading: false, message: msg };
    }

    case ALL_VACANCY_IS_LOADING: {
      return { ...state, isLoading: true, loadingState: { fetching: true, update: false, publish: false } };
    }

    case ALL_VACANCY: {
      const { data } = action.payload.data;
      return { ...state, vacancies_by_group: data, isLoading: false, loadingState: { fetching: false, update: false, publish: false } };
    }

    case ALL_VACANCY_ERROR: {
      return { ...state, error: action.err, isLoading: false, loadingState: { fetching: false, update: false, publish: false } };
    }

    case VACANCIES_BY_ACCOUNT_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case VACANCIES_BY_ACCOUNT_DATA: {
      const { vacancies } = action.result.data;
      return { ...state, vacancies_by_account: vacancies, isLoading: false };
    }

    case VACANCIES_BY_ACCOUNT_ERROR: {
      return { ...state, error: action.err, isLoading: false };
    }

    case DELETE_VACANCY_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case DELETE_VACANCY_DATA: {
      return {
        ...state,
        ...state.vacancies_by_group.filter((data, i) => data._id !== action.id),
        isLoading: false,
        err: false,
        message: "succesfully deleted vacancy"
      };
    }

    case DELETE_VACANCY_ERROR: {
      return { ...state, error: true, isLoading: false, message: "Something went wrong , try again!" };
    }

    case VACANCY_IS_LOADING: {
      return { ...state, isLoading: true, loadingState: { fetching: true, update: false, publish: false } };
    }

    case VACANCY: {
      const { data } = action.payload.data;
      return { ...state, isLoading: false, single_vacancy: data, loadingState: { fetching: false, update: false, publish: false } };
    }

    case VACANCY_ERROR: {
      return { ...state, error: action.err, loadingState: { fetching: false } };
    }

    case UPDATE_VACANCY_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loaded: false,
        loadingState: { fetching: false, update: true, publish: false, done: false },
        errorState: {
          publish: false,
          update: false
        }
      };
    }

    case UPDATE_VACANCY: {
      const { data } = action.payload.data;
      return {
        ...state,
        single_vacancy: data._doc,
        err: false,
        loaded: true,
        loadingState: { fetching: false, update: false, publish: false, done: true },
        errorState: {
          publish: false,
          update: false
        }
      };
    }

    case UPDATE_VACANCY_ERROR: {
      return {
        ...state,
        isLoading: false,
        err: true,
        message: "Something went wrong , try again!",
        loaded: true,
        loadingState: { fetching: false, update: false, publish: false, done: false },
        errorState: {
          publish: false,
          update: true
        }
      };
    }

    case ACTIVATE_VACANCY_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingState: { fetching: false, update: false, publish: true, done: false },
        publishDone: false,
        errorState: {
          publish: false,
          update: false
        }
      };
    }

    case ACTIVATE_VACANCY: {
      const { data } = action.response;
      return {
        ...state,
        single_vacancy: data,
        isLoading: false,
        err: false,
        publishDone: true,
        loadingState: { fetching: false, update: false, publish: false, done: true },
        errorState: {
          publish: false,
          update: false
        }
      };
    }

    case ACTIVATE_VACANCY_ERROR: {
      return {
        ...state,
        isLoading: false,
        err: true,
        message: "Something went wrong , try again!",
        publishDone: false,
        loadingState: { fetching: false, update: false, publish: false, done: true },
        errorState: {
          publish: true,
          update: false
        }
      };
    }

    case DRAFT_VACANCY_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case DRAFT_VACANCY: {
      return { ...state, isLoading: false, err: false };
    }

    case DRAFT_VACANCY_ERROR: {
      return { ...state, isLoading: false, err: true, message: "Something went wrong , try again!" };
    }

    default:
      return state;
  }
};
