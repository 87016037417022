// READ
export const ASSET_GET_MANY_IS_LOADING = 'asset_get_many_is_loading'
export const ASSET_GET_MANY_DATA = 'asset_get_many_data'
export const ASSET_GET_MANY_ERROR = 'asset_get_many_error'

// CREATE
export const ASSET_CREATE_ONE_IS_LOADING = 'asset_create_is_loading'
export const ASSET_CREATE_ONE_DATA = 'asset_create_data'
export const ASSET_CREATE_ONE_ERROR = 'asset_create_error'

// UPDATE
export const ASSET_UPDATE_IS_LOADING = 'asset_get_update_loading'
export const ASSET_UPDATE_DATA = 'asset_update_data'
export const ASSET_UPDATE_ERROR = 'asset_update_error'

// DELETE
export const ASSET_DELETE_ONE_IS_LOADING = 'asset_delete_is_loading'
export const ASSET_DELETE_ONE_DATA = 'asset_delete_data'
export const ASSET_DELETE_ONE_ERROR = 'asset_delete_error'

// LOOKUP
export const ASSET_LOOKUP_IS_LOADING = 'asset_lookup_is_loading'
export const ASSET_LOOKUP_DATA = 'asset_lookup_data'
export const ASSET_LOOKUP_ERROR = 'asset_lookup_error' 