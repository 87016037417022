import React from "react";
import { Field } from "formik";
import softskillsData from "../../../data/softskills";

const SoftskillsForm = props => {
  const { values, addonNmbr, job_post } = props;
  return (
    <>
      <div className="mt-6 grid grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-2">
          <label for="softSkillOne" className="block text-sm font-medium text-gray-700">
            Softskill one
          </label>
          <Field
            as="select"
            id="softSkillOne"
            name={`addonData[${addonNmbr}].data.softSkillOne`}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
            disabled={job_post.active}
          >
            <option value="" selected disabled hidden>
              Select your first softskills
            </option>
            {softskillsData.map(x => (
              <option value={x.value}>{x.title}</option>
            ))}
          </Field>
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label for="softSkillTwo" className="block text-sm font-medium text-gray-700">
            Softskill two
          </label>
          <Field
            as="select"
            id="softSkillTwo"
            name={`addonData[${addonNmbr}].data.softSkillTwo`}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            required
            disabled={job_post.active}
          >
            <option value="" selected disabled hidden>
              Select your second softskills
            </option>
            {softskillsData.map(x => (
              <option value={x.value}>{x.title}</option>
            ))}
          </Field>
        </div>
      </div>
    </>
  );
};

export default SoftskillsForm;
