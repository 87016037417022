import React from "react";
import Slider from "react-rangeslider";

import "react-rangeslider/lib/index.css";

const RangeSlider = props => {
  return (
    <Slider
      min={props.min}
      max={props.max}
      value={props.value}
      labels={props.labels}
      onChangeStart={props.handleChangeStart}
      onChange={props.handleChangeSlider}
      onChangeComplete={props.handleChangeComplete}
    />
  );
};

export default RangeSlider;
