import React from "react";

const ModalHeader = props => {
  return (
    <div data-cy={props.data_cy} className="text-center">
      <h2 className="text-4xl font-bold text-gray-900">{props.title}</h2>
      <div className="max-w-xl mx-auto">
        <p className="text-gray-700">{props.description}</p>
      </div>
    </div>
  );
};

export default ModalHeader;
