import React, { useState, useContext } from 'react'
import { PhotographIcon } from '@heroicons/react/solid'
import { useDispatch, useSelector } from 'react-redux'

import { MediaLibrary, MediaInfo } from '../components/Molecules/MediaLibrary'
import { InfoPanel, InfoPanelEmpty } from '../components/Molecules/InfoPanel'
import { Button } from '../components/Atoms'

import { ConfirmActionContext } from '../context'
import { createAsset, updateAsset, deleteAsset, lookupAsset } from '../actions'
import { ASSET_LOOKUP_DATA, ASSET_DELETE_ONE_DATA, ASSET_UPDATE_DATA, ASSET_CREATE_ONE_DATA } from '../actions/Assets/types'
import { ScrollableLayout } from '../layouts'
import { triggerToastError, triggerToastSuccess } from "../helpers/handleResponse";

const EmptyInfo = ({ handleCreate }) => {
  const { isLoading: { value: isLoading, action: loadingAction } } = useSelector(state => state.Assets)

  const onFileUploadedLocally = (files) => {
    handleCreate(files)
  }

  return (
    <InfoPanelEmpty
      title="Add an asset"
      description="Creating an asset allows you to add them to every job post"
      icon={<PhotographIcon />}
    >
      <Button
        data_cy="button-upload-asset"
        data_cy_file="button-upload-asset-input"
        theme="purple"
        text="Add asset"
        loading={isLoading && loadingAction === 'create'}
        disabled={isLoading && loadingAction !== 'getMany'}
        type="file"
        multipleFile={true}
        onFilesUploaded={onFileUploadedLocally}
      />
    </InfoPanelEmpty>
  )
}

const Library = () => {
  const dispatch = useDispatch()

  const { isLoading: { value: isLoading, action: loadingAction } } = useSelector(state => state.Assets)
  const { group } = useSelector(state => state.auth)

  const { setContent, setOpen: setConfirmActionOpen, setCallback } = useContext(ConfirmActionContext)

  const [fileDetailed, setFileDetailed] = useState(null)

  const handleConfirmUpdate = (file) => {
    dispatch(lookupAsset(group._id, fileDetailed)).then(({ type, fileLookup }) => {
      const { total, used } = fileLookup
    
      if (type === ASSET_LOOKUP_DATA) {
        if (total) {
          setContent({
            title: 'This file is used in other data',
            description: 'If you update this asset, it will be updated on other data too.',
            data: { file, fileDetailed },
            list: used
          })
          setCallback(updateFile)
          setConfirmActionOpen(true)
        } else {
          updateFile({ file, fileDetailed })
        }
      }
    })
  }

  const handleConfirmDelete = (file) => {
    dispatch(lookupAsset(group._id, file)).then(({ type, fileLookup }) => {  
      if (type === ASSET_LOOKUP_DATA) {
        const { total, errors, used } = fileLookup

        if (errors.length) {
          // Toast error
          triggerToastError(
            `The asset could not be removed automatically. Please remove the asset from <a href="/job/edit/${errors[0].vacancy._id}" target="_blank">${errors[0].vacancy.title}</a> first.`,
            false
          )
        } else if (total) {
          setContent({
            title: 'This file is used in other data',
            description: 'If you’re deleting this asset, it will be removed from other data too:',
            data: file,
            list: used
          })
          setCallback(deleteFile)
          setConfirmActionOpen(true)
        } else {
          deleteFile(file)
        }
      }
    })
  }

  const createFile = (files) => {
    dispatch(createAsset(group._id, files)).then(({ type, file }) => {
      if (type === ASSET_CREATE_ONE_DATA) setFileDetailed(file[0])
    })
  }

  const deleteFile = (file) => { 
    setConfirmActionOpen(false)
  
    dispatch(deleteAsset(group._id, file)).then(({ type }) => {
      if (type === ASSET_DELETE_ONE_DATA) setFileDetailed(null)
    })
  }

  const updateFile = ({ file, fileDetailed }) => {
    setConfirmActionOpen(false)
  
    dispatch(updateAsset(group._id, file, fileDetailed)).then(({ type, file }) => {
      if (type === ASSET_UPDATE_DATA) setFileDetailed(file)
    })
  }

  const contentLeft = (
    <>
      <MediaLibrary
        setFileDetailed={setFileDetailed}
        fileDetailed={fileDetailed}
      />
    </>
  )

  const contentRight = (
    <InfoPanel
      title="View asset"
      onClose={() => setFileDetailed(null)}
      isEmpty={!fileDetailed}
      emptyComponent={<EmptyInfo handleCreate={createFile} />}
    >
      <MediaInfo
        file={fileDetailed}
        onUpdate={handleConfirmUpdate}
        onDelete={handleConfirmDelete}
        
      />
    </InfoPanel>
  )

  const ctaButton = (
    <Button
      theme="purple"
      text="Add asset"
      loading={isLoading && loadingAction === 'create'}
      disabled={isLoading && loadingAction !== 'getMany'}
      type="file"
      multipleFile={true}
      onFilesUploaded={createFile}
    />
  )

  return (
    <>
      <ScrollableLayout
        title="Media"
        cta={ctaButton}
        contentLeft={contentLeft}
        contentRight={contentRight}
      />
    </>
  )
}

export default Library