import React, { useState } from 'react'

import { StyledMediaItem, StyledMediaItemThumbnail } from './styled'

const MediaItemSkeleton = ({ loading }) => {
  return (
    <StyledMediaItem className="animate-pulse">
      <StyledMediaItemThumbnail
        className="relative bg-gray-200 bg-cover bg-center bg-no-repeat mb-2"
        $inActive={true}
        $loading={loading}
      >
        {loading && (
          <div className="loader">
            <svg className={`w-full animate-spin h-5 w-5 text-black`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </StyledMediaItemThumbnail>
      <div className="bg-gray-300 h-3" style={{ width: `95%`}}></div>
    </StyledMediaItem>
  )
}

export default MediaItemSkeleton