import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import axios from "axios";
import { Element } from "react-scroll";
import { updateGroup, updateProfile, inviteMember, uploadGroupIcon, uploadProfileIcon } from "../actions/Auth";
import { getInvoices } from "../actions/billing";
import { getMembers } from "../actions/member";

import { UserIcon } from "@heroicons/react/outline";
import { UsersIcon, SuitcaseIcon, CreditcardIcon, ToggleIcon } from "../components/Atoms/Icon";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Sidebar, SidebarItem } from "../components/Molecules/Navigation";
import { ProfileForm, CompanyForm } from "../components/Molecules/Settings";

import { API_URL } from "../constants/url";

import { Button } from "../components/Atoms";
import { SlideOver } from "../components/Atoms/Slider";
import { ModalContainer, ModalHeader } from "../components/Atoms/Modal";
import { InvoiceModal } from "../components/Molecules/Modal";
import { Badge } from "../components/Atoms/UI";

import { isAdmin } from "../helpers/settings";
import { triggerToastError } from "../helpers/handleResponse";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      group: [],
      members: [],
      selectedInvoice: [],
      invoiceModal: false
    };
  }

  componentDidUpdate(prevProps) {
    //Handling erros
    if (prevProps.message !== this.props.message) {
      if (!this.props.auth_loading && this.props.isError) {
        triggerToastError(this.props.message);
      }
    }
  }
  componentDidMount = async () => {
    const { group, getMembers, getInvoices } = this.props;
    await getMembers(group._id);
    await getInvoices(group.stripeCustomerId);
  };

  handleProfileIcon = async file => {
    const { profile, uploadProfileIcon } = this.props;
    try {
      await uploadProfileIcon(profile._id, file, profile);
    } catch (error) {
      return error;
    }
  };

  handleCompanyIconSubmit = async file => {
    const { group, uploadGroupIcon } = this.props;
    try {
      await uploadGroupIcon(group._id, file, group);
    } catch (error) {
      return error;
    }
  };

  handleRoles = async (member, switchState) => {
    const { updateGroup } = this.props;
    const { group } = this.props;

    //switch from admin to member
    if (switchState) {
      let newStateGroup = group;
      //remove id from admins
      newStateGroup.admins.splice(newStateGroup.admins.indexOf(member._id), 1);

      this.setState({
        group: newStateGroup
      });
    }

    //switch from member to admin
    if (!switchState) {
      let newStateGroup = group;
      //push id to admins
      group.admins.push(member._id);
      this.setState({
        group: newStateGroup
      });
    }

    await updateGroup(group);
  };

  render() {
    const { profile, group, members, updateProfile, updateGroup, inviteMember, auth_loading, invoices, loadingState } = this.props;
    const { invoiceModal, selectedInvoice } = this.state;
    return (
      <main className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8 relative">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <Sidebar>
            <SidebarItem link="profile" name="Profile" icon={<UserIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />} />
            <SidebarItem link="company" name="Company account" icon={<SuitcaseIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />} />
            <SidebarItem link="members" name="Members" icon={<UsersIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />} />
            <SidebarItem link="billing" name="Billing" icon={<CreditcardIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />} />
          </Sidebar>

          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <Element name="profile" aria-labelledby="payment_details_heading">
              <Formik
                initialValues={profile}
                onSubmit={async values => {
                  await new Promise(r => setTimeout(r, 500));

                  await updateProfile(values);
                }}
                render={({ handleChange, values, errors, touched }) => {
                  return (
                    <Form>
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 sm:p-6">
                          <div>
                            <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
                              Profile
                            </h2>
                            <p className="mt-1 text-sm text-gray-500">Update and view your personal profile.</p>
                          </div>
                          <ProfileForm
                            handleProfileChange={file => this.handleProfileIcon(file)}
                            profile={profile}
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                          />
                        </div>
                        <div className="flex justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <div className="w-24">
                            <Button loading={loadingState.profile} disabled={!isAdmin(profile._id, group.admins)} type="submit" text="Save" theme="black" />
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              ></Formik>
            </Element>

            <Element name="company" aria-labelledby="payment_details_heading">
              <Formik
                initialValues={group}
                onSubmit={async values => {
                  await new Promise(r => setTimeout(r, 500));
                  await updateGroup(values);
                }}
                render={({ values, errors, touched, handleChange }) => {
                  return (
                    <Form>
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 sm:p-6">
                          <div>
                            <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
                              Company account
                            </h2>
                            <p className="mt-1 text-sm text-gray-500">Update and view your company account. Please note that only admins can make changes.</p>
                          </div>
                          <CompanyForm
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            group={group}
                          />
                        </div>
                        <div className="flex justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <div className="w-24">
                            <Button loading={loadingState.group} disabled={!isAdmin(profile._id, group.admins)} type="submit" text="Save" theme="black" />
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              ></Formik>
            </Element>

            <Element name="members" aria-labelledby="payment_details_heading">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
                      Members
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">Add team members and make changes in member roles.</p>
                  </div>
                  <div className="">
                    <ul className="divide-y divide-gray-200">
                      {members.map(member => {
                        return (
                          <li className="py-3 flex items-center">
                            <div className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-200">
                              <UserIcon className="h-6 w-6 text-gray-500" />
                            </div>

                            <div className="ml-3 flex flex-col">
                              <span className="text-sm font-medium text-gray-900">{member.name}</span>
                              <span className="text-sm text-gray-500">{isAdmin(member._id, group.admins) ? "Admin" : "Member"}</span>
                            </div>
                            <div className="ml-auto">
                              <button
                                onClick={() => this.handleRoles(member, isAdmin(member._id, group.admins))}
                                type="button"
                                disabled={!isAdmin(profile._id, group.admins)}
                                aria-pressed="false"
                                className={`${
                                  isAdmin(member._id, group.admins) ? "bg-green-400" : "bg-gray-200"
                                } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 ${
                                  isAdmin(profile._id, group.admins) ? "cursor-pointer" : "cursor-not-allowed"
                                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`pointer-events-none ${
                                    isAdmin(profile._id, group.admins) ? "translate-x-5" : "translate-x-0"
                                  } inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                                ></span>
                              </button>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="space-y-1 mt-4">
                    <label for="add_team_members" className="block text-sm font-medium text-gray-700">
                      Add Team Member
                    </label>
                    <p id="add_team_members_helper" className="sr-only">
                      Search by email address
                    </p>
                    <Formik
                      initialValues={{
                        email: ""
                      }}
                      onSubmit={async values => {
                        await new Promise(r => setTimeout(r, 500));
                        await inviteMember(group._id, values.email);
                      }}
                    >
                      <Form>
                        <div className="flex">
                          <div className="flex-grow">
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Email address"
                              aria-describedby="email"
                            />
                          </div>
                          <div className="ml-3">
                            <button
                              type="submit"
                              className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                            >
                              {loadingState.member ? (
                                <svg className="animate-spin h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                <>
                                  <svg className="-ml-2 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                                  </svg>
                                  <span>Add</span>
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="billing" aria-labelledby="billing_history_heading">
              <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 sm:px-6">
                  <h2 id="billing_history_heading" className="text-lg leading-6 font-medium text-gray-900">
                    Billing
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">View the status of your billing. Pay and download individual invoices at your command.</p>
                </div>
                <div className="mt-6 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden border-t border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Description
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount
                              </th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                              </th>
                              <th scope="col" className="relative px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {invoices.length === 0 && (
                              <tr>
                                <td className=""></td>
                                <td className=""></td>
                                <td className="mt-4 py-4">
                                  <p className="mb-0 text-lg text-gray-700">No invoices found yet...</p>
                                </td>
                                <td className=""></td>
                                <td className=""></td>
                              </tr>
                            )}
                            {invoices.length !== 0 &&
                              invoices.map(x => {
                                const newDescription = x.lines.data.find(x => x.description.startsWith("Vacancy"));
                                const oldDescription = x.lines.data.find(x => x.description.startsWith("Applicant"));

                                return (
                                  <tr>
                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">1/1/2020 </td> */}
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{x.metadata.toBeInvoicedAt}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-col">
                                      {newDescription && newDescription.description}
                                      {oldDescription && oldDescription.description}
                                      {/* {x.lines.data.length >= 1 && <p className="mb-0 text-xs text-indigo-400">{`+${x.lines.data.length} add-ons`}</p>} */}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">€{Number((x.total / 100).toFixed(2))}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      <Badge text={x.status} colorType={x.status === "paid" ? "success" : "info"} />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      {/* {x.status === "draft" && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">No actions...</td>} */}
                                      <p className="mb-0 text-xs cursor-pointer" onClick={() => this.setState({ invoiceModal: true, selectedInvoice: x })}>
                                        View more
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Element>
          </div>
        </div>

        <SlideOver onClose={() => this.setState({ invoiceModal: false })} title="Invoice" isOpen={invoiceModal} sliderWidth="max-w-lg">
          {selectedInvoice.length !== 0 && (
            <div class="bg-white overflow-hidden sm:rounded-lg">
              <div class="">
                <h3 class="mb-0 text-lg leading-6 font-medium text-gray-900">Basic information</h3>
              </div>
              <div class="border-b border-gray-200">
                <dl class="mt-2">
                  <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">Package</dt>
                    {selectedInvoice.lines.data.find(x => x.description.startsWith("Vacancy")) && (
                      <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedInvoice.lines.data.find(x => x.description.startsWith("Vacancy")).description}</dd>
                    )}
                    {selectedInvoice.lines.data.find(x => x.description.startsWith("Applicant")) && (
                      <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedInvoice.lines.data.find(x => x.description.startsWith("Applicant")).description}</dd>
                    )}
                  </div>
                  <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">Total price</dt>
                    <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">€{Number((selectedInvoice.total / 100).toFixed(2))}</dd>
                  </div>
                  <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">Status</dt>
                    <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Badge text={selectedInvoice.status} colorType={selectedInvoice.status === "paid" ? "success" : "info"} />
                    </dd>
                  </div>
                  <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">Billing date</dt>
                    <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedInvoice.metadata.toBeInvoicedAt}</dd>
                  </div>

                  {selectedInvoice.status !== "draft" && (
                    <>
                      <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-gray-500">Pay invoice</dt>
                        <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <a target="_blank" href={selectedInvoice.hosted_invoice_url}>
                            Payment invoice link
                          </a>
                        </dd>
                      </div>

                      <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-gray-500">Download invoice</dt>
                        <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <a target="_blank" href={selectedInvoice.invoice_pdf}>
                            Download invoice link
                          </a>
                        </dd>
                      </div>
                    </>
                  )}
                </dl>
              </div>
              <div class="mt-4">
                <h3 class="mb-0 text-lg leading-6 font-medium text-gray-900">Add-ons</h3>
                <p class="mb-0 max-w-2xl text-sm text-gray-500">See here purchased add-ons within this package.</p>
              </div>
              <div class="border-b border-gray-200">
                {selectedInvoice.lines.data.find(x => x.description.startsWith("Vacancy")) && (
                  <dl class="mt-2">
                    {selectedInvoice &&
                      selectedInvoice.lines.data.length !== 0 &&
                      selectedInvoice.lines.data
                        .filter(x => !x.description.startsWith("Vacancy"))
                        .map(x => (
                          <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt class="text-sm font-medium text-gray-500">{x.description}</dt>
                            <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">€{Number((x.amount / 100).toFixed(2))}</dd>
                          </div>
                        ))}
                  </dl>
                )}

                {selectedInvoice.lines.data.find(x => x.description.startsWith("Applicant")) && (
                  <dl class="mt-2">
                    {selectedInvoice &&
                      selectedInvoice.lines.data.length !== 0 &&
                      selectedInvoice.lines.data
                        .filter(x => !x.description.startsWith("Applicant"))
                        .map(x => (
                          <div class="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt class="text-sm font-medium text-gray-500">{x.description}</dt>
                            <dd class="mb-0 text-sm text-gray-900 sm:mt-0 sm:col-span-2">€{Number((x.amount / 100).toFixed(2))}</dd>
                          </div>
                        ))}
                  </dl>
                )}
              </div>
            </div>
          )}
        </SlideOver>
      </main>
    );
  }
}

// Connect Redux
function mapStateToProps(state) {
  return {
    group: state.auth.group,
    auth_loading: state.auth.isLoading,
    profile: state.auth.profile,
    members: state.Member.members,
    invoices: state.Billing.invoices,
    loadingState: state.auth.loadingState,
    message: state.auth.message,
    isError: state.auth.err
  };
}

export default connect(mapStateToProps, { updateProfile, updateGroup, getMembers, inviteMember, getInvoices, uploadGroupIcon, uploadProfileIcon })(Settings);
