export const CATEGORIES = [
  {
    title: 'All files',
    value: ''
  },
  {
    title: 'Photos',
    value: 'image'
  },
  {
    title: 'Videos',
    value: 'video'
  }
]