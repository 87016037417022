import React from 'react'

import { InfoPanelHeader } from '.'

const InfoPanel = ({ 
  title,
  isEmpty = false,
  emptyComponent = <></>,
  onClose,
  children,
  ...rest
}) => {
  return (
    <div className="flex flex-col h-full bg-white flex-grow" {...rest}>
      {!isEmpty && <InfoPanelHeader title={title} onClose={onClose} />}
      <div className="flex-grow">
        {!isEmpty ? children : emptyComponent}
      </div>
    </div>
  )
}

export default InfoPanel