import React from "react";
import { orderBy } from "lodash";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

import { getImageUrl } from "../../../../utils";
import GalleryUpload from "./GalleryUpload";
import { CloseIcon } from "../../../Atoms";

const SortableImage = sortableElement(({ file, index, handleRemoveImage }) => {
  return (
    <div
      data-cy="gallery-image"
      key={index}
      className="relative cursor-pointer"
    >
      <CloseIcon onClick={() => handleRemoveImage(file)} />

      <div
        className="w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('${getImageUrl(file)}')`,
          height: "150px"
        }}
      />
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div className="bg-gray-100 w-full" style={{ minHeight: "400px" }}>
      <div className="grid grid-cols-3 gap-4 p-2">{children}</div>
    </div>
  );
});

const GalleryImages = ({
  files,
  handleGallerySorted,
  handleRemoveImage,
  handleOpenMediaLibrary,
  handleFileUploaded
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMoveImmutable(files, oldIndex, newIndex);
    handleGallerySorted(sorted);
  };

  return (
    <>
      <SortableContainer onSortEnd={onSortEnd} axis="xy" pressDelay={100}>
        {orderBy(files || [], file => file.order).map((file, index) => (
          <SortableImage
            key={`item-${file.Key}`}
            index={index}
            file={file}
            handleRemoveImage={handleRemoveImage}
          />
        ))}
      </SortableContainer>

      <GalleryUpload
        className="flex bg-gray-100 flex-col px-4 space-y-2 justify-center items-center relative border-dashed border-4 border-gray-300 cursor-pointer"
        style={{ height: "150px" }}
        handleOpenMediaLibrary={handleOpenMediaLibrary}
        handleFileUploaded={handleFileUploaded}
      />
    </>
  );
};

export default GalleryImages;
