import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Field, ErrorMessage } from "formik";
import axios from "axios";
import { Input } from "../../Atoms/Form";
import { Button } from "../../Atoms/Elements";
import { SpinnerCircle } from "../../Atoms/Animation";

import "react-quill/dist/quill.snow.css";

const CopyForm = props => {
  const { values, setFieldValue } = props;
  const [scrapeUrl, setScrapeUrl] = useState("");
  const [scrapeContentStatus, setScrapeContentStatus] = useState({
    loading: false
  });

  const handleContentInsert = async () => {
    setScrapeContentStatus({
      loading: true
    });
    try {
      const {
        data
      } = await axios.post(
        `${process.env.REACT_APP_STUDIO_WEB_API}/tools/scrape-jobpost`,
        { url: scrapeUrl }
      );
      setFieldValue("content", data);
      return setScrapeContentStatus({
        loading: false
      });
    } catch (err) {
      return setScrapeContentStatus({
        loading: false
      });
    }
  };
  return (
    <>
      <div className="mt-6 grid grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-4">
          <label
            for="first_name"
            className="block text-sm font-medium text-gray-700"
          >
            Content
          </label>

          <Field
            name="content"
            required
            render={({ field }) => {
              return (
                <ReactQuill
                  className="textarea-about-job-edit-job"
                  value={
                    values.length !== 0 && values.content && values.content
                      ? values.content
                      : "<p>Fill in about job content</p>"
                  }
                  onChange={value => {
                    setFieldValue("content", value);
                  }}
                />
              );
            }}
          />
          <ErrorMessage
            component="p"
            className="mb-0 mt-1 text-xs text-red-500"
            name={`content`}
          />
        </div>
      </div>
      <div className="mt-2">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Experimental: Automatically retrieve content of job post that is already online. Function is still experimental so always verify that the job content looks good before posting.
        </label>
        <div className="flex gap-x-2 items-center">
          <div className="flex-1">
            <Input
              data_cy="input-scraper-url"
              name="url"
              type="url"
              placeholder="Enter existing job post URL (http://...)"
              value={scrapeUrl}
              onChange={e => setScrapeUrl(e.target.value)}
            />
          </div>
          <div className="flex-2">
            <Button
              colorScheme="whiteOutline"
              size="lg"
              type="button"
              onClick={() => handleContentInsert()}
              loading={scrapeContentStatus.loading}
            >
              {scrapeContentStatus.loading ? (
                <SpinnerCircle theme="white" />
              ) : (
                "Get content"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyForm;
