export const APPLICATIONS_BY_GROUP = "APPLICATIONS_BY_GROUP";
export const APPLICATIONS_BY_GROUP_IS_LOADING = "APPLICATIONS_BY_GROUP_IS_LOADING";
export const APPLICATIONS_BY_GROUP_ERROR = "APPLICATIONS_BY_GROUP_ERROR";

export const APPLICATIONS_BY_VACANCY = "APPLICATIONS_BY_VACANCY";
export const APPLICATIONS_BY_VACANCY_IS_LOADING = "APPLICATIONS_BY_VACANCY_IS_LOADING";
export const APPLICATIONS_BY_VACANCY_ERROR = "APPLICATIONS_BY_VACANCY_ERROR";

export const SINGLE_APPLICATION = "SINGLE_APPLICATION";
export const SINGLE_APPLICATION_IS_LOADING = "SINGLE_APPLICATION_IS_LOADING";
export const SINGLE_APPLICATION_ERROR = "SINGLE_APPLICATION_ERROR";
