import React from "react";

const Block = props => {
  const { data_cy } = props;
  return (
    <div data-cy={data_cy} className="shadow sm:rounded-md sm:overflow-hidden relative">
      <div className="bg-indigo-600">
        <div className="flex justify-between items-center px-4 py-4">
          <div className="">
            <h2 id="payment_details_heading" className="mb-2 text-2xl text-white leading-6 font-bold text-gray-900">
              {props.title}
            </h2>
            <p className="mb-0 text-sm text-gray-50">{props.description}</p>
          </div>
          <div className="flex space-x-2">
            {props.optional && (
              <div className="flex justify-center items-center px-2 py-1 bg-indigo-200 rounded-sm">
                <p className="mb-0 text-xs text-indigo-500">OPTIONAL</p>
              </div>
            )}
          </div>
        </div>
        {props.type === "addon" && (
          <div className="absolute" style={{ right: "20px", top: "20px" }}>
            <button
              type="button"
              onClick={props.onClose}
              className="bg-gray-200 rounded-md p-1 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        )}

        <div className="px-4 py-4 bg-white">{props.children}</div>
      </div>
    </div>
  );
};

export default Block;
