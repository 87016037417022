/* eslint jsx-a11y/anchor-is-valid: 0 */
import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { CompleteForm, ResetPasswordForm, VerifyForm } from "../components/Forms";
import { verifyResetPasswordToken, resetPassword } from "../actions/Auth";
import { API_URL } from "../constants/url";

class RegisterMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingStatus: "reset_verify",
      user: {},
      group: {},
      trigger: false,
      email: ""
    };
  }

  componentDidMount = async () => {
    this.setState({
      email: window.location.pathname
        .split("/")
        .slice(-1)
        .pop()
    });
  };

  handleVerify = async values => {
    const { verifyResetPasswordToken } = this.props;
    const { email } = this.state;
    try {
      const verify_code = await verifyResetPasswordToken(email, values);

      const { status } = verify_code.payload.data;
      if (status === 200) {
        this.setState({
          onboardingStatus: "reset"
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleSubmit = async values => {
    const { email } = this.state;
    this.setState({ trigger: true });
    const { resetPassword } = this.props;
    try {
      const reset_password = await resetPassword(email, values);
      if (reset_password.response.status === 200) {
        this.setState({
          onboardingStatus: "complete"
        });
      }
    } catch (err) {
      return err;
    }
  };

  render() {
    const { auth_loading, auth_error, auth_message, group_loading, group_error, group_message } = this.props;
    const { onboardingStatus, trigger, group, email } = this.state;
    return (
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {onboardingStatus === "reset_verify" && (
              <VerifyForm
                title={`Verify your reset password email`}
                description="You received an email for us! Fill in the code to reset your password"
                onSubmit={values => this.handleVerify(values)}
                loading={auth_loading}
                error={auth_error}
                message={auth_message}
              />
            )}

            {onboardingStatus === "reset" && (
              <ResetPasswordForm handleSubmit={values => this.handleSubmit(values)} loading={auth_loading} error={auth_error} message={auth_message} title={`Reset your password`} trigger={trigger} />
            )}

            {onboardingStatus === "complete" && (
              <CompleteForm title="Mission accomplished!" description="You succesfully reset your password. Go back to login to use otellu again!" redirect="/login" redirectText="Login" />
            )}
          </div>
        </div>
        <div
          className="hidden lg:block relative  flex-1 bg-cover"
          style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80")' }}
        ></div>
      </div>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    auth_error: state.auth.err,
    auth_message: state.auth.message,
    auth_loading: state.auth.isLoading,
    group_loading: state.group.isLoading,
    group_error: state.group.err,
    group_message: state.group.message
  };
}

export default connect(mapStateToProps, { verifyResetPasswordToken, resetPassword })(RegisterMember);
