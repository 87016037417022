export const prices = [
  {
    vacancySlotAmount: 1,
    price: "€450",
    pricePerSlot: 45000,
    discount: "0.00%",
    saved: "€0",
    vacancySlotExpiryTime: 14
  },
  {
    vacancySlotAmount: 2,
    price: "€870",
    pricePerSlot: 43500,
    discount: "2.80%",
    saved: "€15",
    vacancySlotExpiryTime: 30
  },
  {
    vacancySlotAmount: 3,
    price: "€1260",
    pricePerSlot: 42000,
    discount: "6.30%",
    saved: "€30",
    vacancySlotExpiryTime: 30
  },
  {
    vacancySlotAmount: 4,
    price: "€1620",
    pricePerSlot: 40500,
    discount: "10.00%",
    saved: "€45",
    vacancySlotExpiryTime: 45
  },
  {
    vacancySlotAmount: 5,
    price: "€1900",
    pricePerSlot: 38000,
    discount: "15.00%",
    saved: "€70",
    vacancySlotExpiryTime: 45
  }
];
