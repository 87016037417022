import React, { useContext, useRef } from 'react'

import { ConfirmActionContext } from '../../../context'
import { Button } from '../../Atoms'
import { Modal } from '../Modal'

const TYPE_TO_TITLE = {
  vacancy: 'Job post',
  account: 'Admin/member',
  group: 'Your company'
}

const ConfirmAction = () => {
  const { content, open, setOpen, callback } = useContext(ConfirmActionContext)
  const { title, description, data, list } = content || {}

  return (
    <Modal isOpen={open} setOpen={setOpen}>
      <div className="p-4">
        <h5 className="text-lg leading-6 font-medium text-gray-900">{title}</h5>
        <p className="text-sm leading-5 font-normal text-gray-500 mb-0">{description}</p>
        {list && <ul className="pl-6 mt-3" style={{ listStyle: 'initial' }}>
          {list.map(li => <li className="text-sm leading-5 font-normal text-gray-500">{li.title} ({TYPE_TO_TITLE[li.type]})</li>)}
        </ul>}
        <div className="grid grid-cols-2 gap-2 button-container mt-4">
          <Button theme="purple" text="Confirm" onClick={() => callback && callback(data)} data_cy="button-confirm-action" />
          <Button theme="white" text="Cancel" onClick={() => setOpen(false)} />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmAction