import React from "react";
import { Button } from "../../Atoms";

const Header = props => {
  const { draftId, job_post, editStatus, group, loadingState, updateTrigger, readyToPublish, setActivateTrigger } = props;

  return (
    <header data-cy="edit-job-header" className="bg-white">
      <div className="max-w-9xl mx-auto  lg:divide-y lg:divide-gray-200 lg:px-8">
        <div className="flex justify-between py-3">
          <div className="px-2 flex lg:px-0">
            <div className="flex items-center">
              {editStatus && <h4 className="mb-0">{job_post.title}</h4>}
              {!editStatus && <h4 className="mb-0">Create a job</h4>}
            </div>
          </div>
          {!group.demo && (
            <div className="flex items-center justify-end flex-1 gap-4">
              <div className="w-40">
                <Button data_cy="button-save-job" loading={loadingState.update} type="submit" onClick={props.onClick} text="Save job post" theme="white" />
              </div>
              {job_post.active && (
                <a target="_blank" href={`${process.env.REACT_APP_PEOPLE_FRONTEND_URL}/c/${group.title}/${job_post._id}`}>
                  <Button text="View job post" type="button" theme="purple" />
                </a>
              )}

              {!job_post.active && job_post.title && readyToPublish && (
                <div className="w-40">
                  <Button data_cy="button-activate-job" loading={loadingState.publish} type="submit" onClick={setActivateTrigger} text="Publish job" theme="purple" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
