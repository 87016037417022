import { SKILLS_IS_LOADING, SKILLS, SKILLS_ERROR } from "../actions/types/skills";

const initialState = {
  skillOptions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SKILLS_IS_LOADING: {
      return { ...state, err: false, isLoading: false, loadingState: { fetch: true } };
    }
    case SKILLS: {
      const { data } = action.payload;
      return { ...state, skillOptions: data, err: false, isLoading: false, loadingState: { fetch: true } };
    }

    case SKILLS_ERROR: {
      return { ...state, err: true, isLoading: false, loadingState: { fetch: true } };
    }

    default:
      return state;
  }
};
