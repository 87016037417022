import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { convertYoutubeUrl } from "../../../helpers/editor";

const Youtube = props => {
  const { values, addonNmbr, setFieldValue } = props;
  const [correctYoutubeId, setCorrectYoutubeId] = useState(false);

  useEffect(() => {
    if (convertYoutubeUrl(values.data.youtubeUrl)) {
      setCorrectYoutubeId(true);
    }
  }, []);

  const setYoutubeUrl = url => {
    const youtubeId = convertYoutubeUrl(url);
    if (youtubeId) {
      setFieldValue(`addonData[${addonNmbr}].data.youtubeUrl`, `https://www.youtube.com/embed/${youtubeId}`);
      setCorrectYoutubeId(true);
    }
    if (youtubeId === null) {
      setFieldValue(`addonData[${addonNmbr}].data.youtubeUrl`, url);
      setCorrectYoutubeId(false);
    }
  };

  return (
    <div>
      <>
        <div className="flex flex-col space-y-6">
          <div className="col-span-4 sm:col-span-3">
            <label for={`addonData[${addonNmbr}].data.youtubeUrl`} className="block text-sm font-medium text-gray-700">
              Youtube url
            </label>
            <Field
              type="text"
              name={`addonData[${addonNmbr}].data.youtubeUrl`}
              id="youtubeUrl"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              onChange={e => setYoutubeUrl(e.target.value)}
              required
            />
          </div>

          {values && values.data.youtubeUrl && (
            <div className="col-span-4 sm:col-span-2">
              <label for="youtubeUrl" className="block text-sm font-medium text-gray-700">
                Preview video
              </label>
              {correctYoutubeId ? (
                <iframe width="560" height="315" src={values.data.youtubeUrl} />
              ) : (
                <div className="bg-gray-200 flex justify-center items-center" style={{ height: "315px", width: "560px" }}>
                  <p>Not able to preview because youtube url is not valid</p>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default Youtube;
