export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATE_IS_LOADING = "AUTHENTICATE_IS_LOADING";
export const AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_IS_LOADING = "CREATE_ACCOUNT_IS_LOADING";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";

export const CREATE_ACCOUNT_INV = "CREATE_ACCOUNT_INV";
export const CREATE_ACCOUNT_INV_IS_LOADING = "CREATE_ACCOUNT_INV_IS_LOADING";
export const CREATE_ACCOUNT_INV_ERROR = "CREATE_ACCOUNT_INV_ERROR";

export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_IS_LOADING = "VERIFY_ACCOUNT_IS_LOADING";
export const VERIFY_ACCOUNT_ERROR = "VERIFY_ACCOUNT_ERROR";

export const PROFILE = "PROFILE";
export const PROFILE_IS_LOADING = "PROFILE_IS_LOADING";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_IS_LOADING = "LOGOUT_USER_IS_LOADING";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

export const ONBOARD_GROUP = "ONBOARD_GROUP";
export const ONBOARD_GROUP_IS_LOADING = "ONBOARD_GROUP_IS_LOADING";
export const ONBOARD_GROUP_ERROR = "ONBOARD_GROUP_ERROR";

export const OPT_OUT_DEMO = "OPT_OUT_DEMO";
export const OPT_OUT_DEMO_IS_LOADING = "OPT_OUT_DEMO_IS_LOADING";
export const OPT_OUT_DEMO_ERROR = "OPT_OUT_DEMO_ERROR";

export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_IS_LOADING = "GET_GROUP_IS_LOADING";
export const GET_GROUP_ERROR = "GET_GROUP_ERROR";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_IS_LOADING = "UPDATE_GROUP_IS_LOADING";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

export const UPLOAD_GROUP_ICON = "UPLOAD_GROUP_ICON";
export const UPLOAD_GROUP_ICON_IS_LOADING = "UPLOAD_GROUP_ICON_IS_LOADING";
export const UPLOAD_GROUP_ICON_ERROR = "UPLOAD_GROUP_ICON_ERROR";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_IS_LOADING = "UPDATE_PROFILE_IS_LOADING";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const UPLOAD_PROFILE_ICON = "UPLOAD_PROFILE_ICON";
export const UPLOAD_PROFILE_ICON_IS_LOADING = "UPLOAD_PROFILE_ICON_IS_LOADING";
export const UPLOAD_PROFILE_ICON_ERROR = "UPLOAD_PROFILE_ICON_ERROR";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_IS_LOADING = "GET_PROFILE_IS_LOADING";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const CHECK_MEMBER_INV_CODE = "CHECK_MEMBER_INV_CODE";
export const CHECK_MEMBER_INV_CODE_IS_LOADING = "CHECK_MEMBER_INV_CODE_IS_LOADING";
export const CHECK_MEMBER_INV_CODE_ERROR = "CHECK_MEMBER_INV_CODE_ERROR";

export const INVITE_MEMBER = "INVITE_MEMBER";
export const INVITE_MEMBER_IS_LOADING = "INVITE_MEMBER_IS_LOADING";
export const INVITE_MEMBER_ERROR = "INVITE_MEMBER_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_IS_LOADING = "FORGOT_PASSWORD_IS_LOADING";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const CHECK_RESET_PASSWORD_CODE = "CHECK_RESET_PASSWORD_CODE";
export const CHECK_RESET_PASSWORD_CODE_IS_LOADING = "CHECK_RESET_PASSWORD_CODE_IS_LOADING";
export const CHECK_RESET_PASSWORD_CODE_ERROR = "CHECK_RESET_PASSWORD_CODE_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_IS_LOADING = "RESET_PASSWORD_IS_LOADING";
export const RESET_PASSWORD_ERROR = "CHECK_RESET_PASSWORD_ERROR";

export const GO_LIVE = "GO_LIVE";
export const GO_LIVE_IS_LOADING = "GO_LIVE_IS_LOADING";
export const GO_LIVE_ERROR = "CHECK_GO_LIVE_ERROR";

export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_PACKAGE_IS_LOADING = "ADD_PACKAGE_IS_LOADING";
export const ADD_PACKAGE_ERROR = "CHECK_ADD_PACKAGE_ERROR";
