import React, { useRef, useEffect } from 'react'

import { useClickOutsideComponent } from '../../../hooks'

import { StyledModal, StyledModalWrapper } from './styled'

const Modal = ({
  isOpen,
  setOpen,
  children,
  className
}) => {
  const ref = useRef()
  useClickOutsideComponent(ref, () => setOpen(false))

  useEffect(() => {
    isOpen
      ? document.querySelector('body').classList.add('overflow-hidden')
      : document.querySelector('body').classList.remove('overflow-hidden')

  }, [isOpen])

  return (
    <StyledModalWrapper $isOpen={isOpen}>
      <StyledModal $isOpen={isOpen} ref={ref} className={className}>
        {children}
      </StyledModal>
    </StyledModalWrapper>
  )
}

export default Modal