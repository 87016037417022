import React from "react";

const SkeletonEditor = () => {
  return (
    <>
      <header className="bg-white animate-pulse">
        <div className="max-w-9xl mx-auto bg-gray-300 lg:divide-y lg:divide-gray-200 lg:px-8">
          <div className="flex justify-between py-3">
            <div className="px-2 flex lg:px-0">
              <div className="flex items-center">
                <div className="h-6 w-64 bg-gray-400 rounded"></div>
              </div>
            </div>

            <div className="flex items-center justify-end flex-1 gap-4">
              <div className="w-32 h-8 bg-gray-400" />
              <div className="w-32 h-8 bg-indigo-400" />
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-9xl mx-auto pb-10 lg:py-12 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <div className="space-y-6 relative sm:px-6 lg:px-0 lg:col-span-9">
            <div className="w-full h-96 bg-gray-200 rounded-md">
              <div className="w-full h-20 bg-indigo-400 rounded-t-md"></div>
            </div>

            <div className="w-full h-96 bg-gray-200 rounded-md">
              <div className="w-full h-20 bg-indigo-400 rounded-t-md"></div>
            </div>

            <div className="w-full h-96 bg-gray-200 rounded-md">
              <div className="w-full h-20 bg-indigo-400 rounded-t-md"></div>
            </div>
          </div>
          <div className="lg:col-span-3 space-y-4">
            <div className="bg-gray-300 h-12 w-full"></div>
            <div className="bg-gray-300 h-12 w-full"></div>
            <div className="bg-gray-300 h-12 w-full"></div>
            <div className="bg-gray-300 h-12 w-full"></div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SkeletonEditor;
