/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, FormInput, FormCheckbox, FormSelect, Button } from "shards-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { registerInviteUser } from "../actions/index";
import amplitude from "amplitude-js";
import { countries } from "../data/countries.js";

var employerAnalytics = amplitude.getInstance();

class RegisterInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      adress: "",
      city: "",
      country: "",
      postal_code: "",
      password: "",
      password2: "",
      groupName: "",
      icon: "",
      token: "",
      groupId: "",
      checked: false,
      showingError: true
    };
  }

  componentDidMount() {
    this.setState({
      groupId: this.props.match.params.groupId,
      token: this.props.match.params.token
    });
  }

  handleCheck = e => {
    this.setState({ checked: !this.state.checked });
  };

  async componentDidUpdate(nextProps, history) {
    if (!this.props.error && !this.state.showingError) {
      if (this.props.message) {
        toast.success(this.props.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });

        await this.setState({ showingError: true });
        // await history.push("/vacancies");
      }
    }
    if (this.props.error && !this.state.showingError) {
      if (this.props.message) {
        toast.error(this.props.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });
        await this.setState({ showingError: true });
      }
    }
  }

  onRegister = async () => {
    const { name, email, password, password2, groupId, token } = this.state;
    if ((name && email && password && password2 && groupId, token)) {
      this.props.registerInviteUser(name, email, password, password2, groupId, token);
      await this.setState({ showingError: false });
    }
  };

  onUploadIcon = e => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let newState = Object.assign({}, this.state);
      newState.icon = reader.result;
      this.setState(newState);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  render() {
    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="8" md="10" sm="12" className="mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                <img className="auth-form__logo d-table mx-auto mb-3" src={require("../images/logos/otellu-icon-v1.svg")} alt="Shards Dashboards - Register Template" />

                {/* Title */}
                <h5 className="auth-form__title text-center mb-2">Let's make it official!</h5>
                <p className="text-center">You can create a new account down below:</p>

                {/* Form Fields */}
                <Form>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Full name</label>
                      <FormInput type="text" id="name" placeholder="Enter name" required autoComplete="name" onChange={e => this.setState({ name: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <FormInput type="email" id="exampleInputEmail1" placeholder="Enter email" required autoComplete="email" onChange={e => this.setState({ email: e.target.value })} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <FormInput type="password" id="exampleInputPassword1" placeholder="Password" autoComplete="new-password" required onChange={e => this.setState({ password: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputPassword2">Repeat password</label>
                      <FormInput
                        type="password"
                        id="exampleInputPassword2"
                        placeholder="Repeat Password"
                        autoComplete="new-password"
                        required
                        onChange={e => this.setState({ password2: e.target.value })}
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-50 mx-2">
                      <FormCheckbox checked={this.state.checked} onChange={e => this.handleCheck(e)}>
                        I agree with the{" "}
                        <a target="_blank" href="https://otellu.work/algemene-voorwaarden/">
                          Terms & Conditions
                        </a>
                        .
                      </FormCheckbox>
                    </FormGroup>
                  </div>
                  <Button pill theme="accent" className="d-table mx-auto" onClick={() => this.onRegister()}>
                    Create Account
                  </Button>
                </Form>
              </CardBody>
            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/forgot-password">Forgot your password?</Link>
              <Link to="/login" className="ml-auto">
                Sign In?
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    error: state.auth.err,
    message: state.auth.message
  };
}

export default connect(mapStateToProps, { registerInviteUser })(RegisterInvite);
