import React, { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import { Button, Alert } from "../Atoms";
import { Link } from "react-router-dom";

const CompleteForm = props => {
  return (
    <div className=" flex flex-col justify-center py-12">
      <div className="sm:mx-auto sm:w-full max-w-sm">
        {/* <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> */}
        <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-lg bg-green-100">
          <svg className="h-8 w-8 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z"
            />
          </svg>
        </div>
        <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">{props.title}</h2>
        <p className="mt-2 mb-0 text-center text-lg font-light text-gray-600 max-w">{props.description}</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <Link to={props.redirect}>
            <Button data_cy="button-confirm-onboarding" theme="purple" text={props.redirectText} type="button" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompleteForm;
