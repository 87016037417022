import React from "react";
import styled from 'styled-components'

const StyledDeleteButton = styled.div`
  background-color: #fff;
  transition: all 0.25s ease;

  svg path {
    color: #161e2e;
    transition: all 0.25s ease;
  }

  &:hover {
    background-color: #161e2e;

    svg path {
      color: #fff;
    }
  }
`

const CloseIcon = ({ className, ...props }) => {
  return (
    <StyledDeleteButton
        className={`absolute z-10 top-2 right-2 p-1 rounded-full cursor-pointer ${className}`}
        {...props}
    >
        <svg
        className="h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
        />
        </svg>
    </StyledDeleteButton>
  )
}

export default CloseIcon