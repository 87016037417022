import React, { useContext } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { AWS_URL } from "../../../constants/url";
import { Button } from "..";
import { createAsset } from "../../../actions";
import { ASSET_CREATE_ONE_DATA } from "../../../actions/Assets/types";
import { MediaLibraryContext } from "../../../context";

const StyledFileInput = styled.input`
  font-size: 0;
  cursor: pointer;

  &::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
`;

const InputFile = ({
  label,
  name,
  onChange,
  required,
  value,
  allowMediaLibrary = true,
  data_cy,
  useWhenAlreadyExist = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const { group } = useSelector(state => state.auth);
  const {
    isLoading: { value: isLoading }
  } = useSelector(state => state.Assets);
  const {
    setCallback,
    setMultiSelect,
    setTitle,
    setOpen,
    setExclude
  } = useContext(MediaLibraryContext);

  const handleUploadFromDesktop = e => {
    const { files } = e.target;

    dispatch(createAsset(group._id, files)).then(
      ({ type, file, alreadyExist }) => {
        if (type === ASSET_CREATE_ONE_DATA) {
          if (file.length) {
            onChange({ target: { name, value: file[0].Key } });
          } else if (alreadyExist.length && useWhenAlreadyExist) {
            onChange({ target: { name, value: alreadyExist[0] } });
          }
        }
      }
    );
  };

  const handleOpenMediaLibrary = () => {
    setCallback(selected => {
      if (selected.length) {
        onChange({ target: { name, value: selected[0].Key } });
      }
      setOpen(false);
    });

    setExclude([]);
    setTitle("Add a photo to your team member");
    setMultiSelect(false);
    setOpen(true);
  };

  const handleRemoveFile = e => {
    e.preventDefault();

    onChange({ target: { name, value: "" } });
  };

  return (
    <div>
      {label ? (
        <label for={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}

      <div className="mt-1 text-center">
        {value ? (
          <div class="flex items-center">
            <img
              className="w-12 h-12 rounded-full object-cover"
              src={`${AWS_URL}/${value}`}
            />
            <a
              href="#"
              onClick={handleRemoveFile}
              className="ml-4 text-gray-500 text-xs leading-none font-normal"
            >
              Remove photo
            </a>
          </div>
        ) : (
          <>
            {allowMediaLibrary && (
              <Button
                type="button"
                theme="white"
                text="Choose from library"
                onClick={handleOpenMediaLibrary}
              />
            )}
            {allowMediaLibrary ? (
              <a
                href="#"
                className="relative text-xs leading-none font-normal text-gray-500"
              >
                Upload from desktop
                <StyledFileInput
                  type="file"
                  data-cy={data_cy}
                  id={name}
                  name={name}
                  required={required}
                  className="w-100 h-100 left-0 top-0 absolute opacity-0 cursor-pointer"
                  multiple={false}
                  onChange={handleUploadFromDesktop}
                  {...props}
                />
              </a>
            ) : (
              <Button
                type="file"
                theme="white"
                text="Upload from desktop"
                onFilesUploaded={files =>
                  handleUploadFromDesktop({ target: { files } })
                }
                disabled={isLoading}
                loading={isLoading}
                data_cy_file={data_cy}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const Input = props => {
  const {
    onChange,
    name,
    type,
    value,
    placeholder,
    required,
    data_cy,
    ...rest
  } = props;

  if (type === "file") {
    return <InputFile {...props} />;
  }

  return (
    <input
      data-cy={data_cy}
      type={type}
      name={name}
      required={required}
      id={name}
      className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export default Input;
