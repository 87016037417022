import React from "react";
import { XIcon } from "@heroicons/react/solid";

const Pill = props => {
  const { selected, onClick, onRemove } = props;
  const activeColor = "bg-green-400 text-white";
  const defaultColor = "bg-white text-gray-900 ";

  return (
    <span onClick={onClick} className={`text-sm ${selected ? activeColor : defaultColor} py-2 px-4 rounded-md cursor-pointer`}>
      {props.children}
    </span>
  );
};

export default Pill;
