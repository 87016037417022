import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { ToggleIcon } from "../../Atoms/Icon";

const ToggleMenu = props => {
  const [menuState, setMenuState] = useState(false);
  return (
    <div>
      <div className="relative inline-block text-left">
        <div>
          <button
            className="flex items-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setMenuState(!menuState)}
          >
            <span className="sr-only">Open options</span>

            <ToggleIcon className="h-5 w-5" />
          </button>
        </div>
        <Transition
          show={menuState}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        >
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {props.children}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default ToggleMenu;
