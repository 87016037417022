import React, { createContext, useState, useRef } from 'react'

import { ConfirmAction } from '../components/Molecules/ConfirmAction'

const INITIAL = {
  open: false,
  content: { title: '', description: '', data: null, list: [] },
  callback: () => {},
  setOpen: () => {},
  setContent: () => {},
  setCallback: () => {},
}

const ConfirmActionContext = createContext(INITIAL)

export const ConfirmActionProvider = ({ children }) => {
  const callback = useRef()
  const setCallback = cb => callback.current = cb

  const [open, setOpen] = useState(false)
  const [content, setContent] = useState({ title: '', description: '', data: null, list: [] })

  return (
    <ConfirmActionContext.Provider value={{ open, setOpen, callback: callback.current, setCallback, content, setContent }}>
      <ConfirmAction />
      {children}
    </ConfirmActionContext.Provider>
  )
}

export default ConfirmActionContext