import React from "react";

const TabList = props => {
  return (
    <div className="hidden bg-white lg:block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px px-8  flex space-x-4">
          {/* <!-- Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}
          {props.children}
        </nav>
      </div>
    </div>
  );
};

export default TabList;
