import axios from "axios";
import crypto from "crypto";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { get } from "lodash";
import { setUpTeamFiles, getTeamFiles } from "../helpers/storage";
import {
  triggerToastError,
  triggerToastSuccess
} from "../helpers/handleResponse";
import { API_URL, AWS_URL } from "../constants/url";

import {
  VACANCY,
  VACANCY_IS_LOADING,
  VACANCY_ERROR,
  ALL_VACANCY,
  ALL_VACANCY_IS_LOADING,
  ALL_VACANCY_ERROR,
  ACTIVATE_VACANCY,
  ACTIVATE_VACANCY_IS_LOADING,
  ACTIVATE_VACANCY_ERROR,
  UPDATE_VACANCY,
  UPDATE_VACANCY_IS_LOADING,
  UPDATE_VACANCY_ERROR,
  DRAFT_VACANCY,
  DRAFT_VACANCY_IS_LOADING,
  DRAFT_VACANCY_ERROR
} from "./types/vacancy";
import { bearer } from "../helpers/Bearer";
const header = bearer();

export const getAllVacancies = groupId => async dispatch => {
  try {
    dispatch({ type: ALL_VACANCY_IS_LOADING });
    const response = await axios.get(
      `${API_URL}/vacancies/?groupId=${groupId}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );

    const headerImageURLs = [];

    // Find the header images associated with each vacancy
    // to use the first image as an example in the vacancy overview
    // if (response) {
    //   await Promise.all(
    //     response.data.data.map(async vac => {
    //       // returns all vacancy files (images)
    //       const vacFiles = await axios.get(`${API_URL}/aws/folder/?type=vacancy&folderType=headerImages&id=${vac._id}`);
    //       // use the first image as a preview
    //       if (vacFiles.data.data.length !== 0 && vacFiles.data.data[0].Key) {
    //         //assing previewUrl to vacancy if vacancy has an image
    //         vac.previewImgURL = vacFiles.data.data[0].Key;
    //       }
    //     })
    //   );
    // }
    // if (response) {
    //   response.data.data.forEach((v, idx) => {
    //     response.data.data[idx].previewImgURL = v.gallery && v.gallery.length ? v.gallery[0].Key : '';
    //   })
    // }

    return dispatch({ type: ALL_VACANCY, payload: response });
  } catch (err) {
    return dispatch({
      type: ALL_VACANCY_ERROR,
      payload: err
    });
  }
};

export const getVacancy = vacancyId => async dispatch => {
  try {
    dispatch({ type: VACANCY_IS_LOADING });

    const response = await axios.get(`${API_URL}/vacancies/${vacancyId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    });

    return dispatch({ type: VACANCY, payload: response });
  } catch (err) {
    return dispatch({
      type: VACANCY_ERROR,
      payload: err
    });
  }
};

// Consists of two steps: update and activate. If either fails, action fails.
// Always also update the vacancy -before- activating, to make sure
// that the most up-to-date information is retrieved when the vacancy
// is updated at the end of the cycle.
export const activateVacancy = (
  vacancyId,
  vacancyTitle,
  values,
  user,
  group
) => async dispatch => {
  console.log("activate action");

  const config = {
    headers: {
      Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
    }
  };

  // 1. Update
  try {
    dispatch({ type: UPDATE_VACANCY_IS_LOADING });
    var bodyFormData = new FormData();
    const addonIds = values.addonData.map(x => x.addonId);
    const valsPlusActive = { ...values, active: true };

    const response = await axios.put(
      `${API_URL}/vacancies/${vacancyId}`,
      {
        ...valsPlusActive,
        addons: addonIds,
        author: user._id,
        companyName: group.title
      },
      config
    );
    triggerToastSuccess(response.data.message);
    dispatch({ type: UPDATE_VACANCY, payload: response });
  } catch (err) {
    let error = "Failed to update vacancy";

    // Check for validation errors
    const childError = get(err, "response.data.childError", {});
    if (childError.name === "ValidationError" && childError.errors) {
      error = Object.values(childError.errors)[0].message;
    }

    triggerToastError(error);
    return dispatch({
      type: UPDATE_VACANCY_ERROR,
      payload: err
    });
  }

  // 2. Activate
  try {
    dispatch({ type: ACTIVATE_VACANCY_IS_LOADING });

    // Just send a message to Slack: the actual activation call is done from Retool.
    await axios.post(
      `${API_URL}/slack/messages/${vacancyId}`,
      {
        vacancyTitle: vacancyTitle,
        groupName: group.title
      },
      config
    );

    // If successful, set vac to pending (draft:false, active: false)
    const response = await axios.put(
      `${API_URL}/vacancies/${vacancyId}`,
      {
        active: false,
        draft: false
      },
      config
    );

    return dispatch({ type: ACTIVATE_VACANCY, response });
  } catch (err) {
    console.log(err);
    let error = "Failed to activate vacancy";

    // Check for validation errors
    const childError = get(err, "response.data.childError", {});
    if (childError.name === "ValidationError" && childError.errors) {
      error = Object.values(childError.errors)[0].message;
    }

    triggerToastError(error);

    return dispatch({
      type: ACTIVATE_VACANCY_ERROR,
      payload: err
    });
  }
};

export const draftVacancy = (group, slot) => async dispatch => {
  try {
    dispatch({ type: DRAFT_VACANCY_IS_LOADING });
    const response = await axios.post(
      `${API_URL}/vacancies/draft`,
      {
        groupId: group._id,
        packageStripeInvoiceId: slot.stripeInvoiceId
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );
    return dispatch({ type: DRAFT_VACANCY, payload: response });
  } catch (err) {
    toast.error("Something went wrong, connect our support", {
      position: "bottom-center"
    });
    return dispatch({
      type: DRAFT_VACANCY_ERROR,
      payload: err
    });
  }
};

export const updateVacancy = (
  vacancyId,
  values,
  user,
  group
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_VACANCY_IS_LOADING });
    const config = {
      headers: {
        Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
      }
    };
    var bodyFormData = new FormData();

    //get addonIds
    const addonIds = values.addonData.map(x => x.addonId);

    const response = await axios.put(
      `${API_URL}/vacancies/${vacancyId}`,
      {
        ...values,
        addons: addonIds,
        author: user._id,
        companyName: group.title
      },
      config
    );

    triggerToastSuccess(response.data.message);

    return dispatch({ type: UPDATE_VACANCY, payload: response });
  } catch (err) {
    let error = "Failed to update vacancy";

    // Check for validation errors
    const childError = get(err, "response.data.childError", {});
    if (childError.name === "ValidationError" && childError.errors) {
      error = Object.values(childError.errors)[0].message;
    }

    triggerToastError(error);
    return dispatch({
      type: UPDATE_VACANCY_ERROR,
      payload: err
    });
  }
};
