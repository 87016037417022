export const dashboardMockup = {
  totalApplicants: 30,
  totalVacancies: 4,
  totalMembers: 4,
  currentJobSlots: 3
};

export const vacanciesMockup = [
  {
    _id: process.env.REACT_APP_MOCKUP_JOB_ID,
    active: true,
    draft: false,
    archive: false,
    visible: true,
    applicants: [],
    aboutCompany: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutJob: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutYou: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    branch: "",
    employmentType: "Full-time",
    remoteWork: false,
    city: "Amsterdam",
    title: "Frontend developer",
    workLevel: "Junior",
    previewImgURL: "headerImages/demo/previewImage.jpeg",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 17, 18], _id: "60d31cb4bc46af776d1b6e48", label: "Jest", value: 182, __v: 0, createdAt: "2021-06-23T11:36:20.203Z", updatedAt: "2021-06-23T11:36:20.203Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ]
  },
  {
    _id: process.env.REACT_APP_MOCKUP_JOB_ID,
    active: true,
    draft: false,
    archive: false,
    visible: true,
    applicants: [],
    aboutCompany: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutJob: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutYou: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    branch: "",
    employmentType: "Full-time",
    remoteWork: false,
    city: "Amsterdam",
    title: "Finance controller",
    workLevel: "Junior",
    previewImgURL: "headerImages/demo/previewImage.jpeg",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 17, 18], _id: "60d31cb4bc46af776d1b6e48", label: "Jest", value: 182, __v: 0, createdAt: "2021-06-23T11:36:20.203Z", updatedAt: "2021-06-23T11:36:20.203Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ]
  },
  {
    _id: process.env.REACT_APP_MOCKUP_JOB_ID,
    active: true,
    draft: false,
    visible: true,
    applicants: [],
    aboutCompany: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutJob: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutYou: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    branch: "",
    employmentType: "Full-time",
    remoteWork: false,
    city: "Amsterdam",
    title: "Marketing manager",
    workLevel: "Junior",
    previewImgURL: "headerImages/demo/previewImage.jpeg",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 17, 18], _id: "60d31cb4bc46af776d1b6e48", label: "Jest", value: 182, __v: 0, createdAt: "2021-06-23T11:36:20.203Z", updatedAt: "2021-06-23T11:36:20.203Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ]
  },
  {
    _id: process.env.REACT_APP_MOCKUP_JOB_ID,
    active: true,
    archive: false,
    visible: true,
    applicants: [],
    aboutCompany: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutJob: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    aboutYou: "<p>Nullam vel sem. Phasellus accumsan cursus velit. Pellentesque auctor neque nec urna.</p>",
    branch: "",
    employmentType: "Full-time",
    remoteWork: false,
    city: "Amsterdam",
    title: "Customer support",
    workLevel: "Junior",
    previewImgURL: "headerImages/demo/previewImage.jpeg",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 17, 18], _id: "60d31cb4bc46af776d1b6e48", label: "Jest", value: 182, __v: 0, createdAt: "2021-06-23T11:36:20.203Z", updatedAt: "2021-06-23T11:36:20.203Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ]
  }
];

export const applicationsMockup = [
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Tommy Rippeon",
    email: "tommy.rippeon@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],

    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Irma Leisy",
    email: "irma.leisy@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Ramona Bade",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Dagmar Reger",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Yelena Moors",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Mariano Tourville",
    email: "tommy.rippeon@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Lashay Jenkins",
    email: "irma.leisy@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Garret Leary",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Marilyn Seidel",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Benton Kolstad",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Page Diller",
    email: "tommy.rippeon@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Lashawn Bish",
    email: "irma.leisy@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Carl Inskeep",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Bell Pollitt",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Customer support",
    name: "Demetrius Strausbaugh",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Customer support",
    name: "Marcy Bearden",
    email: "tommy.rippeon@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Customer support",
    name: "Rosemary Hottle",
    email: "irma.leisy@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Customer support",
    name: "Bryon Morelli",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Anjelica Wadkins",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Jake Zazueta",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Christel Dibble",
    email: "tommy.rippeon@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Frontend developer",
    name: "Twana Height",
    email: "irma.leisy@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      },
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Noble Rohrbach",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Brady Sorg",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Lorenza Tobler",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Sonia Phillippe",
    email: "tommy.rippeon@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Finance controller",
    name: "Alethia Herrick",
    email: "irma.leisy@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Soft Skills",
        data: {
          softSkillOne: "motivation",
          softSkillTwo: "teamwork",
          SSScoreTotal: "45",
          SSScoreOne: "23",
          SSScoreTwo: "67",
          applicantTypeformToken: "b2oy55ofqt6"
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Lavone Fredericks",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Glennie Arehart",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  },
  {
    _id: "601d1af902e8260024681188",
    isVerified: true,
    vacancyId: "601accd5da14170024b07484",
    groupId: "601552376dbe0206bcdc4604",
    vacancyTitle: "Marketing manager",
    name: "Josephine Orwig",
    email: "timo@gmail.com",
    phone: "0611299790",
    motivation:
      "I'm really driven by results. I like it when I have a concrete goal to meet and enough time to figure out a strong strategy for accomplishing it. At my last job, our yearly goals were very aggressive, but I worked with my manager and the rest of my team to figure out a month-by-month strategy for meeting the year-end numbers. It was a real thrill to accomplish that.",
    specifications: {
      workLevel: "Junior"
    },
    applicantId: "601d144e85f1bdd972bd79f7",
    skills: [
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e38", label: "HTML", value: 32, __v: 0, createdAt: "2021-06-23T11:36:20.202Z", updatedAt: "2021-06-23T11:36:20.202Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6e81", label: "Next.js", value: 221, __v: 0, createdAt: "2021-06-23T11:36:20.207Z", updatedAt: "2021-06-23T11:36:20.207Z" },
      { jobIds: [16, 18], _id: "60d31cb4bc46af776d1b6eb2", label: "React", value: 7, __v: 0, createdAt: "2021-06-23T11:36:20.210Z", updatedAt: "2021-06-23T11:36:20.210Z" }
    ],
    addonData: [
      {
        name: "Questions",
        data: {
          questions: [
            {
              question: "Do you have a allergy for dogs?",
              answer: "no"
            },
            {
              question: "Do you have a transport card?",
              answer: "yes"
            }
          ]
        }
      }
    ],
    createdAt: "2021-02-05T10:16:25.969+00:00"
  }
];
