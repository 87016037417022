import mixpanel from "mixpanel-browser";
mixpanel.init("f7430ffce0e5959e5dc226ffeccd686d", { api_host: "https://api-eu.mixpanel.com" }, "");

let env_check = process.env.REACT_APP_ENV === "production";

let actions = {
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props);
    }
  }
};

export let Mixpanel = actions;
