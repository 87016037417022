import React, { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import { Button, Alert } from "../Atoms";

const VerifyForm = props => {
  const [value, setValue] = useState("");

  const style_props = {
    inputStyle: {
      fontFamily: "monospace",
      margin: "4px",
      webkitAppearance: "none",
      MozAppearance: "textfield",
      width: "40px",
      borderRadius: "3px",
      fontSize: "14px",
      height: "40px",
      paddingLeft: "7px",
      backgroundColor: "white",
      color: "black",
      border: "1px solid gray"
    },
    inputStyleInvalid: {
      fontFamily: "monospace",
      margin: "4px",
      MozAppearance: "textfield",
      width: "15px",
      borderRadius: "3px",
      fontSize: "14px",
      height: "26px",
      paddingLeft: "7px",
      backgroundColor: "black",
      color: "red",
      border: "1px solid red"
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (value.length === 6) {
      return props.onSubmit(value);
    }
  };
  const { loading, message, error } = props;
  return (
    <div className=" flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        {/* <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> */}

        <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-lg bg-indigo-100">
          <svg className="h-8 w-8 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{props.title}</h2>
        <p className="mt-2 mb-0 text-center text-base font-light text-gray-700 max-w">{props.description}</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <form className="space-y-6" onSubmit={onSubmit}>
          <div className="flex flex-col items-center justify-center mb-8">
            <ReactCodeInput onChange={val => setValue(val)} />
          </div>
          {!loading && error && <Alert status="error" message={message} />}
          <div>
            <Button data_cy="button-verify" theme="purple" text="Verify account" type="submit" loading={loading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyForm;
