import axios from "axios";
import crypto from "crypto";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { API_URL } from "../../constants/url";
import { multiPart } from "../../helpers/Bearer";
const headerFile = multiPart();
export const UPDATE_VACANCY_IS_LOADING = "update_vacancy_is_loading";
export const UPDATE_VACANCY_DATA = "update_vacancy_data";
export const UPDATE_VACANCY_ERROR = "update_vacancy_error";

export const updateVacancy = (
  { vacancy, headerImages, gallery, content, teamImages, team, questions },
  fromSwitch
) => async dispatch => {
  var bodyFormData = new FormData();
  bodyFormData.set("id", vacancy._id);
  bodyFormData.set("maxApplicants", vacancy.maxApplicants);
  bodyFormData.set("jobTitle", vacancy.jobTitle);
  bodyFormData.set("branch", vacancy.branch);
  bodyFormData.set("workLevel", vacancy.workLevel);
  bodyFormData.set("employmentType", vacancy.employmentType);
  bodyFormData.set("title", vacancy.title);
  bodyFormData.set("shortDescription", vacancy.shortDescription);
  bodyFormData.set("type", vacancy.type);
  bodyFormData.set("content", content);
  bodyFormData.set("softSkillOne", vacancy.softSkillOne);
  bodyFormData.set("softSkillTwo", vacancy.softSkillTwo);
  bodyFormData.set("youtubeUrl", vacancy.youtubeUrl);
  bodyFormData.set("typeFormId", vacancy.typeFormId);
  bodyFormData.set(
    "team",
    JSON.stringify(
      team.map(t => ({
        name: t.name,
        function: t.function,
        linkedin: t.linkedin,
        imgFileName: t.imgFileName
      }))
    )
  );
  bodyFormData.set(
    "questions",
    vacancy.type === "premium"
      ? JSON.stringify(questions.map(t => ({ question: t.question })))
      : []
  );
  bodyFormData.set("gallery", gallery);

  try {
    dispatch({ type: UPDATE_VACANCY_IS_LOADING });
    const result = await axios.put(
      `${API_URL}/vacancies/${vacancy._id}`,
      bodyFormData,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("otellu-studio-jwt-token")
        }
      }
    );

    toast.success("Sucessfully updated a vacancy");

    return dispatch({ type: UPDATE_VACANCY_DATA, result });
  } catch (err) {
    return dispatch({
      type: UPDATE_VACANCY_ERROR,
      payload: err
    });
  }
};
