import axios from "axios";
import { API_URL } from "../../constants/url";

export const REGISTER_IS_LOADING = "register_is_loading";
export const REGISTER_DATA = "register_data";
export const REGISTER_ERROR = "register_error";

export const REGISTER_PARTNER_IS_LOADING = "register_partner_is_loading";
export const REGISTER_PARTNER_DATA = "register_partner_data";
export const REGISTER_PARTNER_ERROR = "register_partner_error";

export const registerUser = (name, email, password, password2, groupName, checked, address, city, country, zip, icon, demo) => async dispatch => {
  try {
    dispatch({ type: REGISTER_IS_LOADING });
    const result = await axios.post(`${API_URL}/auth/register`, {
      name,
      email,
      password,
      password2,
      groupName,
      address,
      city,
      country,
      zip,
      icon,
      demo
    });
    return dispatch({ type: REGISTER_DATA, result });
  } catch (err) {
    return dispatch({
      type: REGISTER_ERROR,
      payload: err
    });
  }
};

export const registerPartner = (name, email, password, password2, groupName, checked, address, city, country, zip, icon, groupId, token) => async dispatch => {
  try {
    dispatch({ type: REGISTER_PARTNER_IS_LOADING });

    const result = await axios.post(`${API_URL}/auth/register/invitation/partner/${groupId}/invitationToken/${token}`, {
      name,
      email,
      password,
      password2,
      groupName,
      address,
      city,
      country,
      zip,
      icon
    });
    return dispatch({ type: REGISTER_PARTNER_DATA, result, err: false });
  } catch (err) {
    return dispatch({
      type: REGISTER_PARTNER_ERROR,
      payload: err,
      message: "Register failed",
      err: true
    });
  }
};
