import React from "react";

const Questions = props => {
  const { data } = props;
  return (
    <div className="overflow-y-scroll">
      <div className="pb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Questions</h3>
        <p className="mb-0 mt-1 max-w-2xl text-sm text-gray-500">See the answers of the extra questions.</p>
      </div>

      <div className="space-y-2">
        {data.questions.map(q => (
          <div className="sm:p-0">
            <dt className="text-lg leading-5 font-medium text-gray-900 mb-2">{q.question}</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md">
                <li className={`pl-3 pr-4 py-3 flex items-center justify-between text-sm rounded-tl-md rounded-tr-md leading-5 ${q.answer === "yes" && "bg-green-100"} `}>
                  <div className="w-0 flex-1 flex flex-col">
                    <p className="text-base w-full mb-0 flex-1 w-0 truncate text-gray-900">Yes</p>
                  </div>
                </li>
                <li className={`border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm rounded-bl-md rounded-br-md leading-5 ${q.answer === "no" && "bg-green-100"}`}>
                  <div className="w-0 flex-1 flex flex-col">
                    <p className="text-base w-full mb-0 flex-1 w-0 truncate text-gray-900">No</p>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
