import React from 'react'
import { Container } from 'shards-react'
import styled from 'styled-components'

import PageTitle from '../components/common/PageTitle'

import { useNavHeight } from '../hooks'

const ContentLeft = styled.div`
  flex-basis: 66.66667%;
  width: 66.66667%;
`

const ContentRight = styled.div`
  flex-basis: 33.33332%;
  width: 33.33332%;
`

/**
 *  Used for media library & team page.
 */
const ScrollableLayout = ({ title, cta, contentLeft, contentRight }) => {
  const { headerHeight, footerHeight } = useNavHeight()

  return (
    <Container fluid className="main-content-container px-2 px-sm-4 relative">
      <div
        className="max-w-7xl mx-auto flex overflow-hidden"
        style={{ height: `calc(100vh - ${headerHeight + footerHeight}px)`}}
      >
        <ContentLeft className="pr-2 flex flex-column">
          {title && (
            <div className="page-header py-4 flex justify-between items-center">
              <h3 className="page-title">{title}</h3>
              <div>
                {cta}
              </div>
            </div>
          )}

          {contentLeft}
        </ContentLeft>

        <ContentRight className="pl-2">
          {contentRight}
        </ContentRight>
      </div>
    </Container>
  )
}

export default ScrollableLayout