import React, { useEffect, useState } from "react";
import { Field } from "formik";

const LocationForm = props => {
  const { values } = props;
  return (
    <div className="space-y-4">
      <div className="flex space-x-2 items-center">
        <Field
          data-cy="checkbox-remote-edit-job"
          checked={values.remote}
          name="remote"
          default={false}
          as="input"
          type="checkbox"
          id="remote"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        <label htmlFor="remote" className="mb-0 block text-sm font-medium text-gray-700">
          Remote work is possible
        </label>
      </div>
      <div className="flex space-x-2 items-center">
        <Field
          data-cy="checkbox-openToMove-edit-job"
          checked={values.openToMove}
          name="openToMove"
          default={false}
          as="input"
          type="checkbox"
          id="openToMove"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        <label htmlFor="openToMove" className="mb-0 block text-sm font-medium text-gray-700">
          Open for applicants that want to relocate to your area
        </label>
      </div>
    </div>
  );
};

export default LocationForm;
