import React, { useState } from "react";
import { AddonCard } from "../Card";

const PackageModal = props => {
  const { addonsOptions } = props;
  const [addOns, setAddOns] = useState([
    {
      type: "softskills",
      id: 0,
      price: "€10",
      title: "Softskills",
      description: "Nam eget dui. Fusce fermentum odio nec arcu.",
      icon: "http://images.ctfassets.net/k4kk06v59kf0/3zsEu3ZoP6gSVML5bplmkk/284ad245cf27e6b5160c75ba502f6f5e/nodejs.svg",
      active: false
    },
    {
      type: "questions",
      id: 1,
      price: "€5",
      title: "Questions",
      description: "Nam eget dui. Fusce fermentum odio nec arcu.",
      icon: "http://images.ctfassets.net/k4kk06v59kf0/5AvfyPq1Iu6mvH6OlEsa5c/35af78a0019dd3fd817566f31f616ada/php.svg",
      active: false
    },
    {
      type: "team",
      id: 2,
      price: "free",
      title: "Team",
      description: "Nam eget dui. Fusce fermentum odio nec arcu.",
      icon: "https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg",
      active: false
    },
    {
      type: "youtube",
      id: 3,
      price: "free",
      title: "Youtube video",
      description: "Nam eget dui. Fusce fermentum odio nec arcu.",
      icon: "https://www.youtube.com/about/static/svgs/icons/brand-resources/YouTube_icon_full-color.svg",
      active: false
    }
  ]);

  const selectAddOn = id => {
    let newArr = [...addOns];
    newArr[id].active = !newArr[id].active;
    setAddOns(newArr);
    props.onClick(id);
  };
  return (
    <div className="flex flex-col align-center text-left justify-between">
      <div className="flex gap-4 overflow-auto py-4">
        {addonsOptions.map((x, index) => (
          <div className="max-w-xs self-stretch" key={index} style={{ minWidth: "300px" }}>
            <AddonCard addon={x} onClick={() => props.selectAddon(x)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackageModal;
