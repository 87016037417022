import React from "react";
import { Field, FieldArray } from "formik";

const Questions = props => {
  const { values, addonNmbr, job_post } = props;
  return (
    <FieldArray
      name={`addonData[${addonNmbr}].data.questions`}
      render={arrayHelpers => (
        <div className="space-y-4 max-w-xl">
          {values.data.questions.length !== 0 &&
            values.data.questions.map((t, index) => (
              <div className="flex justify-between gap-4 items-center" key={index}>
                {/** both these conventions do the same */}
                <div className="w-full">
                  <label for={`addonData[${addonNmbr}].data.questions[${index}].question`} className="block text-sm font-medium text-gray-700">
                    Question
                  </label>

                  <Field
                    id={`addonData[${addonNmbr}].data.questions[${index}].question`}
                    name={`addonData[${addonNmbr}].data.questions[${index}].question`}
                    type="text"
                    placeholder="Are you allergic for the office dog?"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    disabled={job_post.active}
                  />
                </div>
                {!job_post.active && (
                  <div className=" flex items-center ml-auto mt-4">
                    <button className="rounded-full h-8 w-8 bg-red-200 p-2" type="button" onClick={() => arrayHelpers.remove(index)}>
                      <svg className="w-4 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            ))}
          {values.data.questions.length <= 2 && !job_post.active && (
            <div className="mt-4 cursor-pointer">
              <button className="flex items-center gap-2 rounded-md bg-indigo-200 p-2" type="button" onClick={() => arrayHelpers.push({ question: "" })}>
                <span className="text-sm text-indigo-700 ">Add question</span>
                <svg className="w-4 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default Questions;
