import { getErrorMessage } from "../../helpers/handleResponse";

import {
  AUTHENTICATE,
  AUTHENTICATE_IS_LOADING,
  AUTHENTICATE_ERROR,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_IS_LOADING,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_INV,
  CREATE_ACCOUNT_INV_IS_LOADING,
  CREATE_ACCOUNT_INV_ERROR,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_IS_LOADING,
  VERIFY_ACCOUNT_ERROR,
  PROFILE,
  PROFILE_IS_LOADING,
  PROFILE_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_IS_LOADING,
  LOGOUT_USER_ERROR,
  ONBOARD_GROUP,
  ONBOARD_GROUP_IS_LOADING,
  ONBOARD_GROUP_ERROR,
  OPT_OUT_DEMO,
  OPT_OUT_DEMO_IS_LOADING,
  OPT_OUT_DEMO_ERROR,
  GET_GROUP,
  GET_GROUP_IS_LOADING,
  GET_GROUP_ERROR,
  UPDATE_GROUP,
  UPDATE_GROUP_IS_LOADING,
  UPDATE_GROUP_ERROR,
  UPLOAD_GROUP_ICON,
  UPLOAD_GROUP_ICON_IS_LOADING,
  UPLOAD_GROUP_ICON_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IS_LOADING,
  UPDATE_PROFILE_ERROR,
  UPLOAD_PROFILE_ICON,
  UPLOAD_PROFILE_ICON_IS_LOADING,
  UPLOAD_PROFILE_ICON_ERROR,
  GET_PROFILE,
  GET_PROFILE_IS_LOADING,
  GET_PROFILE_ERROR,
  CHECK_MEMBER_INV_CODE,
  CHECK_MEMBER_INV_CODE_IS_LOADING,
  CHECK_MEMBER_INV_CODE_ERROR,
  INVITE_MEMBER,
  INVITE_MEMBER_IS_LOADING,
  INVITE_MEMBER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_IS_LOADING,
  FORGOT_PASSWORD_ERROR,
  CHECK_RESET_PASSWORD_CODE,
  CHECK_RESET_PASSWORD_CODE_IS_LOADING,
  CHECK_RESET_PASSWORD_CODE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_IS_LOADING,
  RESET_PASSWORD_ERROR,
  GO_LIVE,
  GO_LIVE_IS_LOADING,
  GO_LIVE_ERROR,
  ADD_PACKAGE,
  ADD_PACKAGE_IS_LOADING,
  ADD_PACKAGE_ERROR
} from "../../actions/types/auth";

export default function(
  state = {
    isLoading: false,
    isAuthenticated: false,
    err: false,
    message: "",
    busy: false,
    profile: {},
    group: {},
    loadingState: {
      profile: false,
      group: false,
      member: false
    }
  },
  action
) {
  switch (action.type) {
    case AUTHENTICATE_IS_LOADING: {
      return { ...state, isLoading: true, err: false };
    }

    case AUTHENTICATE: {
      return {
        ...state,
        err: false,
        isLoading: false
      };
    }

    case AUTHENTICATE_ERROR: {
      const { message } = action.payload.response.data.childError;
      return { ...state, err: true, message: message, isLoading: false };
    }

    case CREATE_ACCOUNT_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case CREATE_ACCOUNT: {
      return {
        ...state,
        err: false,
        isLoading: false
      };
    }

    case CREATE_ACCOUNT_ERROR: {
      const { message } = action.payload.response.data.childError;
      return { ...state, err: true, message: message, isLoading: false };
    }

    case VERIFY_ACCOUNT_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case VERIFY_ACCOUNT: {
      return {
        ...state,
        err: false,
        isLoading: false
      };
    }

    case VERIFY_ACCOUNT_ERROR: {
      const { message } = action.payload.response.data.childError;
      return { ...state, err: true, message: message, isLoading: false };
    }

    case FORGOT_PASSWORD_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case FORGOT_PASSWORD: {
      const { message } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        message: message
      };
    }

    case FORGOT_PASSWORD_ERROR: {
      const { message } = action.payload.response.data.childError;
      return { ...state, err: true, isLoading: false, message: message };
    }

    case CHECK_RESET_PASSWORD_CODE_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case CHECK_RESET_PASSWORD_CODE: {
      const { message } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        message: message
      };
    }

    case CHECK_RESET_PASSWORD_CODE_ERROR: {
      const { message } = action.payload.response.data.childError;
      return { ...state, err: true, message: message, isLoading: false };
    }

    case RESET_PASSWORD_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case RESET_PASSWORD: {
      const { message } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        message: message
      };
    }

    case RESET_PASSWORD_ERROR: {
      const { message } = action.payload.response.data.childError;
      return { ...state, err: true, isLoading: false, message: message };
    }

    case CREATE_ACCOUNT_INV_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case CREATE_ACCOUNT_INV: {
      return {
        ...state,
        err: false,
        isLoading: false
      };
    }

    case CREATE_ACCOUNT_INV_ERROR: {
      const message = getErrorMessage(action);
      return { ...state, err: true, message: message, isLoading: false };
    }

    case PROFILE_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case PROFILE: {
      const { user, group } = action.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        profile: user,
        group: group,
        isAuthenticated: true
      };
    }

    case PROFILE_ERROR: {
      return { ...state, err: true, isLoading: false };
    }

    case ONBOARD_GROUP_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case ONBOARD_GROUP: {
      const { data } = action.response.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        group: data
      };
    }

    case ONBOARD_GROUP_ERROR: {
      return { ...state, err: true, isLoading: false };
    }

    case OPT_OUT_DEMO_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case OPT_OUT_DEMO: {
      const { data } = action.response.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        group: data
      };
    }

    case OPT_OUT_DEMO_ERROR: {
      return { ...state, err: true, isLoading: false };
    }

    case GET_GROUP_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case GET_GROUP: {
      const { data, message } = action.response.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        group: data,
        message: message,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case GET_GROUP_ERROR: {
      return {
        ...state,
        err: true,
        isLoading: false,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case UPDATE_GROUP_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        message: "",
        loadingState: {
          profile: false,
          group: true,
          member: false
        }
      };
    }

    case UPDATE_GROUP: {
      const { data, message } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        group: data,
        message: message,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case UPDATE_GROUP_ERROR: {
      const message = getErrorMessage(action);
      return {
        ...state,
        err: true,
        isLoading: false,
        message: message,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case INVITE_MEMBER_IS_LOADING: {
      return {
        ...state,
        message: "",
        isLoading: true,
        loadingState: {
          profile: false,
          group: false,
          member: true
        }
      };
    }

    case INVITE_MEMBER: {
      return {
        ...state,
        err: false,
        isLoading: false,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case INVITE_MEMBER_ERROR: {
      const message = getErrorMessage(action);
      return {
        ...state,
        err: true,
        isLoading: false,
        message: message,
        loadingState: {
          profile: false,
          group: false,
          member: false
        },
        err: true
      };
    }

    case UPLOAD_GROUP_ICON_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingState: {
          profile: false,
          group: true,
          member: false
        }
      };
    }

    case UPLOAD_GROUP_ICON: {
      const { data } = action.response;
      return {
        ...state,
        err: false,
        isLoading: false,
        group: data,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case UPLOAD_GROUP_ICON_ERROR: {
      return {
        ...state,
        err: true,
        isLoading: false,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case UPDATE_PROFILE_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingState: {
          profile: true,
          group: false,
          member: false
        }
      };
    }

    case UPDATE_PROFILE: {
      const { data, message } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        profile: data,
        message: message,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        err: true,
        isLoading: false,
        loadingState: {
          profile: false,
          group: true,
          member: false
        }
      };
    }

    case UPLOAD_PROFILE_ICON_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingState: {
          profile: true,
          group: false,
          member: false
        }
      };
    }

    case UPLOAD_PROFILE_ICON: {
      const { data } = action.response;
      return {
        ...state,
        err: false,
        isLoading: false,
        // profile: data,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case UPLOAD_PROFILE_ICON_ERROR: {
      return {
        ...state,
        err: true,
        isLoading: false,
        loadingState: {
          profile: false,
          group: false,
          member: false
        }
      };
    }

    case GET_PROFILE_IS_LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingState: {
          profile: false,
          group: true,
          member: false
        }
      };
    }

    case GET_PROFILE: {
      const { data } = action.response.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        profile: data,
        loadingState: {
          profile: false,
          group: true,
          member: false
        }
      };
    }

    case GET_PROFILE_ERROR: {
      return {
        ...state,
        err: true,
        isLoading: false,
        loadingState: {
          profile: false,
          group: true,
          member: false
        }
      };
    }

    case LOGOUT_USER_IS_LOADING: {
      return { ...state };
    }

    case LOGOUT_USER: {
      return {
        ...state,
        err: false,
        profile: {},
        group: {},
        isAuthenticated: false
      };
    }

    case LOGOUT_USER_ERROR: {
      return { ...state, err: true };
    }

    case CHECK_MEMBER_INV_CODE_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case CHECK_MEMBER_INV_CODE: {
      const { data, message } = action.payload.data;
      return {
        ...state,
        err: false,
        isLoading: false,
        group: data,
        message: message
      };
    }

    case CHECK_MEMBER_INV_CODE_ERROR: {
      const message = getErrorMessage(action);
      return { ...state, err: true, isLoading: false, message: message };
    }

    case ADD_PACKAGE_IS_LOADING: {
      return { ...state, err: false, isLoading: true };
    }

    case ADD_PACKAGE: {
      const { data } = action.response.data;

      return { ...state, err: false, isLoading: false, group: data };
    }

    case ADD_PACKAGE_ERROR: {
      const { message } = action.payload.response.data;
      return { ...state, err: true, isLoading: false, message: message };
    }

    case GO_LIVE_IS_LOADING: {
      return { ...state, err: false, isLoading: true };
    }

    case GO_LIVE: {
      const { msg } = action.result.data;

      return { ...state, err: false, isLoading: false, message: msg, group: action.result.data.data };
    }

    case GO_LIVE_ERROR: {
      const { message } = action.payload.response.data;
      return { ...state, err: true, isLoading: false, message: message };
    }

    default:
      return state;
  }
}
