/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { AuthForm, LoginForm } from "../components/Forms";
import { loginUser } from "../actions/index";
import { authenticate, getProfile } from "../actions/Auth";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      trigger: false
    };
  }

  handleSubmit = async values => {
    const { authenticate, getProfile } = this.props;
    try {
      this.setState({
        trigger: true
      });
      const auth_user = await authenticate(values);
      const { status } = auth_user.payload.data;
      if (status === 200) {
        const profile_user = await getProfile(auth_user.payload.data.data.token);
        if (profile_user.response.status === 200) {
          this.props.history.push("/dashboard");
        }
      }
    } catch (err) {
      return err;
    }
  };

  render() {
    const { auth_loading, auth_error, auth_message } = this.props;
    const { trigger } = this.state;

    return (
      <div className="min-h-screen bg-white flex relative">
        <img className="h-64 w-auto absolute" style={{ left: "-50px", top: "-100px", opacity: 0.1 }} src={require("../images/logos/otellu-icon-v1.svg")} alt="otellu" />
        <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <LoginForm
              handleSubmit={values => this.handleSubmit(values)}
              loading={auth_loading}
              error={auth_error}
              message={auth_message}
              trigger={trigger}
              title="Sign into your studio account"
              redirectText="Create an account"
              redirectLink="/register"
            />
          </div>
        </div>
        <div
          className="hidden lg:block relative flex-1 bg-cover bg-center"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1598257006303-031250badbdc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80")'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-indigo-500 opacity-100"></div>
        </div>
      </div>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    auth_error: state.auth.err,
    auth_message: state.auth.message,
    auth_loading: state.auth.isLoading
  };
}

export default connect(mapStateToProps, { loginUser, authenticate, getProfile })(withRouter(Login));
