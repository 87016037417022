import React from "react";
// import { toast } from "react-toastify";
import { Container, Row, Col, Card, CardBody, CardFooter } from "shards-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from "dayjs";

import { getVacanciesByGroup } from "../actions";
import { getApplicationsByGroup } from "../actions/application";
import { onboardGroup, addPackage } from "../actions/Auth";
import { goLive } from "../actions/Group";
import { Button, Toast } from "../components/Atoms";
import { ModalContainer, ModalHeader } from "../components/Atoms/Modal";
import { PackageModal } from "../components/Molecules/Modal";
import { Modal, Success, UnlockDemo, Error } from "../components/Modal";
import { CompleteForm, OnboardingForm } from "../components/Forms";
import { AWS_URL } from "../constants/url";

import { dashboardMockup, applicationsMockup } from "../data/mockup";

import amplitude from "amplitude-js";
var employerAnalytics = amplitude.getInstance();

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goLiveTrigger: false,
      allow_placing_vacancy: true,
      onBoardModal: false,
      onBoardSuccessModal: false,
      unlockDemoModal: false,
      packageModal: false,
      packageModalTrigger: false,
      demo_applications: [],
      packageInfo: {
        value: 1
      }
    };
  }

  componentDidMount = async () => {
    const { group, getApplicationsByGroup, applications } = this.props;
    await getApplicationsByGroup(group._id);
    await this.props.getVacanciesByGroup(group._id);

    if (group.demo) {
      const demo_applciations = applications.filter(x => x.isDemoApplicant);
      this.setState({
        demo_applications: demo_applciations
      });
    }
  };

  goLive = async () => {
    const { goLive } = this.props;
    const { _id } = this.props.group;
    this.setState({ goLiveTrigger: true });
    try {
      const go_live_request = await goLive(_id);
    } catch (err) {
      return err;
    }
  };

  continueAfterLive = () => {
    this.setState({
      unlockDemoModal: false
    });
    window.location.reload(false);
  };

  handleOnboardingSubmit = async values => {
    const { onboardGroup, group } = this.props;
    try {
      const onboard_user = await onboardGroup(group, values);
      const { status } = onboard_user.payload.data;
      if (status === 200) {
        this.setState({
          onBoardModal: false,
          onBoardSuccessModal: true
        });
      }
    } catch (err) {
      return err;
    }
  };

  handleAddPackage = async packageInfo => {
    const { group, addPackage } = this.props;
    await addPackage(group, packageInfo);
    this.setState({
      packageModal: false,
      packageModalTrigger: true
    });
  };

  render() {
    const { group, profile, applications, vacancies_by_group, group_error, group_loading, group_message } = this.props;
    const { onBoardModal, onBoardSuccessModal, unlockDemoModal, packageModalTrigger, packageInfo, packageModal } = this.state;

    const groupInfoCheck = group => {
      if (!group.country || !group.city || !group.address || !group.zip || !group.icon) {
        return true;
      }
    };

    return (
      <Container fluid className="main-content-container">
        <div className="max-w-7xl mx-auto">
          <div className="bg-white shadow-sm mt-4 ">
            <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              <div className="py-6 md:flex md:items-center md:justify-between ">
                <div className="min-w-0">
                  <div className="flex items-center">
                    {profile.photo && <img className="hidden h-16 w-16 rounded-full sm:block" src={`${AWS_URL}/${profile.photo}`} alt="" />}
                    {!profile.photo && (
                      <div className="hidden h-16 w-16 rounded-full sm:flex justify-center items-center" alt="">
                        <img src={`https://eu.ui-avatars.com/api/?name=${profile.name}&format=svg&rounded=true&&color=fff&background=5850ec`} />
                      </div>
                    )}

                    <div>
                      <div className="flex items-center">
                        <img
                          className="h-16 w-16 rounded-full sm:hidden"
                          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                          alt="/"
                        />
                        <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">{`Howdy ${profile.name}`}</h1>
                      </div>
                      <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                        <dt className="sr-only">Company</dt>
                        <dd className="mb-0 flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                          <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                              fill-rule="evenodd"
                              d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          {group.title}
                        </dd>
                        <dt className="sr-only">Account status</dt>
                        <dd className="mb-0 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 ">
                          <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          {group.demo ? "Demo account" : "Live account"}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex justify-end flex-1 max-w-xs space-x-3 md:mt-0 md:ml-4">
                  {groupInfoCheck(group) && (
                    <div className="w-48">
                      <Button theme="black" text="Add group info" onClick={() => this.setState({ onBoardModal: true })} />
                    </div>
                  )}
                  {!groupInfoCheck(group) && (
                    <div className="w-48">
                      <Button data_cy="button-buy-package" theme="purple" text="Buy a package" onClick={() => this.setState({ packageModal: true })} />
                    </div>
                  )}
                  {!groupInfoCheck(group) && (
                    <div className="w-48">
                      <Link to="vacancies">
                        <Button theme="black" text="View jobs" />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="my-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              <div className="bg-white overflow-hidden shadow-sm rounded-lg">
                <div className="p-3">
                  <div className="flex items-center">
                    <div className="w-0 flex-1">
                      <h3 className="mb-0 text-xl font-bold text-gray-600 truncate">Total applicants</h3>
                      <h4 className="mb-0 text-4xl font-bold text-gray-900">{group.demo ? dashboardMockup.totalApplicants : applications.length}</h4>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-3 py-3">
                  <div className="text-sm">
                    <Link to="/applicants">
                      <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                        View all
                      </a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-white overflow-hidden shadow-sm rounded-lg">
                <div className="p-3">
                  <div className="flex items-center">
                    <div className="w-0 flex-1">
                      <h3 className="mb-0 text-xl font-bold text-gray-600 truncate">Total vacancies</h3>
                      <h4 className="mb-0 text-4xl font-bold text-gray-900">{group.demo ? dashboardMockup.totalVacancies : vacancies_by_group.length}</h4>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-3 py-3">
                  <div className="text-sm">
                    <Link to="/vacancies">
                      <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                        View all
                      </a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-white overflow-hidden shadow-sm rounded-lg">
                <div className="p-3">
                  <div className="flex items-center">
                    <div className="w-0 flex-1">
                      <h3 className="mb-0 text-xl font-bold text-gray-600 truncate">Members</h3>
                      <h4 className="mb-0 text-4xl font-bold text-gray-900">{group.demo ? dashboardMockup.totalMembers : group.members.length}</h4>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-3 py-3">
                  <div className="text-sm">
                    <Link to="/settings">
                      <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                        View all
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" grid grid-cols-1 gap-5 md:grid-cols-4 md:grid-rows-2">
            <section aria-labelledby="timeline-title" className="lg:col-span-2 lg:row-span-2">
              <div className="bg-white px-4 py-4 shadow-sm sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" className="text-2xl font-bold text-gray-900">
                  Recent applications
                </h2>

                <div className="mt-6 flow-root">
                  {group.demo && (
                    <ul className="-mb-8">
                      {applicationsMockup.slice(Math.max(applicationsMockup.length - 5, 0)).map(x => (
                        <li>
                          <div className="relative pb-2">
                            <div className="relative flex space-x-3">
                              <div>
                                <span className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                                  </svg>
                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm text-gray-500">
                                    {x.name} applied to{" "}
                                    <Link to="/applicants">
                                      <a href="#" className="font-medium text-gray-900">
                                        {x.vacancyTitle}
                                      </a>
                                    </Link>
                                  </p>
                                </div>
                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                  <time datetime="2020-09-20">{dayjs(x.createdAt).format("MMM D")}</time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}

                  <ul className="-mb-8">
                    {applications.slice(Math.max(applications.length - 5, 0)).map(x => (
                      <li>
                        <div className="relative pb-2">
                          <div className="relative flex space-x-3">
                            <div>
                              <span className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                                </svg>
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                <p className="text-sm text-gray-500">
                                  {x.name} applied to{" "}
                                  <Link to={`/job/edit/${x.vacancyId}`}>
                                    <a href="#" className="font-medium text-gray-900">
                                      {x.vacancyTitle}
                                    </a>
                                  </Link>
                                </p>
                              </div>
                              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                <time datetime="2020-09-20">{dayjs(x.createdAt).format("MMM D")}</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-6 flex flex-col justify-stretch">
                  {applications.length !== 0 ? (
                    <Link to="/applicants">
                      <Button type="button" theme="purple" text="View all applicants" />
                    </Link>
                  ) : (
                    <Link to="/vacancies">
                      <Button type="button" theme="purple" text="Add vacancy" />
                    </Link>
                  )}
                </div>
              </div>
            </section>
            <section className="col-span-2">
              <div className="bg-white overflow-hidden shadow-sm rounded-lg">
                <div className="p-3">
                  <div className="flex items-center">
                    <div className="w-0 flex-1">
                      <h3 className="mb-0 text-xl font-bold text-gray-600 truncate">Active packages</h3>
                      <h4 className="mb-0 text-4xl font-bold text-gray-900">{group.demo ? dashboardMockup.currentJobSlots : group.activePackages.length}</h4>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between bg-gray-50 px-3 py-3">
                  <div className="text-sm">
                    <Link to="/settings">
                      <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                        View billing
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {packageModal && (
            <ModalContainer modalState={packageModal} modalWidth="max-w-3xl" onClose={() => this.setState({ packageModal: false })}>
              <ModalHeader title="Choose your package" description="Choose the amount of job slots and place your vacacancies directly." />
              <PackageModal
                labels={{
                  1: "1 job",
                  2: "2 jobs",
                  3: "3 jobs",
                  4: "4 jobs",
                  5: "5 jobs"
                }}
                min={1}
                max={5}
                value={packageInfo.value}
                handleChangeSlider={value =>
                  this.setState(prevState => ({
                    packageInfo: {
                      ...prevState.packageInfo,
                      value: value
                    }
                  }))
                }
                onSubmit={packageInfo => this.handleAddPackage(packageInfo)}
              />
            </ModalContainer>
          )}

          {packageModalTrigger && (
            <ModalContainer data_cy="modal-confirm-buy-package" modalState={packageModalTrigger} onClose={() => this.setState({ packageModalTrigger: false })} modalWidth="max-w-3xl">
              <ModalHeader title="Mission completed" description="The job slots are added to your account. See the updated status in your billing overview. Let the party begin!" />
              <div className="flex flex-col justify-center items-center mt-10 space-y-5">
                <img className="w-64" src="https://media2.giphy.com/media/26BGP98lm74FJgfNS/giphy.gif?cid=ecf05e470tf530s7wqqd941o90ocy55vzxayrly912vj1rz6&rid=giphy.gif" />
                <div>
                  <Button text="Add a job" onClick={() => this.setState({ packageModalTrigger: false })} />
                </div>
              </div>
            </ModalContainer>
          )}

          {onBoardModal && (
            <ModalContainer modalState={onBoardModal} onClose={() => this.setState({ onBoardModal: !this.state.onBoardModal })} modalWidth="max-w-3xl">
              <Modal>
                <OnboardingForm
                  loading={group_loading}
                  error={group_error}
                  message={group_message}
                  title="Let's make it official!"
                  description="Fill in the details of your company here, or do it later. This is necessary before placing vacancies."
                  handleSubmit={values => this.handleOnboardingSubmit(values)}
                  onCancel={() => this.setState({ onBoardModal: !this.state.onBoardModal })}
                />
              </Modal>
            </ModalContainer>
          )}
          {onBoardSuccessModal && (
            <ModalContainer modalState={onBoardSuccessModal} onClose={() => this.setState({ onBoardSuccessModal: !this.state.onBoardSuccessModal })} modalWidth="max-w-3xl">
              <Modal>
                <CompleteForm title="Mission accomplished!" description="You're now all set to place your first job!" redirect="/vacancies" redirectText="Add vacancy" />
              </Modal>
            </ModalContainer>
          )}
        </div>
      </Container>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    group: state.auth.group,
    profile: state.auth.profile,
    isAuthenticated: state.auth.isAuthenticated,
    applications: state.applicants.applications,
    vacancies_by_group: state.vacancies.vacancies_by_group,
    group_error: state.auth.err,
    group_loading: state.auth.isLoading,
    group_message: state.auth.message
  };
}

export default connect(mapStateToProps, {
  getApplicationsByGroup,
  getVacanciesByGroup,
  onboardGroup,
  goLive,
  addPackage
})(Analytics);
