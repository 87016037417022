import React from "react";

const Divider = props => {
  const { text, width, bg, borderColor } = props;
  return (
    <div className={`relative ${width}`}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={`w-full border-t ${borderColor}`} />
      </div>
      <div className="relative flex justify-center">
        <span className={`px-2 ${bg} text-sm text-gray-500`}>{text}</span>
      </div>
    </div>
  );
};

export default Divider;
