import React from "react";
import moment from "moment";
import { Button } from "../../Atoms";

const EmptyJobCard = props => {
  return (
    <div className="flex flex-col justify-center items-center border-dashed border-4 border-gray-300 px-4" style={{ minHeight: "350px" }}>
      <div className="w-48">
        <Button data_cy="button-create-job" text="Create job" theme="black" onClick={props.onClick} />
      </div>
      <div className="mt-2">
        <p className="text-xs mb-0 mt-2 text-gray-600">{`Job will expire at ${moment(props.job.expiresAt).format("MMMM DD, YYYY")}`}</p>
      </div>
    </div>
  );
};

export default EmptyJobCard;
