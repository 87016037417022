import { useState, useEffect } from 'react'

import { useResize } from '.'

const useNavHeight = () => {
  const { width: screenWidth, height: screenHeight } = useResize()

  const [elements, setElements] = useState({ 
    header: document.querySelector('.main-navbar'),
    footer: document.querySelector('.main-footer'),
  })

  const getHeaderHeight = () => {
    return (document.querySelector('.main-navbar') || {}).offsetHeight
  }

  const getFooterHeight = () => {
    return (document.querySelector('.main-footer') || {}).offsetHeight
  }

  const [headerHeight, setHeaderHeight] = useState(getHeaderHeight())
  const [footerHeight, setFooterHeight] = useState(getFooterHeight())

  useEffect(() => {
    setHeaderHeight(getHeaderHeight())
    setFooterHeight(getFooterHeight())
  }, [elements, screenWidth, screenHeight])

  return { headerHeight, footerHeight }
}

export default useNavHeight