//TOTO POST partners/:partnerId/sendInvite
//TOTO GET partners/:partnerId/referred-companies

import { SEND_INVITE_PARTNER_IS_LOADING, SEND_INVITE_PARTNER_DATA, SEND_INVITE_PARTNER_ERROR } from "../../actions/Partner/partner";

import { GET_REFFERED_COMPANIES_IS_LOADING, GET_REFFERED_COMPANIES_DATA, GET_REFFERED_COMPANIES_ERROR } from "../../actions/Partner/partner";

const initialState = {
  error: false,
  message: "",
  isLoading: false,
  partners: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_INVITE_PARTNER_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case SEND_INVITE_PARTNER_DATA: {
      const { msg } = action.result.data;
      return { ...state, isLoading: false, error: false, message: msg };
    }

    case SEND_INVITE_PARTNER_ERROR: {
      const { msg } = action.result.data;
      return { ...state, isLoading: false, error: true, message: msg };
    }

    case GET_REFFERED_COMPANIES_IS_LOADING: {
      return { ...state, isLoading: true };
    }

    case GET_REFFERED_COMPANIES_DATA: {
      return { ...state, isLoading: false, error: false, partners: action.result.data.result };
    }

    case GET_REFFERED_COMPANIES_ERROR: {
      return { ...state, isLoading: false, error: true };
    }

    default:
      return state;
  }
};
