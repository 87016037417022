export const addonsTypes = [
  {
    name: "Soft Skills",
    id: process.env.REACT_APP_ADDON_ID_SOFTSKILLS,
    addonId: process.env.REACT_APP_ADDON_ID_SOFTSKILLS,
    data: {
      softSkillOne: "",
      softSkillTwo: ""
    },
    price: 1000,
    tags: ["team fit"],
    isOtellu: true,
    isCore: false,
    shortDescription: "Test out soft skills on applicants",
    longDescription: "Soft skills long.",
    icon: "https://otellu-cdn.s3.eu-central-1.amazonaws.com/addons/softskills.svg",
    externalLink: "/product/softskills"
  },
  {
    name: "Questions",
    id: process.env.REACT_APP_ADDON_ID_EXTRA_QUESTIONS,
    addonId: process.env.REACT_APP_ADDON_ID_EXTRA_QUESTIONS,
    data: {
      questions: [
        {
          question: ""
        }
      ]
    },
    price: 500,
    tags: ["team fit"],
    isOtellu: true,
    isCore: false,
    title: "Questions",
    shortDescription: "Setup questions for your applicants",
    longDescription: "Nam eget dui. Fusce fermentum odio nec arcu.",
    icon: "https://otellu-cdn.s3.eu-central-1.amazonaws.com/addons/questions.svg"
  },
  {
    name: "Youtube",
    id: process.env.REACT_APP_ADDON_ID_YOUTUBE,
    addonId: process.env.REACT_APP_ADDON_ID_YOUTUBE,
    price: 0,
    tags: ["media"],
    data: {
      youtubeUrl: ""
    },
    isOtellu: true,
    isCore: true,
    shortDescription: "Display a youtube video on your job post",
    longDescription: "Youtube long description",
    icon: "https://www.youtube.com/about/static/svgs/icons/brand-resources/YouTube_icon_full-color.svg"
  }
];
