/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, FormInput, FormCheckbox, FormSelect, Button } from "shards-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { registerPartner } from "../actions/index";
import amplitude from "amplitude-js";
import { countries } from "../data/countries.js";
var employerAnalytics = amplitude.getInstance();

class RegisterPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      adress: "",
      city: "",
      country: "",
      postal_code: "",
      icon: "",
      password: "",
      password2: "",
      token: "",
      groupId: "",
      checked: false,
      showingError: true
    };
  }

  componentDidMount() {
    this.setState({
      groupId: this.props.match.params.groupId,
      token: this.props.match.params.token
    });
  }

  handleCheck = e => {
    this.setState({ checked: !this.state.checked });
  };

  async componentDidUpdate(nextProps, history) {
    if (!this.props.error && !this.state.showingError) {
      if (this.props.message) {
        toast.success(this.props.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });

        await this.setState({ showingError: true });
      }
    }
    if (this.props.error && !this.state.showingError) {
      if (this.props.message) {
        toast.error(this.props.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });
        await this.setState({ showingError: true });
      }
    }
  }

  onRegister = async () => {
    const { name, email, password, password2, groupName, checked, adress, city, country, postal_code, groupId, token, icon } = this.state;
    if ((groupId && token && name && email && password && password2 && groupName && checked && adress && city && country && postal_code, icon)) {
      this.props.registerPartner(name, email, password, password2, groupName, checked, adress, city, country, postal_code, icon, groupId, token).then(res => {
        this.setState({ showingError: false });
      });
    }
  };

  onUploadIcon = e => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let newState = Object.assign({}, this.state);
      newState.icon = reader.result;
      this.setState(newState);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  render() {
    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="8" md="5" className="mx-auto my-auto">
            <Card>
              <CardBody>
                <img className="auth-form__logo d-table mx-auto mb-3" src={require("../images/logos/otellu-icon-v1.svg")} alt="Shards Dashboards - Register Template" />

                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">Create New Account</h5>

                {/* Form Fields */}
                <Form>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Full name</label>
                      <FormInput type="text" id="name" placeholder="Enter name" required autoComplete="name" onChange={e => this.setState({ name: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputPassword2">Company name</label>
                      <FormInput type="text" id="groupName" placeholder="Company Name" required onChange={e => this.setState({ groupName: e.target.value })} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <FormInput type="email" id="exampleInputEmail1" placeholder="Enter email" required autoComplete="email" onChange={e => this.setState({ email: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <FormInput type="text" id="address" placeholder="Enter your companies adress" required autoComplete="street-address" onChange={e => this.setState({ adress: e.target.value })} />
                    </FormGroup>
                  </div>

                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">City</label>
                      <FormInput
                        type="text"
                        id="address"
                        placeholder="Enter your city"
                        required
                        // autoComplete="street-address"
                        onChange={e => this.setState({ city: e.target.value })}
                      />
                    </FormGroup>

                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Country</label>
                      <FormSelect onChange={e => this.setState({ country: e.target.value })} required>
                        <option value="default">Default</option>
                        {countries.map(country => (
                          <option value={country.name}>{country.name}</option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Postal code</label>
                      <FormInput type="text" id="postal_code" placeholder="Enter your postal code" required autoComplete="postal-code" onChange={e => this.setState({ postal_code: e.target.value })} />
                    </FormGroup>

                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputEmail1">Where did you find us (optional)</label>
                      <FormSelect onChange={e => process.env.REACT_APP_ENV_TYPE === "PRODUCTION" && employerAnalytics.logEvent("findSource", { page: "Register", source: e.target.value })}>
                        <option value="default">Default</option>
                        <option value="google-ads">Google ads</option>
                        <option value="socials">Socials like Facebook and Instagram</option>
                        <option value="network">Personal network</option>
                        <option value="other">Other</option>
                      </FormSelect>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <FormInput type="password" id="exampleInputPassword1" placeholder="Password" autoComplete="new-password" required onChange={e => this.setState({ password: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="w-100 mx-2">
                      <label htmlFor="exampleInputPassword2">Repeat password</label>
                      <FormInput
                        type="password"
                        id="exampleInputPassword2"
                        placeholder="Repeat Password"
                        autoComplete="new-password"
                        required
                        onChange={e => this.setState({ password2: e.target.value })}
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-50 mx-2">
                      <label htmlFor="exampleInputPassword1">Upload your company logo</label>
                      <FormInput type="file" required onChange={e => this.onUploadIcon(e)} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <FormGroup className="w-50 mx-2">
                      <FormCheckbox checked={this.state.checked} onChange={e => this.handleCheck(e)}>
                        I agree with the <a href="#">Terms & Conditions</a>.
                      </FormCheckbox>
                    </FormGroup>
                  </div>

                  <Button pill theme="accent" className="d-table mx-auto" onClick={() => this.onRegister()}>
                    Create Account
                  </Button>
                </Form>
              </CardBody>
            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/forgot-password">Forgot your password?</Link>
              <Link to="/login" className="ml-auto">
                Sign In?
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    error: state.auth.err,
    message: state.auth.message
  };
}

export default connect(mapStateToProps, { registerPartner })(RegisterPartner);
