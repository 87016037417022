import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import toast, { Toaster } from "react-hot-toast";

import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./store";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "./components/Atoms";
import { logoutUser } from "./actions/Auth";
import { ConfirmActionProvider } from "./context/ConfirmActionContext"
import { MediaLibraryProvider } from "./context/MediaLibraryContext"

require("dotenv").config();

var employeeAnalytics = amplitude.getInstance();
employeeAnalytics.init("bda943ff00de1ab61cd7136f237b9024");

Sentry.init({
  dsn: "https://51dd29f31c91464ea50145f2ea9ee361@o565698.ingest.sentry.io/5854064",
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-white">
      <div className="max-w-3xl text-center">
        <img className="h-12 w-auto mx-auto" src={require("./images/logos/otellu-icon-v1.svg")} alt="otellu" />
        <div className="mt-4">
          <h1 className="text-4xl">Something went wrong</h1>
          <p>Please contact us or logout from your account.</p>
        </div>
        <div className="flex space-x-4 max-w-md mx-auto mt-5">
          <Button text="Connect support" theme="purple" onClick={() => window.MessageBirdChatWidget.toggleChat()} />
          <Button text="Logout" onClick={resetErrorBoundary} />
        </div>
      </div>
    </div>
  );
}

const Studio = ({ history }) => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      return store.dispatch(logoutUser());
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfirmActionProvider>
          <MediaLibraryProvider>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Otellu | Make your future work </title>
              <meta name="description" content="Looking for work smarter and faster? It can be done! With the vacancy technology from Otellu. Don't turn job search into job search." />
            </Helmet>
            <App />
            <Toaster
              position="bottom-center"
              toastOptions={{
                className: "pl-4 py-2",
                style: { maxWidth: '600px' }
              }}
            />
          </MediaLibraryProvider>
        </ConfirmActionProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(<Studio />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
