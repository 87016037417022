import React from "react";

const Header = props => {
  const { selectedApplicant } = props;
  return (
    <div className="px-4 sm:mt-2 sm:flex sm:items-end sm:px-6">
      <div className="sm:flex-1">
        <div>
          <div className="flex items-center">
            <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">{selectedApplicant.name}</h3>
          </div>
        </div>
        <div className="flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
          <a
            href={`mailto:${selectedApplicant.email}`}
            type="button"
            className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1"
          >
            Mail
          </a>
          <a
            href={`tel:${selectedApplicant.phone}`}
            type="button"
            className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Call
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
