import React from 'react'

const InfoPanelEmpty = ({
  title,
  description,
  icon = <></>,
  children
}) => {
  return (
    <div className="flex h-100 px-8 py-4 text-center flex-column justify-center items-center">
      {icon && React.cloneElement(icon, { className: 'h-10 w-10' })}
      <h5 className="text-gray-900 text-lg leading-6 font-medium mb-1 mt-2">{title}</h5>
      {description && <p className="text-gray-600 text-sm leading-none font-normal" style={{ maxWidth: '250px' }}>{description}</p>}
      {children}
    </div>
  )
}

export default InfoPanelEmpty