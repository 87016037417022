import React from "react";

const Panel = props => {
  const { title, description, children } = props;
  return (
    <div className="bg-white px-3 py-3 w-full shadow sm:rounded-lg sm:px-6">
      <h2 id="timeline-title" className="text-xl mb-1 font-medium text-gray-900">
        {title}
      </h2>
      <p className="mb-0 text-xs text-gray-500">{description}</p>
      {children}
    </div>
  );
};

export default Panel;
