import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Alert } from "../Atoms";

const form_values = {
  password: "",
  password_repeat: ""
};

const ResetPasswordform = props => {
  const { message, error, loading, trigger } = props;
  const SignupSchema = Yup.object({
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/(?=.*[0-9])/, "Password must contain a number."),
    password_repeat: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match")
  });
  return (
    <div>
      <img className="h-12 w-auto" src={require("../../images/logos/otellu-icon-v1.svg")} alt="otellu" />
      <h2 className="mt-6 text-4xl font-extrabold text-gray-900">{props.title}</h2>
      <div className="mt-8">
        {!loading && error && trigger && <Alert status="error" message={message} />}
        {!loading && !error && trigger && <Alert status="success" message={message} />}
        <div className="mt-6">
          <Formik
            initialValues={form_values}
            validationSchema={SignupSchema}
            onSubmit={async values => {
              await new Promise(r => setTimeout(r, 500));
              props.handleSubmit(values);
            }}
          >
            <Form className="space-y-4">
              <div>
                <div className="flex justify-between items-center">
                  <label for="password" className="mb-0 block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <ErrorMessage component="p" className="mb-0 text-xs text-red-400 text-bold leading-none" name="password" />
                </div>
                <div className="mt-1">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    placeholder="**************"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between items-center">
                  <label for="repeat_password" className="mb-0 block text-sm font-medium text-gray-700 leading-none">
                    Repeat password
                  </label>
                  <ErrorMessage component="p" className="mb-0 text-xs text-red-400 text-bold leading-none" name="password_repeat" />
                </div>
                <div className="mt-1">
                  <Field
                    id="password_repeat"
                    name="password_repeat"
                    type="password"
                    autoComplete="password"
                    placeholder="**************"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-full">
                <Button theme="purple" text="Send" type="submit" loading={loading} />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordform;
