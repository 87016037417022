export const VACANCY = "VACANCY";
export const VACANCY_IS_LOADING = "VACANCY_IS_LOADING";
export const VACANCY_ERROR = "VACANCY_ERROR";

export const ALL_VACANCY = "ALL_VACANCY";
export const ALL_VACANCY_IS_LOADING = "ALL_VACANCY_IS_LOADING";
export const ALL_VACANCY_ERROR = "ALL_VACANCY_ERROR";

export const ACTIVATE_VACANCY = "ACTIVATE_VACANCY";
export const ACTIVATE_VACANCY_IS_LOADING = "ACTIVATE_VACANCY_IS_LOADING";
export const ACTIVATE_VACANCY_ERROR = "ACTIVATE_VACANCY_ERROR";

export const UPDATE_VACANCY = "UPDATE_VACANCY";
export const UPDATE_VACANCY_IS_LOADING = "UPDATE_VACANCY_IS_LOADING";
export const UPDATE_VACANCY_ERROR = "UPDATE_VACANCY_ERROR";

export const DRAFT_VACANCY = "DRAFT_VACANCY";
export const DRAFT_VACANCY_IS_LOADING = "DRAFT_VACANCY_IS_LOADING";
export const DRAFT_VACANCY_ERROR = "DRAFT_VACANCY_ERROR";
