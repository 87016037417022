import axios from 'axios'

import { API_URL } from '../../constants/url'
import { multiPart } from '../../helpers/Bearer'
import { triggerToastError, triggerToastSuccess } from "../../helpers/handleResponse";

import { ASSET_UPDATE_IS_LOADING, ASSET_UPDATE_DATA, ASSET_UPDATE_ERROR } from './types'

const updateAsset = (groupId, fileList, file) => async dispatch => {
  const header = multiPart()

  try {
    if (!fileList.length) return

    dispatch({ type: ASSET_UPDATE_IS_LOADING, action: 'update', file })

    // API call
    const formData = new FormData()
    formData.append('files', fileList[0])

    const { data: { data } } = await axios.put(
      `${API_URL}/groups/${groupId}/media/photos/${file.name}`,
      formData,
      header
    )

    const { updated } = data
  
    triggerToastSuccess(`You succesfully updated '${file.name}'`)
    return dispatch({ type: ASSET_UPDATE_DATA, file: updated })
  } catch (err) {
    triggerToastError("Something went wrong when updating your file", false)
    return dispatch({ type: ASSET_UPDATE_ERROR,  err })
  }
}

export default updateAsset