import { combineReducers } from "redux";
import home from "./Home/Home";
import applicants from "./application";
import Specs from "./Vacancies/GetSpecsReducer";
import Payment from "./Billing/PaymentReducer";
import vacancies from "./Vacancies/vacancies";
import auth from "./Auth/auth";
import profile from "./Profile/profile";
import group from "./Group/group";
import UpcomingInvoice from "./Billing/InvoiceReducer";
import stats from "./Stats/stats";
import Partner from "./Partner/partner";
import Member from "./member";
import Billing from "./Billing/billing";
import Skills from "./skills";
import Assets from './Assets'

const appReducer = combineReducers({
  auth,
  home,
  vacancies,
  applicants,
  profile,
  Payment,
  Specs,
  group,
  UpcomingInvoice,
  stats,
  Partner,
  Member,
  Billing,
  Skills,
  Assets
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = initialState;
  }

  return appReducer(state, action);
};
export default rootReducer;
