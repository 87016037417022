import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import jwt from "jsonwebtoken";
import { store } from "./store";
import { logoutUser } from "./actions/Auth";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Analytics from "./views/Analytics";
import Applicants from "./views/Applicants";
import Vacancies from "./views/Vacancies";
import Login from "./views/Login";
import Register from "./views/Register";
import RegisterMember from "./views/RegisterMember";
import RegisterInvite from "./views/RegisterInvite";
import RegisterPartner from "./views/RegisterPartner";
import ChangePassword from "./views/ChangePassword";
import Logout from "./views/Logout";
import ForgotPassword from "./views/ForgotPasswordView";
import ResetPassword from "./views/ResetPassword";
import People from "./views/People";
import Packages from "./views/Packages";
import MediaLibrary from "./views/MediaLibrary";
import EditJob from "./views/EditJob";
import Settings from "./views/Settings";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ children, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({}) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard"
            }}
          />
        )
      }
    />
  );
};

class App extends React.Component {
  componentDidMount = () => {
    //check if jwt token is correct
    const token = Cookie.get("otellu-studio-jwt-token");
    jwt.verify(token, process.env.REACT_APP_JWT_SECRET, (err, decode) => {
      //if token is not correct redirect user
      if (err) {
        return store.dispatch(logoutUser());
      }
    });
  };
  render() {
    const { isAuthenticated } = this.props;
    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          <Route path="/" exact>
            <Redirect to={isAuthenticated ? "/dashboard" : "/login"} />
          </Route>

          <PrivateRoute
            path="/dashboard"
            exact
            isAuthenticated={isAuthenticated}
          >
            <DefaultLayout>
              <Analytics />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute
            path="/applicants"
            exact
            isAuthenticated={isAuthenticated}
          >
            <DefaultLayout>
              <Applicants />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute
            path="/vacancies"
            exact
            isAuthenticated={isAuthenticated}
          >
            <DefaultLayout>
              <Vacancies />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute
            path="/media-library"
            exact
            isAuthenticated={isAuthenticated}
          >
            <DefaultLayout>
              <MediaLibrary />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute path="/people" exact isAuthenticated={isAuthenticated}>
            <DefaultLayout>
              <People />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute
            path="/job/create/:draftId"
            isAuthenticated={isAuthenticated}
          >
            <DefaultLayout>
              <EditJob />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute path="/job/edit/:id" isAuthenticated={isAuthenticated}>
            <DefaultLayout>
              <EditJob />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute
            path="/settings"
            exact
            isAuthenticated={isAuthenticated}
          >
            <DefaultLayout>
              <Settings />
            </DefaultLayout>
          </PrivateRoute>

          <PrivateRoute path="/logout" exact isAuthenticated={isAuthenticated}>
            <emptyLayout>
              <Logout />
            </emptyLayout>
          </PrivateRoute>

          <PublicRoute path="/login" exact isAuthenticated={isAuthenticated}>
            <emptyLayout>
              <Login />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute path="/register" exact isAuthenticated={isAuthenticated}>
            <emptyLayout>
              <Register />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute
            path="/register/member/:groupId"
            exact
            isAuthenticated={isAuthenticated}
          >
            <emptyLayout>
              <RegisterMember />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute
            path="/registration/invitation/group/:groupId/invitationToken/:token"
            exact
            isAuthenticated={isAuthenticated}
          >
            <emptyLayout>
              <RegisterInvite />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute
            path="/auth/register/invitation/partner/:groupId/invitationToken/:token"
            exact
            isAuthenticated={isAuthenticated}
          >
            <emptyLayout>
              <RegisterPartner />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute
            path="/forgot-password"
            exact
            isAuthenticated={isAuthenticated}
          >
            <emptyLayout>
              <ForgotPassword />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute
            path="/reset-password/:email"
            exact
            isAuthenticated={isAuthenticated}
          >
            <emptyLayout>
              <ResetPassword />
            </emptyLayout>
          </PublicRoute>

          <PublicRoute
            path="/change-password/:id/:token"
            exact
            isAuthenticated={isAuthenticated}
          >
            <emptyLayout>
              <ChangePassword />
            </emptyLayout>
          </PublicRoute>
        </Switch>
      </BrowserRouter>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, {})(App);
