import React from "react";

const Alert = props => {
  if (props.status === "success")
    return (
      <div data-cy="alert-success" className="rounded-md bg-green-50 ">
        <div className="grid grid-cols-6">
          <div className="col-span-1 flex justify-center bg-green-600 h-full py-3 rounded-tl-md rounded-bl-md">
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="col-span-5 flex justify-center items-center px-2 py-1">
            <div className="text-sm font-normal text-gray-900">
              <p className="mb-0">{props.message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  if (props.status === "error") {
    return (
      <div data-cy="alert-error" className="rounded-md bg-red-50 ">
        <div className="grid grid-cols-6">
          <div className="col-span-1 flex justify-center bg-red-600 h-full py-3 rounded-tl-md rounded-bl-md">
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="col-span-5 flex justify-center items-center px-2 py-1">
            <div className="text-sm font-normal text-red-700">
              <p className="mb-0">{props.message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Alert;
