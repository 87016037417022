import React from "react";
import { Link, withRouter } from "react-router-dom";
import Cookies from "js-cookie";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink } from "shards-react";
import { connect } from "react-redux";
import { getProfile } from "../../../../actions";
import { logoutUser } from "../../../../actions/Auth";
import { AWS_URL } from "../../../../constants/url";

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      name: ""
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  componentDidMount = async () => {
    const account = JSON.parse(sessionStorage.getItem("account"));
    if (account && sessionStorage.getItem("otellu-studio-jwt-token")) {
      if (this.props.profile.length === 0) {
        await this.props.getProfile(account._id);
      }
    }
    if (this.props.profile) {
      this.setState({
        profile: this.props.profile
      });
    }
  };

  componentDidUpdate = async prevProps => {
    if (this.props.profile._id !== prevProps.profile._id) {
      const account = JSON.parse(sessionStorage.getItem("account"));
      if (account && sessionStorage.getItem("otellu-studio-jwt-token")) {
        await this.props.getProfile(account._id);
      }
    } else {
    }
  };

  onLogout = async () => {
    const { logoutUser } = this.props;
    await logoutUser();
    // this.props.logoutUser().then(res => {
    //   this.props.history.push("/logout");
    // });
  };

  render() {
    const { profile } = this.props;
    return (
      <NavItem tag={Dropdown} className="d-flex" caret toggle={this.toggleUserActions}>
        <DropdownToggle data-cy="menu-open" caret tag={NavLink} className="d-flex align-items-center text-nowrap px-3">
          {profile.photo && <img className="hidden h-10 w-10 mr-2 rounded-full sm:block" src={`${AWS_URL}/${profile.photo}`} alt="" />}
          {!profile.photo && <img className="h-10 mr-2" src={`https://eu.ui-avatars.com/api/?name=${profile.name}&format=svg&rounded=true&&color=fff&background=5850ec`} />}
          <span className="d-none d-md-inline-block">{profile ? profile.name : "loading"}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="settings">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem data-cy="menu-logout" onClick={() => this.onLogout()} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

//Connect redux
function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    error: state.vacancies.err,
    message: state.vacancies.message,
    busy: state.vacancies.busy
  };
}

export default withRouter(connect(mapStateToProps, { getProfile, logoutUser })(UserActions));
