import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../../constants/url";
import { UPDATE_GROUP, UPDATE_GROUP_IS_LOADING, UPDATE_GROUP_ERROR } from "../types/group";
import { GO_LIVE, GO_LIVE_ERROR, GO_LIVE_IS_LOADING } from "../types/auth";
import { bearer } from "../../helpers/Bearer";
const header = bearer();

export const updateGroup = (values, groupId) => async dispatch => {
  try {
    dispatch({ type: UPDATE_GROUP_IS_LOADING });
    const result = await axios.put(`${API_URL}/group/${groupId}`, {
      billingEmail: values.billingEmail,
      city: values.city,
      country: values.country,
      zip: values.zip,
      address: values.address
    });

    return dispatch({ type: UPDATE_GROUP, result });
  } catch (err) {
    return dispatch({
      type: UPDATE_GROUP_ERROR,
      payload: err
    });
  }
};

export const goLive = groupId => async dispatch => {
  try {
    dispatch({ type: GO_LIVE_IS_LOADING });
    const result = await axios.put(`${API_URL}/group/demo/${groupId}`, header);
    return dispatch({ type: GO_LIVE, result });
  } catch (err) {
    return dispatch({
      type: GO_LIVE_ERROR,
      payload: err
    });
  }
};
